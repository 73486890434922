<template>
	<div>
		<!-- begin #header -->
		<div id="header" class="header navbar-default">
			<!-- begin navbar-header -->
			<div class="navbar-header">
				<button type="button" class="navbar-toggle pull-left" v-on:click="toggleMobileRightSidebar" v-if="pageOptions.pageWithTwoSidebar">
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
				</button>
				<router-link :to="{name: loginState == 'guest' ? 'catalog' : 'dashboard'}" class="navbar-brand d-flex align-items-center" >
					<div :style="{'background-image': `url('${logo}')`}" id="company_logo" class="img url-image height-full flex-shrink-0" data-type="logo" style="width:86px"></div>
					<span class="flex-shrink-0"><b>Learning</b> Centre</span>
				</router-link>
				<button type="button" class="navbar-toggle pt-0 pb-0 mr-0 collapsed" v-on:click="toggleMobileTopMenu" v-if="pageOptions.pageWithTopMenu && !pageOptions.pageWithoutSidebar">
					<span class="fa-stack fa-lg text-inverse">
						<i class="far fa-square fa-stack-2x"></i>
						<i class="fa fa-cog fa-stack-1x"></i>
					</span>
				</button>
				<button type="button" class="navbar-toggle" v-on:click="toggleMobileTopMenu" v-if="pageOptions.pageWithTopMenu && pageOptions.pageWithoutSidebar">
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
				</button>
				<button type="button" class="navbar-toggle p-0 m-r-0" v-on:click="toggleMobileMegaMenu" v-if="pageOptions.pageWithMegaMenu">
					<span class="fa-stack fa-lg text-inverse m-t-2">
						<i class="far fa-square fa-stack-2x"></i>
						<i class="fa fa-cog fa-stack-1x"></i>
					</span>
				</button>
				<button type="button" class="navbar-toggle" v-on:click="toggleMobileSidebar" v-if="!pageOptions.pageWithoutSidebar">
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
				</button>
			</div>
			<!-- end navbar-header -->
			
			<header-mega-menu v-if="pageOptions.pageWithMegaMenu"></header-mega-menu>
		
			<!-- begin header-nav -->
			<ul class="navbar-nav navbar-right">
				
				<li >
					<div id="nav-search" v-if="loginState == 'member'"></div>
				</li>	
				<v-notifications v-if="loginState && loginState != 'guest'" />
				<v-cart-dropdown :cart="cart" />
				
				
				<li class="dropdown">
					<a  href="javascript:;" data-click="fullscreen" ><i class="fas fa-expand" ></i></a>
				</li>
				<b-nav-item-dropdown menu-class="dropdown-menu-right" class="dropdown navbar-user" no-caret>
					<template slot="button-content" v-if="loginState != 'guest' && user">
						<img class='user-avatar' :src="`${user.img}`" alt="Profile Picture"/>
						<span class="d-none d-md-inline">{{ `${user.fname} ${user.lname}`  }}</span> <b class="caret"></b>
					</template>
					<template slot='button-content' v-else>
						<span class="d-none d-md-inline">{{ `Hello, Guest` }}</span> <b class="caret"></b>
					</template>
					<template v-if="loginState != 'guest' && user">
						<router-link class='dropdown-item' :to="{name: 'user-profile'}">My Profile</router-link>
						<template v-if="viewerLevel > 3">
							<router-link class='dropdown-item' to="/dashboard/settings">Dashboard Settings</router-link>
							<div class='dropdown-item' @click="switchView()">Learner View</div>
						</template>
						<template v-else-if="actualUserLevel > 3">
							<a href="javascript:;" class='dropdown-item' @click="switchView()">Admin View</a>
						</template>
						<b-dropdown-divider></b-dropdown-divider>
						<a class='dropdown-item' href="javascript:;" @click="$store.dispatch('auth/logout')">Log Out</a>
					</template>
					<template v-else>
						<router-link class='dropdown-item' to="/login">Log In</router-link>
					</template>
				</b-nav-item-dropdown>
				<li class="divider d-none d-md-block" v-if="pageOptions.pageWithTwoSidebar"></li>
				<li class="d-none d-md-block" v-if="pageOptions.pageWithTwoSidebar">
					<a href="javascript:;" v-on:click="toggleRightSidebarCollapsed" class="f-s-14">
						<i class="fa fa-th"></i>
					</a>
				</li>
			</ul>
			<!-- end header navigation right -->
		</div>
		<!-- end #header -->
	</div>
</template>

<script>
import PageOptions from '../../config/PageOptions.js'
import HeaderMegaMenu from './HeaderMegaMenu.vue'
import VNotifications from '../VNotifications.vue'
import VCartDropdown from './VCartDropdown.vue'
import _ from 'lodash'
import $ from 'jquery'
import '../../assets/css/nav-search.css'
import { mapActions, mapState } from "vuex";
export default {
	name: 'Header',
	components: {
		HeaderMegaMenu, 
		VNotifications, 
		VCartDropdown
	},
	props: {
		cart: {
			type: Object
		},
		user: Object,
		logo: {
			type: String,
			default: ''
		}
	},
	mounted() {
		let vm = this;
		let h = () => $(function(){		
			$("#nav-search")
				.append($('<select></select>'))
				.append('<button class="btn" focusable="false"><i class="fa fa-search"></i></button>')

			let data = _.filter(_.map($("#sidebar ul.nav>li"),el=>{
				let text = $(el).find(">a>span").text()
				if (!text) return null;
				let row = {
					text,
					icon : $(el).find("i").attr("class")
				}
				let sub = $(el).find("ul.sub-menu>li>a")

				if (sub.length){
					row.children = _.map(sub,a=>{
						return {
							text : $(a).find(">span").text(),
							id : $(a).attr("href")
						}
					})
				} else {
					row.id = $(el).find("a").attr("href")
				}
				return row
			}))
			function templateResult (state) {
				if (state.icon) {
					return $(`<span class="d-block"><i class="${state.icon} mr-2"></i>${state.text}</span>`);
				} 
				return $(`<span class="ml-4 d-block">${state.text}</span>`);
			}

			let search = $("#nav-search select").select2({
				data,
				multiple : true,
				placeholder : "Search for a page ...",
				dropdownParent : "#nav-search",
				templateResult
			}).on('select2:selecting', function(e){
				e.preventDefault()
				vm.$router.push(e.params.args.data.id.substr(process.env.BASE_URL.length-1))
			}).on('select2:close',function(){		
				$("#nav-search input").blur()
			})

			const shortcut = (e)=>{
				// ctrl-s
				// ignore shift, since ctrl+shift+s is screenshot on Firefox
				if (e.which == 83 && e.ctrlKey && !e.shiftKey){
					e.preventDefault()
					if (! $("body").hasClass("modal-open")){
						let action = $("#nav-search .select2-container").hasClass("select2-container--open") ? "close" : "open"
						search.select2(action)
					}
				}
			}
			$(document).keydown(shortcut)
			$("#nav-search input")
				.keydown(shortcut)
		})
		setTimeout(h, 1000)
	},
	data() {
		return {
			pageOptions: PageOptions,
			error: false,
		}	
	},
	computed: {
		...mapState('auth', ['viewerLevel', 'actualUserLevel', 'loginState']),
		quantity() {
			return this.cart.items.length
		}
	},
	methods: {
		...mapActions('auth', ['switchView']), 	
		async logout() {
			try {
				await this.$ajax(`/logout.php?no_redir=true`, 'post');
			} catch (ex) {
				this.error = ex;
			} finally {
				this.$router.push({name: this.$router.name, query: { t : new Date()}});	
			}
		},
		toggleMobileSidebar() {
			this.pageOptions.pageMobileSidebarToggled = !this.pageOptions.pageMobileSidebarToggled;
		},
		toggleMobileRightSidebar() {
			this.pageOptions.pageMobileRightSidebarToggled = !this.pageOptions.pageMobileRightSidebarToggled;
		},
		toggleMobileTopMenu() {
			this.pageOptions.pageMobileTopMenu = !this.pageOptions.pageMobileTopMenu;
		},
		toggleMobileMegaMenu() {
			this.pageOptions.pageMobileMegaMenu = !this.pageOptions.pageMobileMegaMenu;
		},
		toggleRightSidebar() {
			this.pageOptions.pageRightSidebarToggled = !this.pageOptions.pageRightSidebarToggled;
		},
		toggleRightSidebarCollapsed() {
			this.pageOptions.pageRightSidebarCollapsed = !this.pageOptions.pageRightSidebarCollapsed;
		},
		checkForm: function(e) {
			e.preventDefault();
			this.$router.push({ path: '/extra/search' })
		},
	}
}
</script>
<style scoped>
.dropdown-menu .media a {
	padding: 10px 15px !important;
}
</style>

export default [
    
  {
    path: '/receipts/create_receipts/:id?',
    name: 'receipt',
    meta: {
      title: "Create Receipt", 
      viewerLevel: 5,
      breadcrumbs: ['Receipts', "Create Receipt"],
    },
    component: () => import(/* webpackChunkName: "receipt" */ '../views/Receipts/CreateReceipt.vue'),
    props: true
  },
  {
    path: '/receipts/edit/:id?',
    name: 'edit-receipt',
    meta: {
      title: "Edit Receipt", 
      viewerLevel: 5
    },
    component: () => import(/* webpackChunkName: "receipt" */ '../views/Receipts/EditReceipt.vue'),
    props: true
  },
]
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "row" },
    [
      _vm._t(
        "theName",
        [_c("label", { staticClass: "col-md-3" }, [_vm._v(_vm._s(_vm.label))])],
        { label: _vm.label }
      ),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "col", staticStyle: { "margin-bottom": "0.7rem" } },
        [_vm._t("theValue", [_vm._v(_vm._s(_vm.value))], { value: _vm.value })],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export default {

    AtcSearchFilter          : () => import(/* webpackChunkName: "Atc-search-filter" */'../../components/search/AtcSearchFilter'), 
    AtcSearchResult          : () => import(/* webpackChunkName: "Atc-search-result" */'../../components/search/AtcSearchResult'), 

    AuditSearchFilter          : () => import(/* webpackChunkName: "audit-search-filter" */'../../components/search/AuditSearchFilter'), 
    AuditSearchResult          : () => import(/* webpackChunkName: "audit-search-result" */'../../components/search/AuditSearchResult'), 

    BillSearchFilter           : () => import(/* webpackChunkName: "bill-search-filter" */'../../components/search/BillSearchFilter'), 
    BillSearchResult           : () => import(/* webpackChunkName: "bill-search-result" */'../../components/search/BillSearchResult'), 

    BlendedInvoiceSearchFilter        : () => import(/* webpackChunkName: "BlendedInvoice-search-filter" */'../../components/search/BlendedInvoiceSearchFilter'), 
    BlendedInvoiceSearchResult        : () => import(/* webpackChunkName: "BlendedInvoice-search-result" */'../../components/search/BlendedInvoiceSearchResult'), 

    BreakdownSearchFilter           : () => import(/* webpackChunkName: "Breakdown-search-filter" */'../../components/search/BreakdownSearchFilter'), 
    BreakdownSearchResult           : () => import(/* webpackChunkName: "Breakdown-search-result" */'../../components/search/BreakdownSearchResult'), 

    CardsSearchFilter          : () => import(/* webpackChunkName: "cards-search-filter" */'../../components/search/CardsSearchFilter'), 
    CardsSearchResult          : () => import(/* webpackChunkName: "cards-search-result" */'../../components/search/CardsSearchResult'), 

    ClassSearchFilter          : () => import(/* webpackChunkName: "class-search-filter" */'../../components/search/ClassSearchFilter'), 
    ClassSearchResult          : () => import(/* webpackChunkName: "class-search-result" */'../../components/search/ClassSearchResult'), 

    ClassFileSearchFilter      : () => import(/* webpackChunkName: "class-file-search-filter" */'../../components/search/ClassFileSearchFilter'), 
    ClassFileSearchResult      : () => import(/* webpackChunkName: "class-file-search-result" */'../../components/search/ClassFileSearchResult'), 

    ClassSalesSearchFilter     : () => import(/* webpackChunkName: "class-sales-search-filter" */'../../components/search/ClassSalesSearchFilter'), 
    ClassSalesSearchResult     : () => import(/* webpackChunkName: "class-sales-search-result" */'../../components/search/ClassSalesSearchResult'), 

    FeedbackSearchFilter       : () => import(/* webpackChunkName: "feedback-search-filter" */'../../components/search/FeedbackSearchFilter'), 
    FeedbackSearchResult       : () => import(/* webpackChunkName: "feedback-search-result" */'../../components/search/FeedbackSearchResult'), 

    InfractionSearchFilter    : () => import(/* webpackChunkName: "infractions-search-filter" */'../../components/search/InfractionSearchFilter'), 
    InfractionSearchResult    : () => import(/* webpackChunkName: "infractions-search-result" */'../../components/search/InfractionSearchResult'), 
    
    MiscInvoiceSearchFilter    : () => import(/* webpackChunkName: "MiscInvoices-search-filter" */'../../components/search/MiscInvoiceSearchFilter'), 
    MiscInvoiceSearchResult    : () => import(/* webpackChunkName: "MiscInvoices-search-result" */'../../components/search/MiscInvoiceSearchResult'), 

    OnlineInvoiceSearchFilter        : () => import(/* webpackChunkName: "OnlineInvoice-search-filter" */'../../components/search/OnlineInvoiceSearchFilter'), 
    OnlineInvoiceSearchResult        : () => import(/* webpackChunkName: "OnlineInvoice-search-result" */'../../components/search/OnlineInvoiceSearchResult'), 

    ImportedCertSearchFilter    : () => import(/* webpackChunkName: "ImportedCerts-search-filter" */'../../components/search/ImportedCertSearchFilter'), 
    ImportedCertSearchResult    : () => import(/* webpackChunkName: "ImportedCerts-search-result" */'../../components/search/ImportedCertSearchResult'), 

    NamechangeSearchFilter        : () => import(/* webpackChunkName: "namechange-search-filter" */'../../components/search/NamechangeSearchFilter'), 
    NamechangeSearchResult        : () => import(/* webpackChunkName: "namechange-search-result" */'../../components/search/NamechangeSearchResult'), 

    NoshowSearchFilter         : () => import(/* webpackChunkName: "no-show-search-filter" */'../../components/search/NoshowSearchFilter'), 
    NoshowSearchResult         : () => import(/* webpackChunkName: "no-show-search-result" */'../../components/search/NoshowSearchResult'), 

    OnsiteClassSearchFilter    : () => import(/* webpackChunkName: "onsite-class-search-filter" */'../../components/search/OnsiteClassSearchFilter'), 
    OnsiteClassSearchResult    : () => import(/* webpackChunkName: "onsite-class-search-result" */'../../components/search/OnsiteClassSearchResult'), 

    OssaTrainingSearchFilter   : () => import(/* webpackChunkName: "ossa-training-search-filter" */'../../components/search/OSSATrainingSearchFilter'), 
    OssaTrainingSearchResult   : () => import(/* webpackChunkNSame: "ossa-training-search-result" */'../../components/search/OSSATrainingSearchResult'), 

    OssaNumbersSearchFilter    : () => import(/* webpackChunkName: "ossa-numbers-search-filter" */'../../components/search/OssaNumbersSearchFilter'), 
    OssaNumbersSearchResult    : () => import(/* webpackChunkName: "ossa-numbers-search-result" */'../../components/search/OssaNumbersSearchResult'), 

    PaypalReceiptsSearchFilter : () => import(/* webpackChunkName: "paypal-receipts-search-filter" */'../../components/search/PaypalReceiptsSearchFilter'), 
    PaypalReceiptsSearchResult : () => import(/* webpackChunkName: "paypal-receipts-search-result" */'../../components/search/PaypalReceiptsSearchResult'), 

    PaypalSearchFilter         : () => import(/* webpackChunkName: "paypal-search-filter" */'../../components/search/PaypalSearchFilter'), 
    PaypalSearchResult         : () => import(/* webpackChunkName: "paypal-search-result" */'../../components/search/PaypalSearchResult'), 

    PrereqCertSearchFilter         : () => import(/* webpackChunkName: "PrereqCert-filter" */'../../components/search/PrereqCertSearchFilter'), 
    PrereqCertSearchResult         : () => import(/* webpackChunkName: "PrereqCert-result" */'../../components/search/PrereqCertSearchResult'), 

    ReceiptsSearchFilter       : () => import(/* webpackChunkName: "receipts-search-filter" */'../../components/search/ReceiptsSearchFilter'), 
    ReceiptsSearchResult       : () => import(/* webpackChunkName: "receipts-search-result" */'../../components/search/ReceiptsSearchResult'), 

    Revenue2SearchFilter : () => import(/* webpackChunkName: "in-house-revenue-search-filter" */'../../components/search/InHouseRevenueSearchFilter'), 
    Revenue2SearchResult : () => import(/* webpackChunkName: "in-house-revenue-search-result" */'../../components/search/InHouseRevenueSearchResult'), 

    StationsSearchFilter       : () => import(/* webpackChunkName: "stations" */"../../components/search/StationSearchFilter"),
    StationsSearchResult       : () => import(/* webpackChunkName: "stations" */"../../components/search/StationSearchResult"),

    StationsBookingsSearchFilter       : () => import(/* webpackChunkName: "StationsBooking" */"../../components/search/StationsBookingSearchFilter"),
    StationsBookingsSearchResult       : () => import(/* webpackChunkName: "StationsBooking" */"../../components/search/StationsBookingSearchResult"),


    StudentSearchFilter        : () => import(/* webpackChunkName: "student-search-filter" */'../../components/search/StudentSearchFilter'), 
    StudentSearchResult        : () => import(/* webpackChunkName: "student-search-result" */'../../components/search/StudentSearchResult'), 

    TechinfoSearchFilter       : () => import(/* webpackChunkName: "Techinfo-search-filter" */'../../components/search/TechinfoSearchFilter'), 
    TechinfoSearchResult       : () => import(/* webpackChunkName: "Techinfo-search-result" */'../../components/search/TechinfoSearchResult'), 

    TimesheetSearchFilter     : () => import(/* webpackChunkName: "time-sheets-search-filter" */'../../components/search/TimesheetsSearchFilter'),  
    TimesheetSearchResult     : () => import(/* webpackChunkName: "time-sheets-search-result" */'../../components/search/TimesheetsSearchResult'), 

    UserSearchFilter          : () => import(/* webpackChunkName: "users-search-filter" */'../../components/search/UsersSearchFilter'), 
    UserSearchResult          : () => import(/* webpackChunkName: "users-search-result" */'../../components/search/UsersSearchResult'), 
  
    InvoiceSearchFilter        : () => import(/* webpackChunkName: "invoice-search-filter" */'../../components/search/InvoiceSearchFilter'), 
    InvoiceSearchResult        : () => import(/* webpackChunkName: "invoice-search-result" */'../../components/search/InvoiceSearchResult'), 
    SharedFilesSearchFilter    : () => import(/* webpackChunkName: "shared-files-search-filter" */'../../components/search/SharedFilesSearchFilter'), 
    SharedFilesSearchResult    : () => import(/* webpackChunkName: "shared-files-search-result" */'../../components/search/SharedFilesSearchResult'), 
    PublicProfileSearchFilter  : () => import(/* webpackChunkName: "public-profile-search-filter" */'../../components/search/PublicProfileSearchFilter'), 
    PublicProfileSearchResult  : () => import(/* webpackChunkName: "public-profile-search-result" */'../../components/search/PublicProfileSearchResult'), 
    TimeoffRequestsSearchFilter: () => import(/* webpackChunkName: "time-off-requests-search-filter" */'../../components/search/TimeOffRequestsSearchFilter'), 
    TimeoffRequestsSearchResult: () => import(/* webpackChunkName: "time-off-requests-search-result" */'../../components/search/TimeOffRequestsSearchResult'), 
    RequestSearchFilter       : () => import(/* webpackChunkName: "requests-search-filter" */'../../components/search/RequestsSearchFilter'), 
    RequestsSearchFilter       : () => import(/* webpackChunkName: "requests-search-filter" */'../../components/search/RequestsSearchFilter'), 
    RequestSearchResult       : () => import(/* webpackChunkName: "requests-search-result" */'../../components/search/RequestsSearchResult'), 
    RequestsSearchResult       : () => import(/* webpackChunkName: "requests-search-result" */'../../components/search/RequestsSearchResult'), 
    TicketSearchFilter        : () => import(/* webpackChunkName: "Ticket-search-filter" */'../../components/search/TicketSearchFilter'), 
    TicketSearchResult        : () => import(/* webpackChunkName: "Ticket-search-result" */'../../components/search/TicketSearchResult'), 
    CheckinSearchFilter        : () => import(/* webpackChunkName: "check-in-search-filter" */'../../components/search/CheckInSearchFilter'), 
    CheckinSearchResult        : () => import(/* webpackChunkName: "check-in-search-result" */'../../components/search/CheckInSearchResult'), 
    TrackingSearchFilter       : () => import(/* webpackChunkName: "tracking-search-filter" */'../../components/search/TrackingSearchFilter'), 
    TrackingSearchResult       : () => import(/* webpackChunkName: "tracking-search-result" */'../../components/search/TrackingSearchResult'), 
    ProctorSearchFilter        : () => import(/* webpackChunkName: "proctor-search-filter" */'../../components/search/ProctorSearchFilter'), 
    ProctorSearchResult        : () => import(/* webpackChunkName: "proctor-search-result" */'../../components/search/ProctorSearchResult'), 
    DisputeSearchFilter        : () => import(/* webpackChunkName: "dispute-search-filter" */'../../components/search/DisputeSearchFilter'), 
    DisputeSearchResult        : () => import(/* webpackChunkName: "dispute-search-result" */'../../components/search/DisputeSearchResult'), 
    PaymentSearchFilter        : () => import(/* webpackChunkName: "payment-search-filter" */'../../components/search/PaymentSearchFilter'), 
    PaymentSearchResult        : () => import(/* webpackChunkName: "payment-search-result" */'../../components/search/PaymentSearchResult'), 
    InhouseInvoiceSearchFilter        : () => import(/* webpackChunkName: "InhouseInvoice-search-filter" */'../../components/search/InhouseInvoiceSearchFilter'), 
    InhouseInvoiceSearchResult        : () => import(/* webpackChunkName: "InhouseInvoice-search-result" */'../../components/search/InhouseInvoiceSearchResult'), 
    ElearningRevenueSearchFilter        : () => import(/* webpackChunkName: "ElearningRevenue-search-filter" */'../../components/search/ElearningRevenueSearchFilter'), 
    ElearningRevenueSearchResult        : () => import(/* webpackChunkName: "ElearningRevenue-search-result" */'../../components/search/ElearningRevenueSearchResult'), 
    OnlineRevenueSearchFilter        : () => import(/* webpackChunkName: "OnlineRevenue-search-filter" */'../../components/search/OnlineRevenueSearchFilter'), 
    OnlineRevenueSearchResult        : () => import(/* webpackChunkName: "OnlineRevenue-search-result" */'../../components/search/OnlineRevenueSearchResult'), 
    UnscheduledStudentSearchFilter        : () => import(/* webpackChunkName: "UnscheduledStudent-search-filter" */'../../components/search/UnscheduledStudentSearchFilter'), 
    UnscheduledStudentSearchResult        : () => import(/* webpackChunkName: "UnscheduledStudent-search-result" */'../../components/search/UnscheduledStudentSearchResult'), 
    FailedPaymentSearchFilter        : () => import(/* webpackChunkName: "Failedpayment-search-filter" */'../../components/search/FailedpaymentSearchFilter'), 
    FailedPaymentSearchResult        : () => import(/* webpackChunkName: "Failedpayment-search-result" */'../../components/search/FailedpaymentSearchResult'), 
    TransactionSearchFilter        : () => import(/* webpackChunkName: "Transaction-search-filter" */'../../components/search/TransactionSearchFilter'), 
    TransactionSearchResult        : () => import(/* webpackChunkName: "Transaction-search-result" */'../../components/search/TransactionSearchResult'), 
    RecordsSearchFilter : () => import(/* webpackChunkName: "records-search-filter" */"../../components/search/RecordsSearchFilter"),
    RecordsSearchResult : () => import(/* webpackChunkName: "records-search-Result" */"../../components/search/RecordsSearchResult"),
    
    
    // these are old copies
    InHouseRevenueSearchFilter : () => import(/* webpackChunkName: "in-house-revenue-search-filter" */'../../components/search/InHouseRevenueSearchFilter'), 
    // InhouseInvoiceSearchFilter : () => import(/* webpackChunkName: "in-house-revenue-search-filter" */'../../components/search/InHouseRevenueSearchFilter'), 
    // InhouseInvoiceSearchResult : () => import(/* webpackChunkName: "in-house-revenue-search-result" */'../../components/search/InHouseRevenueSearchResult'), 
    InHouseRevenueSearchResult : () => import(/* webpackChunkName: "in-house-revenue-search-result" */'../../components/search/InHouseRevenueSearchResult'), 


}
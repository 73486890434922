var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    !_vm.pageOptions.pageEmpty
      ? _c(
          "div",
          {
            staticClass: "fade page-sidebar-fixed page-header-fixed show",
            class: {
              "page-sidebar-minified": _vm.pageOptions.pageSidebarMinified,
              "page-content-full-height": _vm.pageOptions.pageContentFullHeight,
              "page-without-sidebar": _vm.pageOptions.pageWithoutSidebar,
              "page-with-right-sidebar": _vm.pageOptions.pageWithRightSidebar,
              "page-with-two-sidebar": _vm.pageOptions.pageWithTwoSidebar,
              "page-with-wide-sidebar": _vm.pageOptions.pageWithWideSidebar,
              "page-with-light-sidebar": _vm.pageOptions.pageWithLightSidebar,
              "page-with-top-menu": _vm.pageOptions.pageWithTopMenu,
              "page-sidebar-toggled": _vm.pageOptions.pageMobileSidebarToggled,
              "page-right-sidebar-toggled":
                _vm.pageOptions.pageMobileRightSidebarToggled ||
                _vm.pageOptions.pageRightSidebarToggled,
              "page-right-sidebar-collapsed":
                _vm.pageOptions.pageRightSidebarCollapsed,
              "has-scroll": _vm.pageOptions.pageBodyScrollTop
            },
            attrs: { id: "page-container" }
          },
          [
            _c("Header", {
              attrs: { logo: _vm.logo, cart: _vm.cart, user: _vm.user },
              on: { emptyCart: _vm.emptyCart }
            }),
            _vm._v(" "),
            _vm.pageOptions.pageWithTopMenu ? _c("TopMenu") : _vm._e(),
            _vm._v(" "),
            !_vm.pageOptions.pageWithoutSidebar
              ? _c("Sidebar", { attrs: { user: _vm.user, menu: _vm.menu } })
              : _vm._e(),
            _vm._v(" "),
            _vm.pageOptions.pageWithTwoSidebar ? _c("SidebarRight") : _vm._e(),
            _vm._v(" "),
            _c("router-view", {
              on: { login: _vm.update, logout: _vm.logout }
            }),
            _vm._v(" "),
            _c("login-modal", { on: { login: _vm.update } }),
            _vm._v(" "),
            _vm.pageOptions.pageWithFooter ? _c("Footer") : _vm._e()
          ],
          1
        )
      : _c("div", [_c("router-view", { on: { login: _vm.update } })], 1)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "fade show d-none", attrs: { id: "page-loader" } },
      [_c("span", { staticClass: "spinner" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
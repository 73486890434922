<script>
import _ from 'lodash';
import Swal from 'sweetalert2';
import courseMixin from './courseMixin';
import { customPost } from '../../scripts/custom-all'
let is_admin = true;
//course bundle
export default {
    name: 'course-wrapper-4',
    mixins : [courseMixin],
    created(){
        this.passStateOptions = {
            "0" : "owned",
            "9" : "pass"
        };
        this.btnOptions["owned"] = {
            icon : "fa-box",
            content : "Owned",
            type : "btn-secondary",
            disabled : true,
            onclick : ()=>{}
        };
        this.btnOptions["pass"].disabled = true;
    },
    methods : {
        bundle_check_template : (whatever) => _.template(`
            <b>Following courses in this bundle either paid or in-cart </b>
            <ul class="mt-3" style="list-style: none;"><% _.each(courses,(name)=>{%><li><%- name %></li><%} ) %></ul>
        `)(whatever),

        async buyAction(){
            if (is_admin){
                this.purchase();
            } else {
                let r = await customPost(`${ process.env.VUE_APP_AJAX_PATH}/ajax/functions_ajax_cart.php`,{
                    action : "check_bundle",
                    course: this.course.id
                });

                if (r.bundle_up){
                    let r = await Swal.fire({
                        type : "info",
                        text : "You have passed all courses in this bundle, do you want bundle them up to get a bundle certificate?",
                        showCancelButton: true,
                        confirmButtonText: "Bundle up!"
                    });

                    if (r.value) await this.addToRecord();
                    return;
                }

                let courses = r.courses;

                if (_.size(courses)){
                    let r = await Swal.fire({
                        type : "warning",
                        html : this.bundle_check_template({courses}),
                        showCancelButton: true,
                        confirmButtonText: "Add other courses to cart"
                    });
                    if (r.dismiss) return
                }
                await this.purchase();
            }

        }
    }
};
</script>


<template>
    <div class="star-rating">
        <div class="back-stars">
            <i v-for="i in 5" :key="i" class="fa fa-star" :class="{'cursor-pointer' : !readonly}" @click="onClick(i)"></i>
        </div>

        <div class="front-stars" :style="`width : ${Number(value)/5.0 * 100}%`">
            <i v-for="i in 5" :key="i" class="fa fa-star" :class="{'cursor-pointer' : !readonly}" @click="onClick(i)"></i>
        </div>
    </div>

</template>

<script>
  export default {
      name: 'star-rating',
        props : {
            value : {
                type : [Number,String],
                default : 0
            },
            readonly : Boolean
        },
        methods : {
            onClick(i){
                if(!this.readonly){
                    this.$emit("input",i);
                }
            }
        },
    };
</script>


<style>
    .star-rating{
        display : inline-block;
        position : relative;
    }

    .star-rating .front-stars,.star-rating .back-stars {
        display : flex;
    }

    .star-rating .front-stars {
        color: #FFBC0B;
        overflow: hidden;
        position: absolute;
        top: 0;
    }
    .star-rating .back-stars{
        color: #d9e0e7;
    }

    .star-rating .fa-star:not(:last-child){
        padding-right: 4px 
    }

</style>
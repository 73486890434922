var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "widget widget-stats v-state",
      style: {
        "background-color": _vm.Color(_vm.color).toString(),
        color: _vm.Color(_vm.color).invert.toString()
      }
    },
    [
      _vm.icon != null && _vm.icon != " "
        ? _c("div", { staticClass: "stats-icon" }, [
            _c("i", { class: _vm.$getIcon(_vm.icon) })
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "stats-info" }, [
        _c("h4", [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c(
          "p",
          [
            _c(
              "transition",
              { attrs: { "enter-active-class": "animated fadeIn" } },
              [
                _vm.display != null
                  ? _c(
                      "span",
                      [
                        _vm.labels.prepend
                          ? _c("span", { staticClass: "m-r-3" }, [
                              _vm._v(_vm._s(_vm.labels.prepend))
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._.isNumber(_vm.display)
                          ? _c("animated-integer", {
                              attrs: { value: _vm.display }
                            })
                          : _c("span", [_vm._v(_vm._s(_vm.display))]),
                        _vm._v(" "),
                        _vm.labels.append
                          ? _c("span", { staticClass: "m-l-3" }, [
                              _vm._v(_vm._s(_vm.labels.append))
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.error
                  ? _c("span", { staticClass: "text-danger" }, [
                      _vm._v("Error")
                    ])
                  : _vm._e()
              ]
            ),
            _vm._v("   \n             \n        ")
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "stats-link" },
        [
          _vm.url
            ? _c(
                "router-link",
                {
                  attrs: { to: _vm.url, target: "_blank" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                    }
                  }
                },
                [
                  _vm._v("View Detail "),
                  _c("i", { staticClass: "far fa-arrow-alt-circle-right" })
                ]
              )
            : _c("a", [_vm._v("  ")])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
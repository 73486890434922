export default [
    
  {
    path: '/students/group_bookings', 
    name: 'groups', 
    meta: {
      title: "Group Bookings",
    }, 
    component: () => import(/* webpackChunkName: "groupBookings" */"../views/Groups/GroupBookings.vue")
  }
]
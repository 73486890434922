<template>
    <div id="content" class="content" v-bind:class="{ 'content-full-width': pageOptions.pageContentFullWidth, 'content-inverse-mode': pageOptions.pageContentInverseMode }">
        <!-- begin breadcrumb -->
        <ol class="breadcrumb pull-right">
            <li class="breadcrumb-item" v-for="(item,i) in $vstore.breadcrumbs" :key="i" >
                <router-link v-if="typeof item == 'object'" :to="item.to">{{item.name}}</router-link>
                <span v-else>{{item}}</span>
            </li>
        </ol>

        <!-- end breadcrumb -->
        <!-- begin page-header -->
        <h1 class="page-header">
            <slot name="title">{{actualTitle}}</slot>
                <transition-group class="d-inline-flex flex-wrap"  enter-active-class="animated fadeInRight">   
                    <slot name="subtitle">
                        <small  style="padding-left: 5px"  :key="$vstore.subtitle || 0">{{$vstore.subtitle || subtitle}}</small>                    
                        <span key="PLACEHOLDER" style="height:24px">&nbsp;</span>
                    </slot>
                </transition-group> 
            <transition enter-active-class="animated fadeIn">
                <button 
                    v-if = "$vstore.titleButton.html"
                    v-html="$vstore.titleButton.html"
                    class="float-right btn btn-sm mr-2"
                    :class="`btn-${$vstore.titleButton.type} ${$vstore.titleButton.className}`"
                    @click="$vstore.titleButton.callback"
                ></button>
            </transition>
        </h1>   

        <slot v-if="!noAuth && !noFeature"></slot>
        <slot name="no-auth" v-if="noAuth">
            <v-message title="Access Denied">You do not have permission to access this page.  Please contact your administrator to request access.</v-message>
        </slot>
        <slot name="no-feature" v-if="noFeature">
            <v-message title="Access Denied">This feature has been disabled for your company.</v-message>
        </slot>
    </div>
</template> 
<script>
import pageOptions from '../../config/PageOptions.js'
import { mapState } from "vuex";
export default {
    props: {
        title: String,
        subtitle: String,
        noAuth: Boolean,
        feature: {
            type:  String,
            required: false
        }
    },      
    computed: {
        ...mapState('auth', ['company_name']),
        noFeature() {
            return this.feature ? !this.$store.state.auth.features[this.feature] : false
        }, 
        actualTitle() {
            if (this.$route.name == 'search') {
                if (this.$vstore.title) {
                    return this.$vstore.title
                }
            }
            if (this.$route.meta.title) {
                if (this.$route.meta.title instanceof Function) {
                    let title = this.$route.meta.title(this.$route, this.title);
                    document.title = `${title} | ${this.company_name} | Learning Centre`
                    return title;
                } else {
                    return this.$route.meta.title;
                }
            } 
            return this.$vstore.title || this.title;
        }, 
    }, 
    data() {
        return {
            pageOptions
        }
    }
}
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    { ref: "table", staticClass: "table table-hover width-full" },
    [
      _c("thead", [
        _c(
          "tr",
          [_vm.responsive ? _c("th") : _vm._e(), _vm._v(" "), _vm._t("head")],
          2
        )
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.rows, function(row, index) {
          return _c(
            "tr",
            { key: index },
            [
              _vm.responsive ? _c("td") : _vm._e(),
              _vm._v(" "),
              _vm._t("row", null, { row: row, index: index })
            ],
            2
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
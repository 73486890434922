<script>
// station booking
import _ from 'lodash';
import courseMixin from './courseMixin';
import courseButtonActionHelpers from './cabh';
export default {
    name: 'course-wrapper-5',
    mixins : [courseMixin],
    created(){
        this.passStateOptions = {
            "0" : "owned",
        };
        this.btnOptions["owned"] = {
            icon : "fa-calendar-alt",
            content : "View Booking",
            type : "btn-lime",
            onclick : ()=>{courseButtonActionHelpers["booking-detail"].open(this.course.rid, this.buyAction)}
            // }
        };
        this.btnOptions["unbuy"].icon = "fa-calendar-alt";
        this.btnOptions["unbuy"].type = "btn-lime";
        this.btnOptions["unbuy"].content = "CA$"+_.round(this.course.cost,2).toFixed(2)+"/hr",
        this.btnOptions["in-cart"].icon = "fa-calendar-alt";
        this.btnOptions["in-cart"].type = "btn-lime";
        this.btnOptions["in-cart"].onclick = ()=> {courseButtonActionHelpers["cart-booking-detail"].open(this.course.id, this.buyAction)}

    },
    methods : {
        buyAction(){
            courseButtonActionHelpers["booking-site-picker"].open(this.course.id, this.purchase);
        }
    }
};
</script>
<template>
	<!-- menu with submenu -->
	<li v-if="menu.children" class="has-sub" :class="{ 'active': subIsActive(menu.children) }">
		<a href="javascript:;">
			<span v-if="menu.badge" class="badge pull-right">{{ menu.badge }}</span>
			<b v-else class="caret"></b>
			<i v-if="menu.icon" class="fas" :class="menu.icon"></i>
			<div v-if="menu.img" class="icon-img"><img :src="menu.img" alt="" /></div>
			<span>
				{{ menu.title }}
				<span v-if="menu.label" class="label label-theme">{{ menu.label }}</span>
				<i v-if="menu.highlight" class="fa fa-paper-plane text-theme"></i>
			</span>
		</a>
		<ul class="sub-menu"  :style="{ marginTop: (pageOptions.pageSidebarMinified) ? - (scrollTop + 40) + 'px' : '' }">
			<template v-for="submenu in menu.children">
				<sidebar-nav-list :menu="submenu" :key="submenu.path" ref="sidebarNavList"></sidebar-nav-list>
			</template>
		</ul>
	</li>
  
	<!-- menu without submenu -->
	<li 
			v-else  >
		<router-link 
			:to="menu.path" 
			:target="menu.target || ''" 
			:class="{ 'd-none': this.stat =='hide' }" 
			:exact='menu.exact || false'  
			active-class="active" 
		>
			<span v-if="menu.badge" class="badge pull-right">{{ menu.badge }}</span>
			<i v-if="menu.icon" class="fas" :class="menu.icon"></i>
			<div v-if="menu.img" class="icon-img"><img :src="menu.img" alt="" /></div>
			<span>
				{{ menu.title }}
				<i v-if="menu.highlight" class="fa fa-paper-plane text-theme"></i>
				<span v-if="menu.label" class="label label-theme">{{ menu.label }}</span>
			</span>
		</router-link>
	</li>
</template>

<script>
import SidebarNavList from './SidebarNavList.vue'
import PageOptions from '../../config/PageOptions.js'

export default {
	name: 'SidebarNavList',
	props: ['menu', 'scrollTop'],
	components: {
		SidebarNavList
	},
	data() {
		return {
			stat: '',
			pageOptions: PageOptions
		}
	},
	methods: {
		subIsActive(children) {
			const paths = children.map(e => e.path)
			// const paths = Array.isArray(path) ? path : [path]
			return paths.some(path => {
				return this.$route.path.indexOf(path) === 0
			})
		}
  }
}
</script>

<template>
    <v-input
        label="Calendars"
        type="select2"
        :required="required"
        :template-result="calendarOptionTemplate"
        :template-selection="state=>calendars[state.id].abbrev"
        :value="value"
        @input="$emit('input', $event)"
        :inline="inline"
        multiple
    >
        <option v-for="c in _.filter(calendars, c => c.active == 1)" :key="c.id" :value="c.id" >{{c.name}} ({{c.abbrev}})</option>
    </v-input>
</template>
<script>
import _ from 'lodash'
import $ from 'jquery';
import { VInput } from '.';
export default {
    components: {VInput}, 
    props: {
        calendars: [Array, Object],
        value: Array,
        inline: {
            type : Boolean, 
            default : true
        },
        required: {
            type: Boolean,
            default : true
        }
    }, 
    watch: {
        async value() {
            await this.$nextTick();
            // sets the background color of the individual tags
            // whenever the thing is changed. 
            _.map(this.calendars, c => (
                $(`li.select2-selection__choice[title='${c.name} (${c.abbrev})']`)
                    .css(`background-color`, c.color)
                )
            );
        }
    }, 
    methods: {
        calendarOptionTemplate(state){
            let {id,text} = state;
            if (!id) return text;
            let color = this.Color(this.calendars[id].color);
            let cls = "d-flex justify-content-center align-items-center height-30 rounded";
            return $(`<div style='background-color : ${color};color : ${color.invert};' class='${cls}'><b>${text}</b></div>`);
        },
    }, 
}
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-form", {
    on: { submit: _vm.onSubmit },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var edit = ref.edit
          return [
            _c("label", [
              _vm._v("Name "),
              _c("span", { staticClass: "text-danger" }, [_vm._v("*")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row m-b-20" }, [
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c("v-input", {
                    attrs: {
                      placeholder: "First Name",
                      inline: "",
                      required: ""
                    },
                    model: {
                      value: edit.fname,
                      callback: function($$v) {
                        _vm.$set(edit, "fname", $$v)
                      },
                      expression: "edit.fname"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c("v-input", {
                    attrs: {
                      placeholder: "Last Name",
                      inline: "",
                      required: ""
                    },
                    model: {
                      value: edit.lname,
                      callback: function($$v) {
                        _vm.$set(edit, "lname", $$v)
                      },
                      expression: "edit.lname"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("label", [
              _vm._v("Email "),
              _c("span", { staticClass: "text-danger" }, [_vm._v("*")])
            ]),
            _vm._v(" "),
            _c("v-input", {
              staticClass: "m-b-20",
              attrs: {
                name: "email",
                type: "email",
                placeholder: "Email Address",
                required: ""
              },
              model: {
                value: edit.email,
                callback: function($$v) {
                  _vm.$set(edit, "email", $$v)
                },
                expression: "edit.email"
              }
            }),
            _vm._v(" "),
            _c("label", [
              _vm._v("Password "),
              _c("span", { staticClass: "text-danger" }, [_vm._v("*")])
            ]),
            _vm._v(" "),
            _c("v-input", {
              staticClass: "m-b-20",
              attrs: {
                name: "password",
                autocomplete: "new-password",
                type: "password-confirm"
              },
              model: {
                value: edit.psw,
                callback: function($$v) {
                  _vm.$set(edit, "psw", $$v)
                },
                expression: "edit.psw"
              }
            }),
            _vm._v(" "),
            _c(
              "v-input",
              {
                staticClass: "m-b-20",
                attrs: { type: "checkbox" },
                model: {
                  value: _vm.policyConfirm,
                  callback: function($$v) {
                    _vm.policyConfirm = $$v
                  },
                  expression: "policyConfirm"
                }
              },
              [
                _vm._v("By clicking Sign Up, you agree to our "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "//albertabcsafety.com/terms-and-conditions/",
                      target: "_blank"
                    }
                  },
                  [_vm._v("Terms and Conditions")]
                ),
                _vm._v(" and that you have read our "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "//albertabcsafety.com/privacy-policy/",
                      target: "_blank"
                    }
                  },
                  [_vm._v("Privacy / Data Policy")]
                ),
                _vm._v(".")
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex m-b-15 justify-content-center" }, [
              _c("div", { attrs: { id: "captcha-container" } })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "login-buttons" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success btn-block btn-lg",
                  attrs: { type: "submit", disabled: !_vm.submitEnable }
                },
                [
                  _vm.submited
                    ? _c("i", { staticClass: "far fa-spinner fa-spin mr-3" })
                    : _vm._e(),
                  _vm._v("Sign Up")
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "m-t-20" }, [
              _vm._v("\n            Have you already registered? Click "),
              _c(
                "a",
                {
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("update:state", "guest")
                    }
                  }
                },
                [_vm._v("here")]
              ),
              _vm._v(" to login.\n        ")
            ])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }

<template>
    <div v-if="endtime">
        <h4 class="text-danger">IP Banned</h4>
        <p>Your IP address has been blocked for too many failed login attempts. You need to wait until <b class="text-danger">{{endtime.format("lll")}}</b>.</p>
        <h5 class="text-center text-danger">{{remain_timer}}</h5>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    name: "banned-timer",
    data(){
        return {
            endtime : null,
            time_remain : null
        };
    },
    async created(){
        let response = await this.$ajax(`${ process.env.VUE_APP_AJAX_PATH}/ajax/functions_ajax_login.php`, `POST`, {
            action : "checkBannedTime"
        });
        if (response.banned){
            this.endtime = moment(response.time);
            let update = () => {
                this.time_remain = moment.duration(this.endtime.diff(moment()));
                if (this.time_remain.asSeconds() <= 0){
                    this.$emit('update:state', "guest");
                } else {
                    setTimeout(update,1000);
                }
            }
            update();
        } else {
            this.$emit('update:state', 'guest');
        }
    },
    computed : {
        remain_timer() {
            return moment.utc(this.time_remain.asMilliseconds()).format("H:mm:ss");
        }
    },
};
</script>


<template>  
    <div>
        <div 
            @click="toggle()" 
            class="v-node d-flex align-items-center border-left border-bottom" 
            :draggable="draggable" 
            @dragstart="dragstart" 
            @dragend="dragend"
            @dragover="dragover"
            @drop="drop"
            :class="{'dragging-over' : $vstore.draggingOver == id}"
        >
            <i  class="menu-caret d-inline-flex justify-content-center" :class="{open : isOpen,invisible : !state}"></i>
            <span class="noselect">{{name}}</span>
            <span class="icons ml-5">
                <a @click.stop="emit('create',id,()=>toggle(true))"  href="javascript:;" class="text-green">
                    <i class="fas mr-2 fa-plus"></i>
                </a>
                <a @click.stop="emit('edit',id)"  href="javascript:;" class="text-info">
                    <i class="fas mr-2 fa-edit"></i>
                </a>
                <a @click.stop="emit('delete',id)" href="javascript:;" class="text-danger">
                    <i class="fas fa-trash-alt"></i>
                </a>

            </span>
        </div>
        <ul v-if="isBuild" v-collapse="isOpen">
            <li v-for="child in children" :key="child">
                <v-node :id="child" :key="child"></v-node>
            </li>
        </ul>
    </div>
</template>
<script>
import _ from 'lodash';
export default {
    name: "v-node",
    data(){
        _.each(["dragging","draggingOver"],prop=>{
            this.$set(this.$vstore,prop,null);
        });
        return {
            isOpen : false,
            isBuild : false
        };
    },
    props : {
        id : [String, Number],
        draggable : {
            Boolean,
            default : true
        }
    },
    methods : {
        async toggle(state){
            if (!this.state) return;
            if (!this.isBuild){
                this.isBuild = true;
                await this.$nextTick();
            }
            this.isOpen = state != null ? state : !this.isOpen;
        },
        dragstart(e){
            this.$vstore.dragging = this.id;
            e.dataTransfer.setData('id', this.id);
        },
        dragend(){
            this.$vstore.draggingOver = null;
            this.$vstore.dragging = null;
        },
        dragover(e){
            if (this.$vstore.dragging){
                e.preventDefault();
                this.$vstore.draggingOver = this.id;
            }
        },
        drop(e){
            e.preventDefault();
            let id = e.dataTransfer.getData('id');
            if (id != this.id){
                this.emit("drop",id,this.id);
            }
        }
    },
    computed : {
        name(){
            return this.getName(this.id);
        },
        children(){
            return this.getChildren(this.id);
        },
        state(){
            if (!_.size(this.children)){
                return 0;
            }
            if (!this.isBuild){
                return 1;
            }
            if (!this.isOpen){
                return 2;
            }
            return 3;
        } 
    },
    inject : ["getName","getChildren","emit"],
};
</script>
<style>
    .v-tree {
        color: #4a4a4a;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
    }
    .v-tree ul{
        list-style-type: none;
        margin-left : 2em;
        padding-left: 0;
    }
    .v-tree .v-node{
        position : relative;
        height : 32px;
    }
    .v-tree .v-node:hover{
        background-color: #f0f0f0;
    }
    .v-tree .v-node .icons{
        display : none;
    }
    .v-tree .v-node:hover .icons{
        display : inline-block;
    }

    .v-tree .v-node.dragging-over{
        border: 3px dashed #007bff!important;
    }



    .v-tree .menu-caret {
        width : 30px;
    }

    .v-tree .menu-caret:before{
        content: '\f054';
        display: inline-block;
        text-align: center;
        transition: all .2s linear;
        font-family: Font Awesome\ 5 Free,Font Awesome\ 5 Pro,FontAwesome!important;
        font-weight: 900;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
    }

    .v-tree .menu-caret.open:before{
        opacity: 1!important;
        transform: rotate(90deg)!important;
    }

    .v-tree .menu-caret:before{
        opacity: .5;
        transform: rotate(0);
    }

</style>
export default [
  {
    path: '/competencies/critical',
    name: 'critical',
    meta: {
      title: "Critical Task",
      viewerLevel: 1,
    },
    component: () => import(/* webpackChunkName: "critical" */ '../views/Competencies/Critical.vue'),
  },
  {
    path: '/competencies/procedures',
    name: 'procedures',
    meta: {
      title: "Associated Standards and Procedures",
      viewerLevel: 1
    },
    component: () => import(/* webpackChunkName: "procedures" */ '../views/Competencies/Procedures.vue'),
  },
]
<script>
// instructor led
import courseMixin from './courseMixin';
import courseButtonActionHelpers from './cabh';
export default {
    name: 'course-wrapper-3',
    mixins : [courseMixin],
    created(){
        this.passStateOptions = {
            "7" : "scheduled",
            "8" : "no-show",
            '6' : "fail",
            "11" : "pass"
        };
        this.btnOptions["unbuy"].icon = "fa-calendar-alt";
        this.btnOptions["unbuy"].type = "btn-lime";
        this.btnOptions["in-cart"].icon = "fa-calendar-alt";
        this.btnOptions["in-cart"].type = "btn-lime";
    },
    methods : {
        buyAction(){
            courseButtonActionHelpers["site-picker"].open(this.course.id);
        }
    }
};
</script>

<template>
    <v-form  @submit="onSubmit">
        <template slot-scope="{edit}">
            <label>Email <span class="text-danger">*</span></label>
            <v-input name="email" type="email" placeholder="Email Address" v-model="edit.email" class="m-b-20" required></v-input>
            <div class="login-buttons">
                <button type="submit" class="btn btn-success btn-block btn-lg" :disabled="submited"><i v-show="submited" class="far fa-circle-o-notch fa-spin mr-3"></i>Send Email</button>
            </div>
        </template>
    </v-form>
</template>

<script>
 import loginMixin from './loginMixin';
import { customPost} from '../../scripts/custom-all'
export default {
    name: 'recover-form',
    mixins: [loginMixin],
    data(){
        return {
            submited : false
        };
    },
    methods : {
        async onSubmit(data){
            this.submited = true
            try {
                await customPost(`${ process.env.VUE_APP_AJAX_PATH}/ajax/functions_ajax_login.php`,{
                    action : "setUpPswReset",
                    email : data.email
                });
                this.$emit('update:state', "recover-notice");
            } catch (e){
                this.submited = false;
            }
        }
    },
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: {
        mode: "out-in",
        "enter-active-class": "animated fadeIn",
        "leave-active-class": "animated fadeOut"
      }
    },
    [
      !_vm.loadingInit
        ? _c("div", { key: _vm.state, staticClass: "login login-v2" }, [
            _c("div", { staticClass: "login-header mt-3" }, [
              _c(
                "div",
                {
                  staticClass:
                    "brand d-flex align-items-center justify-content-center"
                },
                [
                  _c("div", {
                    staticClass: "url-image img",
                    staticStyle: { width: "100px", height: "100px" },
                    style: { "background-image": "url('" + _vm.bg_img + "')" },
                    attrs: { "data-type": "login-logo" }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "ml-3" }, [
                    _c("b", [_vm._v("Learning")]),
                    _vm._v(" Centre\n                    "),
                    _c("small", [
                      _vm._v("Training | Certification | Proctoring")
                    ])
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "login-content" },
              [
                _c(_vm.state, {
                  tag: "component",
                  attrs: {
                    email: _vm.email,
                    "company-list": _vm.company_list,
                    "ajax-login": _vm.ajaxLogin,
                    "lock-company": _vm.lockCompany,
                    "enable-register": _vm.enable_register,
                    "enable-secondary": _vm.enable_secondary,
                    state: _vm.state
                  },
                  on: {
                    "update:email": function($event) {
                      _vm.email = $event
                    },
                    "update:companyList": function($event) {
                      _vm.company_list = $event
                    },
                    "update:company-list": function($event) {
                      _vm.company_list = $event
                    },
                    "update:ajaxLogin": function($event) {
                      _vm.ajaxLogin = $event
                    },
                    "update:ajax-login": function($event) {
                      _vm.ajaxLogin = $event
                    },
                    "update:lockCompany": function($event) {
                      _vm.lockCompany = $event
                    },
                    "update:lock-company": function($event) {
                      _vm.lockCompany = $event
                    },
                    "update:state": function($event) {
                      _vm.state = $event
                    },
                    login: _vm.update
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "text-white-transparent-2 pull-right mr-3 mb-1 f-s-10"
              },
              [
                _vm._v("ABCS Safety Training Inc. © 2020 ProsperLMS"),
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-weight": "700",
                      "font-size": "45%",
                      "vertical-align": "super"
                    }
                  },
                  [_vm._v("TM")]
                )
              ]
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
	<div>
		<!-- begin #page-loader -->
		<div id="page-loader" class="fade show d-none"><span class="spinner"></span></div>
		<!-- end #page-loader -->

		<div 
			id="page-container"
			class="fade page-sidebar-fixed page-header-fixed show" 
			v-if="!pageOptions.pageEmpty" 
			:class="{ 
			'page-sidebar-minified': pageOptions.pageSidebarMinified, 
			'page-content-full-height': pageOptions.pageContentFullHeight, 
			'page-without-sidebar': pageOptions.pageWithoutSidebar, 
			'page-with-right-sidebar': pageOptions.pageWithRightSidebar, 
			'page-with-two-sidebar': pageOptions.pageWithTwoSidebar,
			'page-with-wide-sidebar': pageOptions.pageWithWideSidebar,
			'page-with-light-sidebar': pageOptions.pageWithLightSidebar,
			'page-with-top-menu': pageOptions.pageWithTopMenu,
			'page-sidebar-toggled': pageOptions.pageMobileSidebarToggled,
			'page-right-sidebar-toggled': pageOptions.pageMobileRightSidebarToggled || pageOptions.pageRightSidebarToggled,
			'page-right-sidebar-collapsed': pageOptions.pageRightSidebarCollapsed,
			'has-scroll': pageOptions.pageBodyScrollTop
		}" >
			<Header :logo="logo" :cart="cart" :user="user" @emptyCart="emptyCart" />
			<TopMenu v-if="pageOptions.pageWithTopMenu" />
			<Sidebar v-if="!pageOptions.pageWithoutSidebar" :user="user" :menu="menu" />
			<SidebarRight v-if="pageOptions.pageWithTwoSidebar" />
			<router-view @login="update" @logout="logout"></router-view>
			<!-- <div id="content" class="content" >
				<router-view name="breadcrumbs"></router-view>
				<transition enter-active-class="animated fadeIn">
						<button 
								v-if = "$vstore.titleButton && $vstore.titleButton.html"
								v-html="$vstore.titleButton.html"
								class="float-right btn btn-sm mr-2"
								:class="`btn-${$vstore.titleButton.type} ${$vstore.titleButton.className}`"
								@click="$vstore.titleButton.callback"
						></button>
				</transition>
				<router-view name="header"></router-view>
				
				<router-view></router-view>
			</div> -->
			<login-modal @login="update"/>
			<Footer v-if="pageOptions.pageWithFooter" />
		</div>
		<div v-else>
			<router-view @login="update"></router-view>
		</div>
	</div>
</template>

<script>


import Sidebar from './components/sidebar/SidebarOld.vue'
import SidebarRight from './components/sidebar-right/SidebarRight.vue'
import Header from './components/header/Header.vue'
import TopMenu from './components/top-menu/TopMenu.vue'
import Footer from './components/footer/Footer.vue'
import pageOptions from './config/PageOptions.js'
import LoginModal from './components/login/LoginModal.vue'
import { mapState, mapActions } from "vuex";
export default {
	name: 'app',
	components: {
		Sidebar,
		SidebarRight,
		Header,
		TopMenu,
		Footer,
		LoginModal
	},
	mounted() {
		this.$appReady();
	}, 
	data() {
		return {
			pageOptions,
		}
	},
	watch: {	
		loginState: {
			/** this is in the wrong place  */
			handler(loginState) {
				this.refreshCart();
				if (loginState == 'guest' || loginState == 'select-company') {
					if (!this.$route.meta.guest) {
						this.$router.push('/login')
					} else {
						this.pageOptions.pageWithoutSidebar = true;
					}
				}  else {
					this.pageOptions.pageWithoutSidebar = false;
					this.pageOptions.pageEmpty = false;
				}
			}, 
			// immediate: true
		},
		dark: {
			handler(dark) {
				if (dark) {
					localStorage.dark = true;
					document.getElementById('css-theme').href = `${process.env.BASE_URL}assets/css/style_dark.min.css`
				} else {
					localStorage.dark = false;
					document.getElementById('css-theme').href = `${process.env.BASE_URL}assets/css/style.min.css`
				}
			}, 
			immediate: true
		}
	},
	computed: {
		...mapState('auth', [
			'menu', 
			'loginState', 
			'dark', 
			'userClickedLogout',
			'user', 
			'logo', 
			'bg_logo'
		]),
		...mapState('cart', ['cart'])
	},
	methods: {
		...mapActions('auth', ['logout']),
		...mapActions('cart', ['updateCart', 'refreshCart', 'emptyCart']), 
		update() {	
			console.log('updating...');
		},
		handleScroll: function() {
			pageOptions.pageBodyScrollTop = window.scrollY;
		},
	},
	async created() {
		pageOptions.pageBodyScrollTop = window.scrollY;
		window.addEventListener('scroll', this.handleScroll);
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.handleScroll);
	}
}
</script>

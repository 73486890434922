var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "course-blocks" } },
    _vm._l(
      _vm._.filter(_vm.categories, function(e) {
        return _vm._.size(e.courses)
      }),
      function(ref) {
        var courses = ref.courses
        var category = ref.category
        return _c("div", { key: category }, [
          category != "default"
            ? _c("legend", { staticStyle: { "font-size": "large" } }, [
                _c("h4", { staticClass: "d-inline" }, [
                  _c("i", {
                    staticClass: "fas text-info mr-2",
                    class: _vm.getCategoryIcons(category)
                  }),
                  _vm._v(_vm._s(category))
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-link float-right text-secondary",
                    on: {
                      click: function($event) {
                        return _vm.toggleCategoryState(category)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          _vm.getCategoryState(category) ? "Hide" : "Show"
                        ) +
                        "\n\t\t\t\t"
                    )
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "collapse",
                  rawName: "v-collapse",
                  value: _vm.getCategoryState(category),
                  expression: "getCategoryState(category)"
                }
              ]
            },
            [
              _c(
                "transition-group",
                {
                  staticClass: "row",
                  attrs: {
                    tag: "div",
                    "enter-active-class": "animated fadeInUp",
                    "leave-active-class": "d-none"
                  }
                },
                _vm._l(courses, function(course) {
                  return _c("course-wrapper-" + course.type, {
                    key: course.id,
                    tag: "component",
                    staticClass:
                      "col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-2",
                    attrs: { course: course, mycourse: _vm.mycourses },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var btn = ref.btn
                            var hasProgressBar = ref.hasProgressBar
                            var img = ref.img
                            var type = ref.type
                            var hasDiscount = ref.hasDiscount
                            return _c(
                              "div",
                              {
                                staticClass:
                                  "panel panel-inverse course-block d-flex flex-column width-full position-relative"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "course-img img d-flex flex-column-reverse width-full",
                                    style: img.style
                                  },
                                  [
                                    hasProgressBar
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "progress progress-xs rounded-0"
                                          },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "progress-bar progress-bar-striped progress-bar-animated",
                                              style:
                                                "width: " +
                                                course.progress +
                                                "%"
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "panel-body d-flex flex-column flex-grow-1"
                                  },
                                  [
                                    _c(
                                      "h5",
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                name: "course-details",
                                                params: course.shortcut
                                                  ? {
                                                      shortcut: course.shortcut
                                                    }
                                                  : null,
                                                query: !course.shortcut
                                                  ? { id: course.id }
                                                  : null
                                              }
                                            }
                                          },
                                          [_vm._v(_vm._s(course.name))]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("h6", [_vm._v(_vm._s(type))]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "d-flex align-items-center"
                                      },
                                      [
                                        _c("star-rating", {
                                          attrs: {
                                            value: course.avg_rating,
                                            readonly: ""
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "pl-3" }, [
                                          _vm._v(
                                            "(" +
                                              _vm._s(course.num_reviews || 0) +
                                              ")"
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("i", {
                                          staticClass: "fas fa-user fa-xs pl-1"
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    course.student_name
                                      ? _c(
                                          "div",
                                          { staticClass: "mt-1 text-right" },
                                          [_vm._v(_vm._s(course.student_name))]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-sm btn-block mt-auto",
                                        class: btn.type,
                                        attrs: { disabled: btn.disabled },
                                        on: { click: btn.onclick }
                                      },
                                      [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(btn.content)
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("i", {
                                          staticClass: "fas pull-right",
                                          class: btn.icon
                                        })
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                hasDiscount
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "label label-danger discount-label"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(course.discount) + "% OFF"
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                }),
                1
              )
            ],
            1
          )
        ])
      }
    ),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
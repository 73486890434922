var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "sidebar",
        class: {
          "sidebar-transparent": _vm.pageOptions.pageSidebarTransparent
        },
        attrs: { id: "sidebar" }
      },
      [
        _c(
          "vue-custom-scrollbar",
          { staticClass: "height-full" },
          [
            !_vm.pageOptions.pageSidebarSearch
              ? _c("sidebar-nav-profile", { attrs: { user: _vm.user } })
              : _vm._e(),
            _vm._v(" "),
            _c("sidebar-nav", {
              ref: "sidebarNav",
              attrs: { menus: _vm.menu, scrollTop: _vm.scrollTop },
              on: {
                showFloatSubmenu: _vm.handleShowFloatSubmenu,
                hideFloatSubmenu: _vm.handleHideFloatSubmenu
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "sidebar-bg" }),
    _vm._v(" "),
    _c("div", {
      staticClass: "mobile-click",
      on: { click: _vm.dismissSidebar }
    }),
    _vm._v(" "),
    this.pageOptions.pageSidebarMinified
      ? _c(
          "div",
          {
            staticClass: "float-sub-menu-container",
            class: {
              "d-none": !this.floatSubmenu
            },
            style: {
              top: this.floatSubmenuTop,
              left: this.floatSubmenuLeft,
              bottom: this.floatSubmenuBottom,
              right: this.floatSubmenuRight
            },
            on: {
              mouseover: _vm.clearHideFloatSubmenu,
              mouseleave: _vm.handleHideFloatSubmenu
            }
          },
          [
            _c("div", {
              staticClass: "float-sub-menu-arrow",
              style: {
                top: this.floatSubmenuArrowTop,
                bottom: this.floatSubmenuArrowBottom
              }
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "float-sub-menu-line",
              style: {
                top: this.floatSubmenuLineTop,
                bottom: this.floatSubmenuLineBottom
              }
            }),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "float-sub-menu" },
              [
                _vm._l(this.floatSubmenuMenu, function(menu) {
                  return [
                    _c("sidebar-nav-list", {
                      key: menu.path,
                      ref: "sidebarNavList",
                      refInFor: true,
                      attrs: { menu: menu, status: menu.status },
                      on: { calcFloatSubmenu: _vm.handleCalcFloatSubmenu }
                    })
                  ]
                })
              ],
              2
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.endtime
    ? _c("div", [
        _c("h4", { staticClass: "text-danger" }, [_vm._v("IP Banned")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Your IP address has been blocked for too many failed login attempts. You need to wait until "
          ),
          _c("b", { staticClass: "text-danger" }, [
            _vm._v(_vm._s(_vm.endtime.format("lll")))
          ]),
          _vm._v(".")
        ]),
        _vm._v(" "),
        _c("h5", { staticClass: "text-center text-danger" }, [
          _vm._v(_vm._s(_vm.remain_timer))
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export default [
    
  /* SCHEDULING SYSTEM */
  {

    path: '/scheduling/scheduling_system',
    name: 'scheduling-system',
    meta: {
      title: "Scheduling", 
      viewerLevel: 4,
      breadcrumbs: ['Scheduling', 'Scheduling System'], 
    }, 
    component: () => import(/* webpackChunkName: "scheduling-system" */"../views/Scheduling/SchedulingSystem.vue")
  }, 
  {
    
    path: '/scheduling/calendar/:id?',
    name: 'calendar',
    meta: {
      title: "Calendar",
      guest: true,
      breadcrumbs: ['Calendar'], 
    },
    component: () => import(/* webpackChunkName: "courses-calendar" */ '../views/Courses/CourseCalendar.vue'),
    props: true
  }, 
  {
    path: '/scheduling/station/:id?',
    name: 'station',
    meta: {
      title: 'Station Details',
      guest: true,
      breadcrumbs: ['Station Details'],
    },
    component: () => import(/* webpackChunkName: "station" */"../views/Scheduling/Station.vue"),
    props: true
  },
  {
    path: '/scheduling/company_calendars',
    name: 'company-calendars',
    meta: {
      title: "Company Calendars", 
      viewerLevel: 3,
      breadcrumbs: ['Scheduling System', 'Company Calendars'], 
    }, 
    component: () => import(/* webpackChunkName: "calendar-list" */"../views/Scheduling/CalendarList.vue")
  },
  {
    path: '/scheduling/sites',
    name: 'company-sites',
    meta: {
      title: "Company Sites", 
      viewerLevel: 3,
      breadcrumbs: ['Scheduling System', 'Sites'], 
    }, 
    component: () => import(/* webpackChunkName: "sites" */"../views/Scheduling/Sites.vue")
  },
  {
    path: '/scheduling/site/:id',
    name: 'site',
    meta: {
      title: "Manage Site Information", 
      viewerLevel: 3,
      breadcrumbs: [ 'Scheduling System', 'Site'], 
    }, 
    component: () => import(/* webpackChunkName: "site" */"../views/Scheduling/Site.vue"),
    props: true
  },
  {
    path: '/scheduling/site_stations/:id',
    name: 'site-stations',
    meta: {
      title: "Manage Site Practical Locations", 
      viewerLevel: 3
    }, 
    breadcrumbs: [ 'Scheduling System', 'Site Practical Locations'], 
    component: () => import(/* webpackChunkName: "site-station" */"../views/Scheduling/SiteStations.vue"),
    props: true
  },
  {
    path: '/scheduling/customer_management',
    name: 'company-customers',
    meta: {
      title: "Customer Management", 
      viewerLevel: 3,
      breadcrumbs: [ 'Scheduling System', 'Customer Management'], 
    }, 
    component: () => import(/* webpackChunkName: "customer-list" */"../views/Scheduling/CustomerList.vue")
  },
  {
    path: '/scheduling/shifts',
    name: 'company-shifts',
    meta: {
      title: "Shifts", 
      viewerLevel : 4,
      breadcrumbs: ['Scheduling System', 'Shifts'], 
    },
    component: () => import(/* webpackChunkName: "shifts" */"../views/Scheduling/Shifts.vue")
  },
  {
    path: '/scheduling/shortcuts',
    name: 'company-shortcuts',
    meta: {
      title: "Shortcuts", 
      viewerLevel: 3, 
      breadcrumbs: ['Scheduling System', 'Shifts'], 
    },
    component: () => import(/* webpackChunkName: "shortcuts" */"../views/Scheduling/Shortcuts.vue")
  },
]
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.promos.length && !_vm.skipHeader
        ? _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "div",
                { staticClass: "col-md-3", attrs: { id: "learn_dash_img1" } },
                [
                  _c("div", { staticClass: "panel panel-inverse" }, [
                    _c("div", {
                      staticClass: "panel-body",
                      style:
                        "height:  275px;background-image: url(" +
                        _vm.assets_path +
                        _vm.promos[0] +
                        ");background-position: 50% 50%;width:  100%;background-size:  cover;"
                    })
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-6", attrs: { id: "learn_dash_img2" } },
                [
                  _c("div", { staticClass: "panel panel-inverse" }, [
                    _c("div", {
                      staticClass: "panel-body",
                      style:
                        "height:  275px;background-image: url(" +
                        _vm.assets_path +
                        _vm.promos[1] +
                        ");background-position: 50% 50%;width:  100%;background-size:  cover;"
                    })
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-3", attrs: { id: "learn_dash_img3" } },
                [
                  _c("div", { staticClass: "panel panel-inverse" }, [
                    _c("div", {
                      staticClass: "panel-body",
                      style:
                        "height:  275px;background-image: url(" +
                        _vm.assets_path +
                        _vm.promos[2] +
                        ");background-position: 50% 50%;width:  100%;background-size:  cover;"
                    })
                  ])
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.blocks, function(block, i) {
                return _c("div", { key: i, class: "col-md-" + block[4] }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "panel panel-inverse  hvr-float-shadow d-block pointer-cursor",
                      on: {
                        click: function($event) {
                          return _vm.$router.push({ name: block[5] })
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "panel-body d-flex flex-column align-items-center"
                        },
                        [
                          _c("i", {
                            staticClass: "fal text-info fa-5x",
                            class: block[2]
                          }),
                          _vm._v(" "),
                          _c("h4", { staticClass: "f-w-700 mt-2" }, [
                            _vm._v(_vm._s(block[0]))
                          ]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(block[1]))])
                        ]
                      )
                    ]
                  )
                ])
              })
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.instructor ? _c("signature-check") : _vm._e(),
      _vm._v(" "),
      _c("dashboard-courses"),
      _vm._v(" "),
      _c("site-picker"),
      _vm._v(" "),
      _c("event-detail")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
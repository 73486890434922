var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-form", {
    on: { submit: _vm.onSubmit },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var edit = ref.edit
          return [
            _c("div", { staticClass: "alert alert-success" }, [
              _vm._v("Select a company to sign-in")
            ]),
            _vm._v(" "),
            _c(
              "v-input",
              {
                attrs: {
                  large: "",
                  type: "select2",
                  placeholder: "Select a  company",
                  required: ""
                },
                model: {
                  value: edit.cid,
                  callback: function($$v) {
                    _vm.$set(edit, "cid", $$v)
                  },
                  expression: "edit.cid"
                }
              },
              _vm._l(_vm.groups, function(group) {
                return _c(
                  "optgroup",
                  { key: group.id, attrs: { label: group.name } },
                  _vm._l(group.children, function(company) {
                    return _c(
                      "option",
                      {
                        key: company.key.h,
                        domProps: { value: company.key.e }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(company.name) +
                            "\n                "
                        )
                      ]
                    )
                  }),
                  0
                )
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "login-buttons" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success btn-block btn-lg",
                  attrs: { type: "submit" }
                },
                [_vm._v("Continue")]
              )
            ])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "loginModal",
      staticClass: "login-cover-bg login-box-bg noselect modal",
      attrs: {
        tabindex: "-1",
        role: "dialog",
        "aria-hidden": "true",
        "data-backdrop": "static",
        "data-keyboard": "false"
      }
    },
    [
      _vm.loginTask
        ? _c("login-box", {
            attrs: { "login-state": _vm.loginTask.state, "ajax-login": "" },
            on: { login: _vm.close }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
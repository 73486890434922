
<template>
    <v-form @submit="onSubmit">
        <template slot-scope="{edit}">
            <label>Name <span class="text-danger">*</span></label>
            <div class="row m-b-20">
                <div class="col-6">
                    <v-input placeholder="First Name" inline v-model="edit.fname" required></v-input>
                </div>
                <div class="col-6">
                    <v-input placeholder="Last Name" inline v-model="edit.lname" required></v-input>
                </div>
            </div>

            <label>Email <span class="text-danger">*</span></label>
            <v-input name="email" type="email" placeholder="Email Address" v-model="edit.email" class="m-b-20" required></v-input>


            <label>Password <span class="text-danger">*</span></label>
            <v-input name="password" autocomplete="new-password" class="m-b-20" type="password-confirm" v-model="edit.psw"></v-input>

            <v-input class="m-b-20" v-model="policyConfirm" type="checkbox">By clicking Sign Up, you agree to our <a href="//albertabcsafety.com/terms-and-conditions/" target="_blank">Terms and Conditions</a> and that you have read our <a href="//albertabcsafety.com/privacy-policy/" target="_blank">Privacy / Data Policy</a>.</v-input>

            <div class="d-flex m-b-15 justify-content-center">
                <div id="captcha-container"></div>
            </div>

            <div class="login-buttons">
                <button type="submit" class="btn btn-success btn-block btn-lg" :disabled="!submitEnable"><i v-if="submited" class="far fa-spinner fa-spin mr-3"></i>Sign Up</button>
            </div>
            <div class="m-t-20">
                Have you already registered? Click <a href="javascript:;" @click="$emit('update:state', 'guest')">here</a> to login.
            </div>
        </template>
    </v-form>
</template>

<script>
import { customPost} from '../../scripts/custom-all'
import loginMixin from './loginMixin'
// import * as g from 'https://www.google.com/recaptcha/api.js?onload=onGrecaptchaLoadCallback&render=explicit'
var onGrecaptchaLoadCallback;
const grecaptchaload = new Promise((resolve,reject)=>{
    window.onGrecaptchaLoadCallback = function(){
        resolve();
    }
});
export default {
    name: "register-form",
    mixins: [loginMixin],
    data(){
        return {
            policyConfirm : false,
            recaptcha : null,
            submited : false
        };
    },

    computed : {
        submitEnable(){
            return this.policyConfirm == '1' && this.recaptcha && !this.submited;
        }
    },
    mounted(){
        grecaptchaload.then(()=>{
            grecaptcha.render('captcha-container', {
                sitekey : process.env.VUE_APP_grecaptcha_site_key,
                theme : "dark",
                callback : (token) =>{
                    this.recaptcha = token;
                },
                "expired-callback" : () => {
                    this.recaptcha = null;
                }
            });
        });
    },
    methods : {
        async onSubmit(form){
            if (!this.submitEnable) return;
            form.token = this.recaptcha;
            form.action = "register";
            this.submited = true;
            try {
                await customPost(`${ process.env.VUE_APP_AJAX_PATH}/ajax/functions_ajax_login.php`,form);
                // this.$store.commit('auth/login', "non-activated");
                this.$emit('update:state', 'non-activated');
            } catch (e){
                this.submited = false;
            }
        }
    },
};
</script>
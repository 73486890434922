var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.menus
    ? _c(
        "ul",
        { staticClass: "nav" },
        [
          _vm.pageOptions.pageSidebarSearch
            ? _c("li", { staticClass: "nav-search" }, [
                _c("input", {
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    placeholder: "Sidebar menu filter..."
                  },
                  on: { keyup: _vm.handleSidebarFilter }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("li", { staticClass: "nav-header" }, [_vm._v("Navigation")]),
          _vm._v(" "),
          _vm._l(_vm.menus, function(menu) {
            return [
              _c("sidebar-nav-list", {
                key: menu.path,
                ref: "sidebarNavList",
                refInFor: true,
                attrs: {
                  menu: menu,
                  scrollTop: _vm.scrollTop,
                  status: menu.status
                },
                on: {
                  "collapse-other": function($event) {
                    return _vm.handleCollapseOther(menu)
                  },
                  showFloatSubmenu: _vm.handleShowFloatSubmenu,
                  hideFloatSubmenu: _vm.handleHideFloatSubmenu
                }
              })
            ]
          }),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                staticClass: "sidebar-minify-btn",
                attrs: { href: "javascript:;" },
                on: {
                  click: function($event) {
                    return _vm.handleSidebarMinify()
                  }
                }
              },
              [_c("i", { staticClass: "fa fa-angle-double-left" })]
            )
          ])
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "modal",
      staticClass: "modal fade",
      attrs: {
        tabindex: "-1",
        role: "dialog",
        "data-backdrop": _vm.backdrop,
        "aria-hidden": "true",
        "data-focus": "false"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-dialog-centered",
          class: { "modal-lg": _vm.large },
          attrs: { role: "document" }
        },
        [
          _c(
            "div",
            { staticClass: "modal-content" },
            [
              _vm.key
                ? _c(
                    "VForm",
                    _vm._b(
                      {
                        key: _vm.key,
                        ref: "form",
                        attrs: {
                          disabled: _vm.config.disabled || _vm.$attrs.disabled,
                          object: _vm.obj
                        },
                        on: { submit: _vm.onSubmit },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var edit = ref.edit
                                var disabled = ref.disabled
                                return [
                                  _vm.$scopedSlots.title ||
                                  _vm.title ||
                                  _vm.config.title
                                    ? _c(
                                        "div",
                                        { staticClass: "modal-header" },
                                        [
                                          _c(
                                            "h5",
                                            {
                                              staticClass:
                                                "modal-title width-full"
                                            },
                                            [
                                              _vm._t(
                                                "title",
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.title ||
                                                        _vm.config.title
                                                    )
                                                  )
                                                ],
                                                { edit: edit, close: _vm.close }
                                              )
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "close",
                                              attrs: {
                                                type: "button",
                                                "aria-label": "Close"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.close()
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  attrs: {
                                                    "aria-hidden": "true"
                                                  }
                                                },
                                                [_vm._v("×")]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "modal-body" },
                                    [
                                      _vm._t("default", null, {
                                        edit: edit,
                                        close: _vm.close,
                                        disabled: disabled,
                                        config: _vm.config
                                      })
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  !_vm.noFooter && !_vm.config.noFooter
                                    ? _c(
                                        "div",
                                        { staticClass: "modal-footer" },
                                        [
                                          _vm._t(
                                            "footer",
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-secondary",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.close()
                                                    }
                                                  }
                                                },
                                                [_vm._v("Close")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "btn btn-green"
                                                },
                                                [_vm._v("Submit")]
                                              )
                                            ],
                                            { close: _vm.close, edit: edit }
                                          )
                                        ],
                                        2
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      "VForm",
                      _vm.$attrs,
                      false
                    )
                  )
                : _vm._e()
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "vue-container" } },
    [
      _vm._.size(_vm.components) == 0
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "v-message",
                  {
                    attrs: {
                      title: "No components pinned to dashboard",
                      icon: "fal fa-shapes"
                    }
                  },
                  [
                    _vm._v("\n\t\t\t\t\tClick "),
                    _c(
                      "router-link",
                      { attrs: { to: "/dashboard/settings" } },
                      [_vm._v("here")]
                    ),
                    _vm._v(" to enable components\n\t\t\t\t")
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "transition-group",
        {
          staticClass: "d-flex state-components flex-wrap",
          attrs: { tag: "div", "leave-active-class": "d-none" }
        },
        _vm._l(_vm.UI.state, function(component) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.show,
                  expression: "show"
                }
              ],
              key: component.key.h,
              staticClass: "state-component",
              style: {
                width: _vm.UI.sw + "px"
              },
              attrs: { "data-key": component.key.h }
            },
            [
              _c("v-state", {
                staticClass: "hvr-float width-full",
                attrs: {
                  category: component.category,
                  title: component.title,
                  color: component.color,
                  filter: component.filter,
                  icon: component.icon,
                  label: component.label
                }
              })
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "transition-group",
        {
          staticClass: "d-flex flex-column flex-wrap search-components",
          style: { height: _vm.UI.sh + "px" },
          attrs: {
            tag: "div",
            "leave-active-class": "d-none",
            "keep-alive": ""
          }
        },
        _vm._l(_vm.UI.search, function(ref) {
          var key = ref.key
          var category = ref.category
          var endHeight = ref.endHeight
          var deleting = ref.deleting
          return _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.show,
                  expression: "show"
                }
              ],
              key: key.h,
              staticClass: "search-component",
              style: {
                width: _vm.UI.sw + "px",
                "margin-bottom": endHeight ? _vm.UI.sh - endHeight + "px" : "",
                visibility: deleting ? "hidden" : ""
              },
              attrs: { "data-key": key.h }
            },
            [
              _c(category + "-search-filter", {
                tag: "component",
                on: { search: _vm.onSearch, unpin: _vm.unpin }
              })
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "transition-group",
            {
              staticClass: "row",
              attrs: {
                "enter-active-class": "animated fadeInRight",
                "leave-active-class": "d-none position-absolute",
                tag: "div"
              }
            },
            _vm._l(_vm.items, function(item, index) {
              return _c(
                "div",
                { key: item.id, staticClass: "col-12" },
                [
                  _c(item.type + "-search-result", {
                    tag: "component",
                    attrs: { item: item, results: _vm.results[item.type] },
                    on: {
                      close: function($event) {
                        return _vm.onClose(index)
                      }
                    }
                  })
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
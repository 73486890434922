<template>
    <div id = "course-blocks">
		<div v-for="{courses,category} in _.filter(categories, e => _.size(e.courses))" :key="category">
			<legend v-if="category!='default'" style="font-size: large">
				<h4 class="d-inline"><i class="fas text-info mr-2" :class="getCategoryIcons(category)"></i>{{category}}</h4>
				<button class="btn btn-link float-right text-secondary" @click="toggleCategoryState(category)">
					{{ getCategoryState(category) ? "Hide" :"Show" }}
				</button>
			</legend>
			<div v-collapse="getCategoryState(category)">
				<transition-group
					tag="div"
					class="row"
					enter-active-class = "animated fadeInUp"
					leave-active-class = "d-none"
				>
					<component :is="'course-wrapper-' + course.type" :course="course" v-for="course in courses" :key="course.id" class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-2" :mycourse="mycourses">
						<div class="panel panel-inverse course-block d-flex flex-column width-full position-relative" slot-scope="{btn,hasProgressBar,img,type,hasDiscount}">

							<div class="course-img img d-flex flex-column-reverse width-full" :style="img.style">
								<div v-if="hasProgressBar" class="progress progress-xs rounded-0">
									<div class="progress-bar progress-bar-striped progress-bar-animated" :style="`width: ${course.progress}%`"></div>
								</div>
							</div>  
	

							<div class="panel-body d-flex flex-column flex-grow-1">

								<h5><router-link :to="{name: 'course-details', params: course.shortcut ? {shortcut: course.shortcut } : null, query: !course.shortcut ? {id: course.id} : null}">{{course.name}}</router-link></h5>

								<h6>{{type}}</h6>

								<div class="d-flex align-items-center">
									<star-rating :value="course.avg_rating" readonly></star-rating>
									<span class="pl-3">({{course.num_reviews || 0}})</span>
									<i class="fas fa-user fa-xs pl-1"></i>
								</div>
								<div v-if="course.student_name" class="mt-1 text-right">{{course.student_name}}</div>
								<button @click="btn.onclick" class="btn btn-sm btn-block mt-auto" :class="btn.type" :disabled="btn.disabled" >
									<span v-html="btn.content"/>
								<i class="fas pull-right" :class="btn.icon"></i></button>
							</div>

							
							<span v-if="hasDiscount" class="label label-danger discount-label">{{course.discount}}% OFF</span>
						</div>
					</component>
				</transition-group>
			</div>
		</div>
	</div>
</template>

<script>
import _ from 'lodash';
import courseHelper from './courseHelper'
export default {
    name: 'course-blocks',
    mixins: [courseHelper], 
    props: {
        courses: [Object, Array],
        categoryIcons : Object,
        mycourses: {
            type: Boolean,
            default: false
        }
    },
	data(){
		this.storageKey = "course-block-category-states";
		return {
			categoryStates : JSON.parse( sessionStorage.getItem(this.storageKey) || "{}")
		}
	},
	methods : {
        size: _.size,
		getCategoryIcons(category){
			for (let c in this.categoryIcons){
				if (_.startsWith(category,c)) return this.categoryIcons[c];
			}
		},
		toggleCategoryState(category,state){
			this.$set(this.categoryStates,category,!this.getCategoryState(category))
			sessionStorage.setItem(this.storageKey,JSON.stringify(this.categoryStates))
		},
		getCategoryState(category){
			return _.get(this.categoryStates,category,true)
		}
	},
    computed : {
		categories(){
			let result = {};
			result["default"] = [];

			let addResult = (course,category)=>{
				let list = _.get(result,[category])
				if ( !list ){
					list = [];
					_.set(result,[category],list);
				}
				list.push(course);
			};

			_.each(this.courses,(course)=>{
				if (!course.category){
					course.category = "default";
				}
				if (_.isArray(course.category)){
					_.each(course.category,(c)=>{
						addResult(course,c);
					});
				} else {
					addResult(course,course.category);
				}
			});

			let categories = _.keys(result)
			categories.sort()
            return _.map(categories,category=>({category,courses : result[category]}))
        }
    },
};
</script>

<style>
#course-blocks div.course-img{
	border-top-left-radius: inherit;
	border-top-right-radius: inherit;
    width : 100%;
    height: 174px;
}

#course-blocks .course-block{
    min-height: 330px;
    border: 2px solid #d2d2d2;
    border-radius: 10px;
    min-width: 165px;
}
#course-blocks .discount-label {
	position : absolute;
	right: -5px;
    font-size: 20px!important;
    top: -5px;
}
</style>

import Swal from 'sweetalert2';
import $ from 'jquery';
import _ from 'lodash';
import Search from '../components/search'
// function CheckLogin = (output) => {
// 	if(output && (typeof output["loginState"] === "string")){
// 		this.openLoginBox("guest");
// 		return "no";
// 	}
// };

/** 
 * https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript  
 * */
export function uuidv4() {
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
		(c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
	);
}

Promise.prototype.onLoading = function(enter,leave){
	let solved = 0
	const TIME_BEFORE = 300

	this.then(()=>{
		solved = 1
	})

	timeout(TIME_BEFORE).then(()=>{
		if (!solved){
			enter()
			this.finally(leave)
		}
	})
	return this
}

function initDataTable(table, buttons = []) {
	return table.DataTable({
		responsive: {
			details: {
				type: 'column'
			}
		}, language: { search: "", searchPlaceholder: "Search", "info": "Showing _START_ to _END_ of _TOTAL_", },
		columnDefs: [{
			className: 'control',
			orderable: false,
			targets: 0
		}],
		order: [1, 'asc'],
		dom: 'Blfrtip',
		lengthMenu: [
			[20, 40, 80, -1],
			[20, 40, 80, "All"]
		],
		buttons: buttons,
		colReorder: true,
		fixedHeader: true
	});
}

function UpdateTable(output, search_value) {
	$('#search_value').val(parseInt($('#search_value').val()) + 1);
	for (var i = 1; i <= search_value; ++i) {

		if (search_value == i) {
			$('#div_results' + search_value).html(output['string']);
		}
		if ($('#div_results' + i).length) {
			if (!($('#div_results' + i).html().indexOf('0 records found.') >= 0)) {
				$('#searchData' + i).DataTable({
					responsive: {
						details: {
							type: 'column'
						}
					}, language: { search: "", searchPlaceholder: "Search", "info": "Showing _START_ to _END_ of _TOTAL_", },
					columnDefs: [{
						className: 'control',
						orderable: false,
						targets: 0
					}],
					order: [1, 'asc'],
					dom: 'Blfrtip',
					"lengthMenu": [
						[20, 40, 80, -1],
						[20, 40, 80, "All"]
					],
					buttons: [{
						text: 'PDF',
						className: 'btn btn-primary buttons-excel buttons-html5 btn-sm',
						action: function (e, dt, node, config) {
							var search_value = parseInt($('#search_value').val() - 1, 10);
							var table = $('#searchData' + search_value);
							var args = table.data('search');
							window.open(`${process.env.VUE_APP_AJAX_PATH}/pdf/pdf_generator.php?${args}`);
						}
						/* End of Func */
					},
					{
						extend: 'excel',
						exportOptions: {
							columns: ':visible'
						},
						className: 'btn btn-primary buttons-excel buttons-html5 btn-sm'
					},
						/*{
							extend: 'print',
							customize: function(win) {
								$(win.document.body)
									.css('font-size', '2pt')


								$(win.document.body).find('table')
									.addClass('compact')
									.css('font-size', '2pt');
							},
							exportOptions: {
								columns: ':visible'
							},
							className: 'btn btn-primary buttons-print btn-sm right-margin-print-button'
						},*/
					],
					/*  End of buttons : [{  */
					colReorder: true,
					fixedHeader: true
				});
				$('#searchData' + i).DataTable().draw();
			}
		}
	}
	$("button[id=btnSubmit]").prop('disabled', false);
}

export function MoveAll(search_value) {
	for (var i = 1; i <= search_value; ++i) {
		if ($('#div_results' + i).length && (!($('#div_results' + i).html().indexOf('0 records found.') >= 0))) {
			if (i != search_value) {
				var args = $('#searchData' + i).data('search');
				$('#searchData' + i).DataTable().page.len(-1).draw();
				$('#searchData' + i).DataTable().rows().data();
				$('#div_results' + i).html('<table id="searchData' + i + '" class="table table-striped table-bordered dataTable no-footer dtr-inline dtr-column collapsed" data-search="' + args + '" cellspacing="0" width="100%" style="width: 100%;">' + $('#searchData' + i).html() + '</table>');
				//$('#searchData' + i).DataTable().draw();
				//$('#searchData' + i).DataTable().destroy();
			}
		}
	}
}

export async function customPost(url,data,callback=null,process=true){
	let output = await Vue.prototype.$ajax({
		url,
		method: 'POST',
		data,
		processData : process
	})
	if(_.isObject(output) && !output.status){
		Swal.fire("Error",output.error,"error");
		return Promise.reject(output.error)
	}
	if (callback){
		return callback(output)
	}
	return output
}

export function formatPhone(value) {
	if (!value) {
		return '';
	}

	var x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
	value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
	return value;
}

export function deleteAlert(text = "Are you sure you want delete this?", confirmButtonText = "Delete") {
	return Swal.fire({
			title: "Warning",
			type: "warning",
			text,
			confirmButtonText,
			showCancelButton: true,
			focusCancel: true,
			cancelButtonColor: '#3085d6',
			confirmButtonColor: '#d33',
			reverseButtons: true,
		}).then(r => {
			if (!r.value) throw "";
			return r;
		});
}

Promise.prototype.showLoading = function (config) {
	let solved = 0;
	const TIME_BEFORE = 300;
	let _config = {
		onBeforeOpen: () => Swal.showLoading(),
		allowOutsideClick: () => !Swal.isLoading()
	};
	if (_.isString(config)) {
		_config.title = config;
	} else if (_.isObject(config)) {
		_.assign(_config, config);
	}
	this.then(() => {
		solved = 1;
	});
	timeout(TIME_BEFORE).then(() => {
		if (!solved) {
			Swal.fire(_config);
			this.then(() => {
				if (Swal.isLoading()) {
					Swal.close();
				}
			})
		}
	});
	return this;
}

export const SwalDelete = Swal.mixin({
	title : "Are you sure?",
	text: "You won't be able to revert this!",
	type : "warning",
	confirmButtonText: 'Delete',
	showCancelButton: true,
	confirmButtonColor: '#d33',
	cancelButtonColor: '#3085d6',
	reverseButtons : true,
	focusCancel: true
});

export const Toast = Swal.mixin({
	type : "success",
	toast: true,
	position: 'bottom',
	showConfirmButton: false,
	timer: 2000
});

export function timeout(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

export function promisify(context, index = null) {
	let fn;
	if (index == null) {
		fn = context;
	} else {
		fn = context[index].bind(context);
	}
	return (...args) => new Promise(resolve => {
		args.push((...rs) => resolve(rs));
		fn(...args);
	});
}
$(function () {
	// config gritter
	$.extend($.gritter.options, {
		position: 'top-right',
	});
	
	// supporting multiple modal
	$("body").on("hidden.bs.modal", ".modal", () => {
		if ($(".modal.show").length != 0) {
			$("body").addClass("modal-open");
		}
	});

	// toggle full screen
	$(document).on('click', '[data-click=fullscreen]', function () {
		if (!document.fullscreenElement) {
			let element = document.documentElement;
			let s = $(this).closest(".fullscreen-root");
			if (s.length) {
				element = s[0];
			}
			if (element.requestFullscreen) {
				element.requestFullscreen();
			} else if (element.mozRequestFullScreen) {
				element.mozRequestFullScreen();
			} else if (element.webkitRequestFullscreen) {
				element.webkitRequestFullscreen();
			} else if (element.msRequestFullscreen) {
				element.msRequestFullscreen();
			}
		} else {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen();
			}
		}
	});

	document.addEventListener("fullscreenchange", function () {
		let icons = $("[data-click=fullscreen] i");
		if ($("body").hasClass("fullscreen")) {
			$("body").removeClass("fullscreen");
			icons.removeClass("fa-compress");
			icons.addClass("fa-expand");
		} else {
			$("body").addClass("fullscreen");
			icons.removeClass("fa-expand");
			icons.addClass("fa-compress");
		}
	});
});

export function loadSearchComponents() {
    let promises = [];
    for (let e of _.keys(Search)) {
        if (typeof Search[e] == 'function') {
            let result = Search[e]();
            if (result instanceof Promise) {
                promises.push(result);
            }
        }
    }
    return Promise.all(promises);
}

import Vue from 'vue'


export async function doSearchAction(filter,config){
    filter = _.pickBy(filter,(val)=>val!=='');
    
    let regexp = new RegExp("^(([+-]?[0-9]+[mdyw])|(INF)),(([+-]?[0-9]+[mdyw])|(INF))$");
    _.each(filter,(value,key)=>{
        if (_.isString(value) &&  value.match(regexp)){
            filter[key] = Vue.prototype.$parseDaterange(value);
        } else if (_.isObject(value) && value.h && value.e){
            filter[key] = value.e;
        }
    });
    let {result,info} = await customPost(config.url,{
        action : config.action,
        filter
    });
    return [result,filter,info];
}
export default [
    
  {
    path: '/ticketing/my_tickets',
    name: 'tickets',
    meta: {
      title: "My Support Tickets",
      viewerLevel: 1, 
    }, 
    component: () => import(/* webpackChunkName: "my-tickets" */ '../views/Tickets/TicketList.vue')
  },
  {
    path: '/tickets/:id',
    name: 'view-ticket',
    meta: {
      title: "Ticket Info",
      viewerLevel: 1,
    }, 
    component: () => import(/* webpackChunkName: "ticket" */ '../views/Tickets/ViewTicket.vue'),
    props: true
  },
]
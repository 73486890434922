<template>
    <div class="theme-panel" ref="tp">
        <a href="javascript:;" data-click="theme-panel-expand" class="theme-collapse-btn">
            <i class="fa text-info" :class="{'fa-sliders-h-square': !icon, [icon]: true}"></i>
        </a>
        <div class="theme-panel-content">
            <slot></slot>
        </div>
    </div>
</template>
<script>
import $ from 'jquery'
export default {
    props: {
        icon: String
    },
    async mounted() {
        var handleThemePanelExpand = () => {
            $(this.$refs.tp.$el).on('click', '[data-click="theme-panel-expand"]', () => {
                var targetContainer = '.theme-panel';
                var targetClass = 'active';
                if ($(targetContainer).hasClass(targetClass)) {
                    $(targetContainer).removeClass(targetClass);
                } else {
                    $(targetContainer).addClass(targetClass);
                }
            });
        };
        handleThemePanelExpand();
    }
}
</script>
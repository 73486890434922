
<template>
	<div>
		<widget-modal @opened="fix" ref="sigModal">
			<template #title>Signature Required</template>
			<div class='row'>
				<div class='alert alert-primary col-12' v-if="!error">
					Our records indicate we do not have a copy of your signature. 
					As an instructor, your signature is required. 
				</div>
				<div v-else class="alert alert-danger col-12">
					No signature was dettected
				</div>
				<div class='col-12'>
					<sign-in-canvas ref="sic"></sign-in-canvas>
				</div>
				<div class='col-12'>
					<button class='pull-right btn btn-green' @click="submitSignature()">Submit</button>
				</div>
			</div>
		</widget-modal>
	</div>
</template>
<script>
import Swal from 'sweetalert2';
import { WidgetModal } from '../common'
export default {
    mounted() {
        this.checkSignature()
    },
    data() {
        return {
            error: null
        }
    },
    methods: {
        async fix() {
            if (typeof(Event) === 'function') {
                // modern browsers
                window.dispatchEvent(new Event('resize'));
            } else {
                // for IE and other old browsers
                // causes deprecation warning on modern browsers
                var evt = window.document.createEvent('UIEvents');
                evt.initUIEvent('resize', true, false, window, 0);
                window.dispatchEvent(evt);
            }
        },
        async checkSignature() {
            // checks if instructor has signaure
            // some post
            let res = await this.$ajax(`/ajax/functions_ajax_sig.php`, 'POST', {
                action: "checkSig"
            })
            if (res.result) {
                this.$refs.sigModal.open();
                await this.$nextTick();
                this.$refs.sic.resizeCanvas();
            }
        },
        async submitSignature() {
            let sig = await this.$refs.sic.getSignature()
            if (sig) {
                let res = await this.$ajax(`/ajax/functions_ajax_sig.php`, "POST", {
                    action: 'submitSig',
                    sig
                })
                if (res.result) {
                    this.$refs.sigModal.close();
                } else {
                    Swal.fire(res.error);
                }
            } else {
                this.error = "No signature detected.";
            }
        }
    },
}
</script>
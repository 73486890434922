var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "widget-modal",
        {
          ref: "sigModal",
          on: { opened: _vm.fix },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function() {
                return [_vm._v("Signature Required")]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            !_vm.error
              ? _c("div", { staticClass: "alert alert-primary col-12" }, [
                  _vm._v(
                    "\n\t\t\t\tOur records indicate we do not have a copy of your signature. \n\t\t\t\tAs an instructor, your signature is required. \n\t\t\t"
                  )
                ])
              : _c("div", { staticClass: "alert alert-danger col-12" }, [
                  _vm._v("\n\t\t\t\tNo signature was dettected\n\t\t\t")
                ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12" },
              [_c("sign-in-canvas", { ref: "sic" })],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c(
                "button",
                {
                  staticClass: "pull-right btn btn-green",
                  on: {
                    click: function($event) {
                      return _vm.submitSignature()
                    }
                  }
                },
                [_vm._v("Submit")]
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }

import _ from 'lodash'
import s from '../store'
let createLocalStore = function(id,config){
    let key = `${id}-${s.state.auth.account}`

    let getStore = ()=>{
        let store =  localStorage.getItem(key);

        if (store){
            store = JSON.parse(store)

            if (_.isObject(store)){
                return store
            }
        }

        return config.default
    }

    let store = getStore()

    let handler = {
        set(obj,prop,value){
            obj[prop] = value
            localStorage.setItem(key,JSON.stringify(store))
            return true
        }
    }

    return new Proxy(store,handler)
}

export function createStore(id,config){
    _.defaults(config,{
        type : "local",
        version : null,
        default : {}
    })
    switch (config.type){
        case "local":
            return createLocalStore(id,config)
        default:
            throw `Unexpected Store Type: ${config.type}`
    }
}



var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("default", null, { page: _vm.page, index: _vm.pageIndex }),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex vt-footer" }, [
        _c(
          "div",
          {
            staticClass: "vt-info",
            attrs: { role: "status", "aria-live": "polite" }
          },
          [
            _vm._v(
              "\n            " + _vm._s(_vm.info(_vm.pageIndex)) + "\n        "
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "v-pagination" }, [
          _c(
            "ul",
            { staticClass: "pagination" },
            [
              _c(
                "li",
                {
                  staticClass: "page-item",
                  class: { disabled: _vm.pageIndex == 0 }
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "page-link",
                      attrs: { href: "javascript:;", tabindex: "0" },
                      on: {
                        click: function($event) {
                          _vm.pageIndex -= 1
                        }
                      }
                    },
                    [_vm._v("Previous")]
                  )
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.pageStatus, function(status, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    staticClass: "page-item",
                    class: {
                      active: index == _vm.pageIndex,
                      disabled: status == "disable"
                    }
                  },
                  [
                    status == "active"
                      ? _c(
                          "a",
                          {
                            staticClass: "page-link",
                            attrs: { href: "javascript:;", tabindex: "0" },
                            on: {
                              click: function($event) {
                                _vm.pageIndex = index
                              }
                            }
                          },
                          [_vm._v(_vm._s(index + 1))]
                        )
                      : status == "disable"
                      ? _c(
                          "a",
                          {
                            staticClass: "page-link",
                            attrs: { href: "javascript:;", tabindex: "0" }
                          },
                          [_vm._v("...")]
                        )
                      : _vm._e()
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "page-item",
                  class: { disabled: _vm.pageIndex == _vm.pages.length - 1 }
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "page-link",
                      attrs: { href: "javascript:;", tabindex: "0" },
                      on: {
                        click: function($event) {
                          _vm.pageIndex += 1
                        }
                      }
                    },
                    [_vm._v("Next")]
                  )
                ]
              )
            ],
            2
          )
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
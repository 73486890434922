var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-input",
    {
      attrs: {
        label: "Calendars",
        type: "select2",
        required: _vm.required,
        "template-result": _vm.calendarOptionTemplate,
        "template-selection": function(state) {
          return _vm.calendars[state.id].abbrev
        },
        value: _vm.value,
        inline: _vm.inline,
        multiple: ""
      },
      on: {
        input: function($event) {
          return _vm.$emit("input", $event)
        }
      }
    },
    _vm._l(
      _vm._.filter(_vm.calendars, function(c) {
        return c.active == 1
      }),
      function(c) {
        return _c("option", { key: c.id, domProps: { value: c.id } }, [
          _vm._v(_vm._s(c.name) + " (" + _vm._s(c.abbrev) + ")")
        ])
      }
    ),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
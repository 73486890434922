<template>
    <div class="login-cover-bg login-box-bg noselect modal" tabindex="-1" ref="loginModal" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <login-box
            v-if="loginTask"
            @login="close"
            :login-state="loginTask.state"
            ajax-login
        ></login-box>
    </div>
</template>
<script>
import $ from 'jquery'
import LoginBox from './LoginBox'
export default {
    name : "login-modal",
    components: {LoginBox},
    data : () => ({
        loginTask : null
    }),
    created(){
        window.CheckLogin = (output)=>{
            if(output && (typeof output["loginState"] === "string")){
                this.openLoginBox("guest");
                return "no";
            }
        };
    },
    mounted(){
        this.$appReady();
    },
    methods :{
        close(){
            if (this.loginTask) this.loginTask.resolve();
            $(this.$refs.loginModal).modal('hide');
            this.$emit('login');
        },
        openLoginBox(state){
            new Promise((resolve,reject)=>{
                this.loginTask = {
                    state,
                    resolve,
                    reject
                };
                this.$bus.$emit("open-login-box",state);
                $(this.$refs.loginModal).modal("show");
            }).then(()=>{
                $(this.$refs.loginModal).modal("hide");
                this.loginTask = null;
                this.$bus.$emit("login");
            });
            this.$store.commit('auth/login', state); // really this should be done in the checkLoginState
        }
    },
}
</script>
<script>
    export default {
        name: 'v-message',
        props : {
            title : String,
            text : String,
            icon : {
                default : "fal fa-do-not-enter"
            },
            iconColor : {
                default : "text-info"
            }
        
        },
    };
</script>

<template>
    <div class="panel panel-inverse">
        <span>
            <div class="d-flex justify-content-center" style="padding: 25px 0px; font-size: 10em;">
                <i :class="icon + ' ' +iconColor "></i>
            </div>
            <h4 v-if="title" style="text-align:  center;font-weight: 700;font-size: 24px;color: #707478;">{{title}}</h4>
            <p style="text-align: center;font-size: 14px;">{{text}}<slot></slot></p>
        </span>
        <br/>						
    </div>
</template>
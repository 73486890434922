import $ from 'jquery';
import _ from 'lodash';
import store from '../store'

export async function addToCart(id, event_id = '', callback = null) {
	let output = await store.dispatch('cart/addToCart', id, event_id)

	if (output.success) {
		if (_.size(output.added)) {
			$.toast({
				heading: 'Item added to Cart',
				text: "Click <a href='/cart/shopping_cart'>here</a> to proceed to checkout.",
				position: 'bottom-center',
				bgColor: '#00acac',
				stack: false
			});
		}
	}

	if (callback) {
		callback(output);
	}
}


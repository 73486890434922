export default [
    
  /* CART */
  {
    path: '/cart/shopping_cart',
    alias: '/cart/shopping_cart.php', 
    name: 'shopping-cart',
    meta: {
      title: "My Shopping Cart", 
      guest: true, 
      breadcrumbs: ['My Shopping Cart'],
    }, 
    component: () => import(/* webpackChunkName: "shopping-cart" */"../views/Cart/ShoppingCart.vue")
  },
  {
    path: '/cart/checkout',
    name: 'checkout',
    meta: {
      title: "Review Your Order", 
      guest: true,
      breadcrumbs: ['Cart', 'Checkout'],
    }, 
    component: () => import(/* webpackChunkName: "checkout" */"../views/Cart/Checkout.vue")
  },
  {
    path: '/cart/summary/:id',
    name: 'summary',
    meta: {
      title: "Checkout Summary", 
      guest: true,
      breadcrumbs: ['Cart', 'Checkout Summary'], 
    }, 
    component: () => import(/* webpackChunkName: "summary" */"../views/Cart/PostPaymentPage.vue"), 
    props: true
  },
]
<script>
import CourseBlocks from '../courses/CourseBlocks';
import { customPost } from '../../scripts/custom-all';
export default {
    components: {CourseBlocks}, 
    created() {
        this.getCourses();
    },
    data(){
        return {
            course_list :  [],
            categoryIcons : {
                "Featured" : "fa-star",
                "Recommended" : "fa-thumbs-up",
                "Assigned" : "fa-hand-holding-box",
                "Purchased" : "fa-shopping-cart"
            }
        };
    },
    methods: {
        async getCourses() {
            let {result: courses} = await customPost(`/ajax/functions_ajax_dashboard.php`, {
                action: "getCourses"
            })
            this.course_list = courses;
        }
    },
    template: "<course-blocks :courses='course_list' :category-icons='categoryIcons' ></course-blocks>"
};
</script>
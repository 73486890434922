export default [
    
  /* INVOICES */
  {
    path: '/invoices/view/:id',
    name: 'edit-invoice',
    meta: {
      title: "Invoice Details",
      viewerLevel: 8
    }, 
    component: () => import(/* webpackChunkName: "edit-invoice" */"../views/Invoices/EditInvoice.vue"),
    props: true
  },
  {
    path: '/invoices/invoice_agreements',
    name: 'invoice-agreements',
    meta: {
      title: "Invoice Agreements",
      viewerLevel: 8
    }, 
    component: () => import(/* webpackChunkName: "invoice-agreements" */"../views/Invoices/InvoiceAgreements.vue"),
  },
  {
    path: '/invoices/accounting_settings',
    name: 'accounting-settings',
    meta: {
      title: "Accounting Settings",
      viewerLevel: 8
    }, 
    component: () => import(/* webpackChunkName: "accounting-settings" */"../views/Accounting/AccountingSettings.vue"),
  }, 
  {
    path: '/invoices/all',
    name: 'all-invoicing',
    meta: {
      title: "All Invoices", 
      viewerLevel: 7
    }, 
    component: () => import(/* webpackChunkName: "manage-partnerships" */"../views/Invoices/Invoicing.vue")
  },
]
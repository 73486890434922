import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import cart from './cart'
Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        dark: false
    },
    mutations: {
        dark(state, i) {
            state.dark = i
        }
    }, 
    modules: {
        auth,
        cart
    }
})
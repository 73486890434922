
<template>
<div>
	<div class="row" v-if="promos.length && !skipHeader">
		
		<div class="col-md-3" id="learn_dash_img1">
			<div class="panel panel-inverse" >
				<div class="panel-body" :style="`height:  275px;background-image: url(${assets_path}${promos[0]});background-position: 50% 50%;width:  100%;background-size:  cover;`">
				</div>
			</div>
		</div>

		<div class="col-md-6" id="learn_dash_img2" >
			<div class="panel panel-inverse" >
				<div class="panel-body" :style="`height:  275px;background-image: url(${assets_path}${promos[1]});background-position: 50% 50%;width:  100%;background-size:  cover;`">
				</div>
			</div>
		</div>

		<div class="col-md-3" id="learn_dash_img3">
			<div class="panel panel-inverse">
				<div class="panel-body" :style="`height:  275px;background-image: url(${assets_path}${promos[2]});background-position: 50% 50%;width:  100%;background-size:  cover;`">
				</div>
			</div>
		</div>

		<div v-for='(block,i) in blocks' :key="i" :class="`col-md-${block[4]}`">
			<div class="panel panel-inverse  hvr-float-shadow d-block pointer-cursor" @click="$router.push({name: block[5]})">
				<div class="panel-body d-flex flex-column align-items-center">
					<i class="fal text-info fa-5x" :class="block[2]"></i>
					<h4 class="f-w-700 mt-2">{{block[0]}}</h4>
					<p>{{block[1]}}</p>
				</div>
			</div>
		</div>
    </div>
    <signature-check v-if="instructor"></signature-check>

	<dashboard-courses></dashboard-courses>
	<site-picker></site-picker>
	<event-detail></event-detail>
</div>
</template>
<script>
import SignatureCheck from '../../components/instructor/SignatureCheck.vue'
import DashboardCourses from '../../components/dashboard/DashboardCourses.vue'
import EventDetail from '../../components/wrapper/EventDetail';
import SitePicker from '../../components/wrapper/SitePicker';
import { customPost } from '../../scripts/custom-all';
export default {
    components: {SignatureCheck, DashboardCourses, EventDetail, SitePicker }, 
	props: {
		skipHeader: Boolean
	}, 
    data() {
        return {
            blocks: [],
            promos: [],
            instructor: false,
            assets_path: process.env.VUE_APP_ASSETS_PATH
        }
    },
    created() {
        this.getInit()
	},
    methods: {
        async getInit() {
            let response = await this.$ajax(`/ajax/functions_ajax_dashboard.php`,  "POST", {
                action:  "getInit"
            });
            if (response.result) {
                let res = response.result;
                this.blocks = res.blocks;
                this.promos = res.promos;
            }
        },
    },
}
</script>
export default [
  /* Company */
  {
    path: '/company/settings',
    name: 'company-settings',
    meta: {
      title: "Company Settings", 
      viewerLevel: 10
    }, 
    component: () => import(/* webpackChunkName: "company-settings" */"../views/Company/CompanySettings.vue")
  },
  {
    path: '/partnerships/manage_partnerships',
    name: 'company-partnerships',
    meta: {
      title: "Manage Partnerships", 
      viewerLevel: 7
    }, 
    component: () => import(/* webpackChunkName: "manage-partnerships" */"../views/Company/ManagePartnerships.vue")
  },
  {
    path: '/company/partnerships/:id',
    name: 'partner-detail',
    meta: {
      title: "Partnership Detail", 
      viewerLevel: 7
    }, 
    component: () => import(/* webpackChunkName: "partnership-detail" */"../views/Company/PartnerDetail.vue"), 
    props: true
  },
  {
    path: '/timesheet/employees',
    name: 'company-employees',
    meta: {
      title: "Employees", 
      viewerLevel: 7
    }, 
    component: () => import(/* webpackChunkName: "company-employees" */"../views/Company/StaffPage.vue")
  },
  {
    path: '/materials/material_list',
    name: 'company-courses',
    meta: {
      title: "Manage Courses", 
      viewerLevel: 8
    }, 
    component: () => import(/* webpackChunkNmae: "course-list" */"../views/Company/ManageCourses.vue")
  },
  {
    path: '/company/courses/edit/:id',
    name: 'edit-course',
    meta: {
      title: "Edit Course", 
      viewerLevel: 8
    }, 
    component: () => import(/* webpackChunkNmae: "edit-course" */"../views/Courses/EditCourse.vue"),
    props: true
  },
  {
    path: '/audit/audit_form_templates',
    name: 'company-audits',
    meta: {
      title: "Audit Template Lists", 
      viewerLevel: 4
    }, 
    component: () => import(/* webpackChunkName: "company-audits" */"../views/Company/AuditForms.vue")
  },
  {
    path: '/materials/manage_coupons',
    name: 'manage-coupons',
    meta: {
      title: "Manage Coupons", 
      viewerLevel: 8
    }, 
    component: () => import(/* webpackChunkName: "course-coupons" */"../views/Company/CourseCoupons.vue")
  },
  {
    path: '/scheduling/site_specifications',
    name: 'offered-sites',
    meta: {
      title: "Offered Sites", 
      viewerLevel: 9
    }, 
    component: () => import(/* webpackChunkName: "offered-sites" */"../views/Company/OfferedSites.vue")
  },
  {
    path: '/courses/course_specifications',
    name: 'offered-courses',
    meta: {
      title: "Offered Courses", 
      viewerLevel: 9,
      breadcrumbs: ['Course Management', 'Offered Courses'],
    }, 
    component: () => import(/* webpackChunkName: "offered-Courses" */"../views/Company/OfferedCourses.vue")
  },
  {
    path: '/notifications/system_notifications',
    name: 'system-notifications',
    meta: {
      title: 'System Notifications',
      viewerLevel: 10,
      breadcrumbs: ['Notications', 'System Notifications']
    },
    component: () => import(/* webpackChunkName: "system-notifications" */"../views/Notifications/SystemNotifications.vue")
  },
  {
    path: '/courses/prereq_certs/:id', 
    props: true, 
    component: () => import(/* webpackChunkName: "prereq-certs" */"../views/Courses/CoursePrereqCerts.vue")    
  }, 
]
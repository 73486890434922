var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h4", { staticClass: "text-white" }, [_vm._v("Set up your password.")]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "We have sent an email to you, click the link in it to set your password."
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "m-t-20" }, [
      _vm._v("\n        Click "),
      _c(
        "a",
        {
          attrs: { href: "javascript:;" },
          on: {
            click: function($event) {
              return _vm.$emit("update:state", "guest")
            }
          }
        },
        [_vm._v("here")]
      ),
      _vm._v(" go back to login.\n    ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "v-node d-flex align-items-center border-left border-bottom",
        class: { "dragging-over": _vm.$vstore.draggingOver == _vm.id },
        attrs: { draggable: _vm.draggable },
        on: {
          click: function($event) {
            return _vm.toggle()
          },
          dragstart: _vm.dragstart,
          dragend: _vm.dragend,
          dragover: _vm.dragover,
          drop: _vm.drop
        }
      },
      [
        _c("i", {
          staticClass: "menu-caret d-inline-flex justify-content-center",
          class: { open: _vm.isOpen, invisible: !_vm.state }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "noselect" }, [_vm._v(_vm._s(_vm.name))]),
        _vm._v(" "),
        _c("span", { staticClass: "icons ml-5" }, [
          _c(
            "a",
            {
              staticClass: "text-green",
              attrs: { href: "javascript:;" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  _vm.emit("create", _vm.id, function() {
                    return _vm.toggle(true)
                  })
                }
              }
            },
            [_c("i", { staticClass: "fas mr-2 fa-plus" })]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "text-info",
              attrs: { href: "javascript:;" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.emit("edit", _vm.id)
                }
              }
            },
            [_c("i", { staticClass: "fas mr-2 fa-edit" })]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "text-danger",
              attrs: { href: "javascript:;" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.emit("delete", _vm.id)
                }
              }
            },
            [_c("i", { staticClass: "fas fa-trash-alt" })]
          )
        ])
      ]
    ),
    _vm._v(" "),
    _vm.isBuild
      ? _c(
          "ul",
          {
            directives: [
              {
                name: "collapse",
                rawName: "v-collapse",
                value: _vm.isOpen,
                expression: "isOpen"
              }
            ]
          },
          _vm._l(_vm.children, function(child) {
            return _c(
              "li",
              { key: child },
              [_c("v-node", { key: child, attrs: { id: child } })],
              1
            )
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
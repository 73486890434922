<template>
    <div class="row mt-2">
        <div class="col-3">
            <label>{{ label }}</label>
        </div>
        <div class="col">
            <slot>{{ value }}</slot>
        </div>
    </div>
</template>
<script>
export default {
    name: "v-info", 
    props: {
        label: String,
        value: String
    },
}
</script>
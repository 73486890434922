

<template>
    <div class="widget widget-stats v-state"  :style="{'background-color' : Color(color).toString(), 'color' : Color(color).invert.toString()}">
        <div class="stats-icon" v-if="icon != null && icon != ' '"><i :class="$getIcon(icon)"></i></div>
        <div class="stats-info">
            <h4>{{title}}</h4>
            <p>
                <transition
                    enter-active-class="animated fadeIn"
                >
                    <span v-if="display != null">
                        <span class="m-r-3" v-if="labels.prepend">{{labels.prepend}}</span> 
                        <animated-integer v-if="_.isNumber(display)" :value="display"></animated-integer>
                        <span v-else>{{display}}</span>
                        <span class="m-l-3" v-if="labels.append">{{labels.append}}</span> 
                    </span>   
                    <span v-if="error" class="text-danger">Error</span>
                </transition>   
                &nbsp;
            </p>	
        </div>
        <div class="stats-link" >
            <router-link  v-if="url" :to="url" target="_blank" @click.stop>View Detail <i class="far fa-arrow-alt-circle-right"></i></router-link>
            <a v-else> &nbsp;</a>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import _searchComponentConfigs from '../search/scc';

import AnimatedInteger from '../transition/AnimatedInteger'
import { doSearchAction } from '../../scripts/custom-all';
export default {
    components: {
        AnimatedInteger
    },
    props : {
        color : String,
        icon : String,
        title : String,
        category : String,
        filter : String,
        label : String,
        value : null
    },
    data : () => ({
        error : false, 
        config: null
    }),
    async created() {   
        let ucfirst = (str) => str.charAt(0).toUpperCase() + str.slice(1)
        // the only reason we need to import, is to get the _searchComponentConfig,
        // which should be available immediately..
        // i think we need to move these to 
        // thier own file again 
        // this would make loading this component easier
        // because right now it involves "loading" the ENTIRE search
        // compoonnet into memory, when only
        // four lines of the config file are needed
        if (!_searchComponentConfigs[this.category])
            await import(`../search/${ucfirst(_.camelCase(this.category))}Search.js`)   
        this.config = _searchComponentConfigs[this.category];
    }, 
    computed : {
        url(){
            if (this.config && this.config.origin){
                return `${this.config.origin}?filter=${encodeURIComponent(this.filter)}`;
            }
            return null;
        },
        labels(){
            if (this.config && (this.config.prepend || this.config.append)) {
                return _.pick(this.config, ["prepend", "append"])
            } else {
                return {
                    append : this.label
                }
            }
        }
    },
    asyncComputed : {
        async display() {
            if (this.value) return this.value;
            if (!this.config) return;
            try {
                let [r,f] = await doSearchAction(JSON.parse(this.filter),this.config);
                if (this.config.reduce){
                    return  this.config.reduce(r);
                } else {
                    return _.size(r);
                }
            } catch (e) {
                this.error = true;
            }
        }
    },
}
</script>
<style scoped>
.v-state h4 {
    color : inherit !important;
}
</style>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-form-modal", {
    ref: "vfm",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var edit = ref.edit
          return [
            _vm._l(edit.values, function(value, index) {
              return [
                _c(
                  "v-input",
                  _vm._b(
                    {
                      key: index,
                      attrs: { autofocus: index == 0 },
                      model: {
                        value: edit.values[index],
                        callback: function($$v) {
                          _vm.$set(edit.values, index, $$v)
                        },
                        expression: "edit.values[index]"
                      }
                    },
                    "v-input",
                    _vm.configs[index],
                    false
                  )
                )
              ]
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
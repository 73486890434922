const icons = [
	{
		"id" : 0,
		"name" : "Card",
		"class" : "far fa-id-card"
	},
	{
		"id" : 1,
		"name" : "User",
		"class" : "far fa-users"
	},
	{
		"id" : 2,
		"name" : "Class",
		"class" : "far fa-users-class"
	},
	{
		"id" : 3,
		"name" : "Calendar",
		"class" : "far fa-calendar-alt"
	},
	{
		"id" : 4,
		"name" : "Student",
		"class" : "far fa-file-user"
	},
	{
		"id" : 5,
		"name" : "Question Mark",
		"class" : "fas fa-question"
	},
	{
		"id" : 7,
		"name" : "Dollar Sign",
		"class" : "far fa-dollar-sign"
	},
	{
		"id" : 8,
		"name" : "File",
		"class" : "fas fa-file-alt"
	},
	{
		"id" : 9,
		"name" : "User Clock",
		"class" : "fas fa-user-clock"
	},
	{
		"id" : 10,
		"name" : "Instructor",
		"class" : "fas fa-chalkboard-teacher"
	},
	{
		"id" : 11,
		"name" : "Receipt",
		"class" : "fas fa-receipt"
	},
	{
		"id" : 12,
		"name" : "Invoice",
		"class" : "far fa-file-invoice-dollar"
	},
	{
		"id" : 13,
		"name" : "List",
		"class" : "far fa-list"
	},
	{
		"id" : 14,
		"name" : "Comment",
		"class" : "far fa-comments"
	},
	{
		"id" : 15,
		"name" : "Certificate",
		"class" : "fas fa-certificate"
	},
	{
		"id" : 16,
		"name" : "Book",
		"class" : "far fa-book"
	},
	{
		"id" : 17,
		"name" : "Ticket",
		"class" : "fal fa-ticket-alt"
	},
	{
		"id" : 18,
		"name" : "Check Mark",
		"class" : "far fa-check"
	},
	{
		"id" : 19,
		"name" : "Times",
		"class" : "far fa-times"
	}
];

export default {
    install(Vue)  {
        Vue.prototype.$_VUE_ICONS = icons;
        Vue.prototype.$getIcon = function(id){
			if (id == 0) return "";
			let i = icons.find(e => e.id == id)
            return i && i.class || 0;
        }
    }
}
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-pagination", {
    ref: "pagination",
    staticClass: "container-fluid v-table",
    attrs: { items: _vm.sorted, "item-per-page": _vm.ipg, info: _vm.info },
    on: { updated: _vm.updateStickPos },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function(ref) {
            var page = ref.page
            return [
              _c("div", { staticClass: "vt-buttons" }, [_vm._t("buttons")], 2),
              _vm._v(" "),
              _c("div", { staticClass: "vt-length" }, [
                _c("label", [
                  _vm._v("\n                Show \n                "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.ipg,
                          expression: "ipg"
                        }
                      ],
                      staticClass: "form-control form-control-sm",
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.ipg = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    [
                      _vm._l(_vm.itemPerPageOptions, function(option) {
                        return _c(
                          "option",
                          { key: option, domProps: { value: option } },
                          [_vm._v(_vm._s(option))]
                        )
                      }),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "-1" } }, [_vm._v("All")])
                    ],
                    2
                  ),
                  _vm._v(" \n                entries\n            ")
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "vt-filter" }, [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.keyWord,
                        expression: "keyWord",
                        modifiers: { trim: true }
                      }
                    ],
                    staticClass: "form-control form-control-sm",
                    attrs: { type: "search", placeholder: "Search" },
                    domProps: { value: _vm.keyWord },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.keyWord = $event.target.value.trim()
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "vt-table d-flex" }, [
                _c("table", [
                  _c("thead", [
                    _c(
                      "tr",
                      _vm._l(_vm.vt_fields, function(field, i) {
                        return _c(
                          "th",
                          {
                            key: field.name,
                            class: {
                              sorting: field.sortable,
                              ascending: field.sorting == 1,
                              descending: field.sorting == -1,
                              sticky: field.sticky
                            },
                            style: {
                              width: field.width,
                              minWidth: field.width,
                              maxWidth: field.width
                            },
                            attrs: { draggable: "", "data-name": field.name },
                            on: {
                              click: function($event) {
                                return _vm.sort(field)
                              },
                              dragstart: function($event) {
                                return _vm.dragstart($event, field)
                              },
                              dragend: _vm.dragend,
                              dblclick: function($event) {
                                return _vm.stick(field)
                              }
                            }
                          },
                          [
                            _c("span", {
                              staticClass: "dropline",
                              class: {
                                "dragging-over": _vm.draggingOver == i * 2
                              },
                              on: {
                                dragover: function($event) {
                                  return _vm.dragover($event, i * 2)
                                },
                                dragleave: _vm.dragleave,
                                drop: function($event) {
                                  return _vm.drop($event, field, 0)
                                }
                              }
                            }),
                            _vm._v(" "),
                            field.headSlot
                              ? _vm._t(field.name + "-head")
                              : _c("span", [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(field.head) +
                                      "\n                            "
                                  )
                                ]),
                            _vm._v(" "),
                            field.sticky
                              ? _c("span", { staticClass: "underline" })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", {
                              staticClass: "dropline",
                              class: {
                                "dragging-over": _vm.draggingOver == i * 2 + 1
                              },
                              on: {
                                dragover: function($event) {
                                  return _vm.dragover($event, i * 2 + 1)
                                },
                                dragleave: _vm.dragleave,
                                drop: function($event) {
                                  return _vm.drop($event, field, 1)
                                }
                              }
                            })
                          ],
                          2
                        )
                      }),
                      0
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    [
                      _vm._l(page, function(row, idx) {
                        return _c(
                          "tr",
                          { key: idx },
                          _vm._l(_vm.vt_fields, function(field) {
                            return _c(
                              "td",
                              {
                                key: field.name,
                                class: _vm.css(field, row),
                                attrs: { "data-name": field.name }
                              },
                              [
                                field.slot
                                  ? _vm._t(field.name, null, { row: row })
                                  : field.html
                                  ? _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          field.html(row, field.name)
                                        )
                                      }
                                    })
                                  : _vm.display(field, row) != null
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.display(field, row)) +
                                          "\n                            "
                                      )
                                    ])
                                  : _c("span", { staticClass: "text-muted" }, [
                                      _vm._v(
                                        "\n                                None\n                            "
                                      )
                                    ])
                              ],
                              2
                            )
                          }),
                          0
                        )
                      }),
                      _vm._v(" "),
                      page.length == 0
                        ? _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "text-center",
                                attrs: { colspan: _vm.vt_fields.length }
                              },
                              [_vm._v("No matching records found")]
                            )
                          ])
                        : _vm._e()
                    ],
                    2
                  )
                ])
              ])
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
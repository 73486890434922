var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "tp", staticClass: "theme-panel" }, [
    _c(
      "a",
      {
        staticClass: "theme-collapse-btn",
        attrs: { href: "javascript:;", "data-click": "theme-panel-expand" }
      },
      [
        _c("i", {
          staticClass: "fa text-info",
          class:
            ((_obj = { "fa-sliders-h-square": !_vm.icon }),
            (_obj[_vm.icon] = true),
            _obj)
        })
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "theme-panel-content" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import _ from 'lodash';
// search component config
const scc = {};
scc['atc'] = {
    url : `/ajax/functions_ajax_class.php`,
    action : "searchForms",
    origin : "/atc/search",
    name : "Attendance & Consent Forms"
}
scc["bill"] = {
    url : `/ajax/functions_ajax_invoices.php`,
    action : "search_invoice_transaction",
    origin : "/bill/search"
};
scc["user"] = {
    url : `/ajax/functions_ajax_users.php`,
    action : "GetCompanyUsers",
    origin : "/users/search"
};
scc["emails"] = {
    url : `/ajax/functions_ajax_tracking.php`,
    action : "searchTracking",
    origin : "/timesheets/search_tracking.php"
};
scc["timesheet"] = {
    url : `/ajax/functions_ajax_timesheets.php`,
    action : "searchTimesheets",
    origin : "/timesheets/search"
};
scc["timeoff-requests"] = {
    url : `/ajax/functions_ajax_timeoff.php`,
    action : "searchRequests",
    origin : "/time-off/search"
};
scc["ticket"] = {
    url : `/ajax/functions_ajax_tickets.php`,
    action : "searchTickets",
    origin : "/tickets/search"
};
scc["student"] = {
	url : `/ajax/functions_ajax_students.php`,
    action : "searchReports",
	origin : "/student/search"
};
scc["shared-files"] = {
    url : `/ajax/functions_ajax_files.php`,
    action : "searchFiles",
    origin : "/shared-files/search"
};
scc["request"] = {
    url : `/ajax/functions_ajax_requests.php`,
    action : "searchRequests",
    origin : "/requests/search"
};
scc["receipts"] = {
    url : `/ajax/functions_ajax_receipts.php`,
    action : "search_receipts",
    origin : "/receipts/search"
};
scc["public-profile"] = {
    url : `/ajax/functions_ajax_public_profiles.php`,
    action : "GetPublicProfiles",
    origin : "/public-profile/search"
};
scc["paypal"] = {
	url : `/ajax/functions_ajax_paypal_reports.php`,
	action : "search_paypal",
	origin : "/reports/paypal_transactions"
};
scc["paypal-receipts"] = {
    url : `/ajax/functions_ajax_receipts.php`,
    action : "search_paypal_receipts",
    origin : "/paypal-receipts/search"
};
scc["payment"] = {
    url : `/ajax/functions_ajax_payment_search.php`,
    action : "searchPayments",
    origin : "/reports/payments"
};
scc["ossa-training"] = {
    url : `/ajax/functions_ajax_ossa.php`,
    action : "searchOSSALocations",
    origin : "/ossa-training/search"
};
scc["ossa-numbers"] = {
    url : `/ajax/functions_ajax_ossa.php`,
    action : "searchOSSANumbers",
    origin : "/ossa-numbers/search"
};
scc["onsite-class"] = {
    url : `/ajax/functions_ajax_onsite_search.php`,
    action : "search_onsite_classes",
    origin : "/reports/onsite_class_report"
};

scc["noshow"] = {
    url : `/ajax/functions_ajax_no_show.php`,
    action : "search_no_show",
    origin : "/noshow/search"
};

scc["namechange"] = {
	origin : "/users/requests.php",
	url : `/ajax/functions_ajax_profile.php`,
    action : "get_user_requests"
};
scc["invoice"] = {
	url : `/ajax/functions_ajax_invoices.php`,
	action : "search_invoices", 
	origin : "/invoices/search"
};
scc["revenue2"] = {
	origin : "in-house-revenue/search",
	url : `/ajax/functions_ajax_revenue_report.php`,
	action : "search_revenue",
	reduce : (rows)=>_.reduce(rows,(sum,row)=>sum += _.toNumber(row.revenue),0),
	prepend : "$"
};
scc["infraction"] = {
    url : `/ajax/functions_ajax_instructors.php`,
    action : "search_infractions",
    origin : "/infractions/search"
};
scc["feedback"] = {
    url : `/ajax/functions_ajax_class.php`,
    action : "searchFeedback",
    origin : "/feedback/search"
};
scc["dispute"] = {
    url : `/ajax/functions_ajax_proctor.php`,
    action : "searchDisputes",
    origin : "/dispute/search"
};
scc["class"] = {
    url : `/ajax/functions_ajax_class.php`,
    action : "searchClasses",
    origin : "/class/search"
};
scc["class-sales"] = {
    url : `/ajax/functions_ajax_class_sales.php`,
    action : "searchClassSales",
    origin : "/class-sales/search"
};
scc["class-file"] = {
    url : `/ajax/functions_ajax_class_file.php`,
    action : "searchClassFiles",
    origin : "/class-file/search"
};
scc["checkin"] = {
    url : `/ajax/functions_ajax_checkins.php`,
    action : "search_checkins",
    origin : "tracking/search"
};
scc["cards"] = {
    url : `/ajax/functions_ajax_cards.php`,
    action : "searchCards",
    origin : "/cards/search"
};
scc["audit"] = {
    url : `/ajax/functions_ajax_audit_report.php`,
    action : "search_audits",
    origin : "/audit/search"
};
scc["proctor"] = {
    url : `/ajax/functions_ajax_proctor.php`,
    action : "searchSessions",
    origin : "/proctor/proctoring_report"
};
export default scc;
import Vue from 'vue'
import _ from 'lodash'
import { customPost } from '../scripts/custom-all'
function poCache(state) {
    let cache = {};
    _.each(state.fullCart.items, (e, i) => {
        cache[e.cart_id_sh] = e.po || '';
    });
    return cache;
}

export default {
    namespaced: true,
    state: {
        //
        cart: {
            subTotal: '0.00', 
            items: [], 
            quantity: 0
        }, 
        fullCart: {
            items: [],
        }   
    }, 
    mutations: {
        //
        updatePO(state, {idx, po}) {
            state.fullCart.items[idx].po = po;
        }, 
        setFullCart(state, cart) {
            state.fullCart = cart;
        }, 
        setCart(state, cart) {
            state.cart = cart
        },
        resetCart(state) { 
            state.cart = {
                subTotal: '0.00',
                quantity: 0,
                items: []
            }
            state.fullCart = {
                items: []
            }
        },
        usePoCache(state, cache) {
            if (_.size(cache) == 0) { 
                return; 
            }

            _.each(state.fullCart.items, (e, i) => {
                if (cache[e.cart_id_sh]) {
                    state.fullCart.items[i].po = cache[e.cart_id_sh];
                }
            });
        }
    }, 
    actions: {
        async refreshFullCart({commit, state}) {
            let response = await customPost(`/ajax/functions_ajax_cart.php`,{
                action : "RefreshCart",
            });
            if (response.status) {
                let cache = poCache(state);
                commit('setFullCart', response.cart);
                commit('setCart', response.cart);
                commit('usePoCache', cache);
            }
            return response;
        }, 
        async updateQty({dispatch, commit, state}, item) {
            let response = await customPost(`/ajax/functions_ajax_cart.php`,{
                action : "UpdateCart2",
                id: item.cart_id,
                qty: item.quantity,
                course : item.course_id,
            });

            if (response.status) {
                let cache = poCache(state);
                commit('setFullCart', response.cart);
                commit('setCart', response.cart);
                commit('usePoCache', cache);
            }

            return response;
        }, 
        async checkInvoiceItem({commit, state}, id) {
            let response = await customPost(`/ajax/functions_ajax_cart.php`,{
                action : "CheckInvoiceCB2",
                id,
            });
            if (response.status) {
                let cache = poCache(state);
                commit('setFullCart', response.cart);
                commit('usePoCache', cache);
            }

            return response;
        }, 
        async processInvoice() {
            let response = await customPost(`/ajax/functions_ajax_cart.php`,{
                action : "ProcessInvoices2",
            });
            return response;
        }, 
        async sendInvoice({commit}, list) {
            let response = await customPost(`/ajax/functions_ajax_cart.php`,{
                action : "SendInvoice",
                data : list
            });

            if (response.success) {
                commit('setFullCart', response.cart);
                commit('setCart', response.cart);
            }

            return response;
        }, 
        async removeFromInvoice({dispatch, commit, state}, item) {
            let response =  await customPost(`/ajax/functions_ajax_cart.php`,{
                action : "RemoveFromInvoice2",
                cr: item.cart_id,
                cid : item.course_id,
            });
            if (response.status) {
                if (response.cart) {
                    let cache = poCache(state);
                    commit('setFullCart', response.cart);
                    commit('setCart', response.cart);
                    commit('usePoCache', cache);
                }
            }
            return response;
        }, 
        async applyCouponCode({dispatch, state, commit}, code) {
            let response = await customPost(`/ajax/functions_ajax_cart.php`,{
                action: "apply_coupon_code",
                code: code
            });
            if (response.status) {
                dispatch('refreshFullCart')
            }
            return response;
        }, 
        async removeCoupon({dispatch}, id) {
            let response = await customPost(`/ajax/functions_ajax_cart.php`,{
                action : "remove_coupon",
                id: id
            })

            dispatch('refreshFullCart');
            
            return response;
        }, 
        proceedToCheckout(ctx, misc) {
            return customPost(`/ajax/functions_ajax_cart.php`,{
                action : "ProceedToCheckOut",
                misc : misc
            });
        }, 
        async addToCart({dispatch}, id, event_id = '') {
            var page = location.href.split("/").slice(-1)[0].split('.')[0];
            try {
                let output = await Vue.prototype.$ajax(`/ajax/functions_ajax_cart.php`,"POST", {
                    action: 'AddToCart',
                    course_id : id,
                    event_id : event_id ? event_id : '',
                    page: page,
                });

                if (output.success) {
                    dispatch('refreshFullCart');
                }
                return output;
            } catch {
                return 0;
            }
        }, 
        async addMultipleToCart({dispatch}, {course_id, event_id, qty, just_for_me}) {
            //
            let response = await customPost(`/ajax/functions_ajax_cart.php`, {
                action : "AddMultipleToCart",
                course_id, 
				event_id, 
				qty, 
				just_for_me
            });

            if (response.status) {
                await dispatch('refreshFullCart');
            }
            
            return response;
        }, 
        async removeStationFromCart({dispatch}, id) {
            let response = await  customPost(
                `/ajax/functions_ajax_stations.php`,
                {
                    action: "removeFromCart",
                    id
                }
            )
            await dispatch('refreshFullCart')

            return response;
        }, 
        async removeFromCart({commit, state}, id) {
            let response = await customPost(`/ajax/functions_ajax_cart.php`,{
                action : "RemoveFromCart",
                id: id,
            });
            if (response.status) {
                let cache = poCache(state);
                commit('setFullCart', response.cart);
                commit('setCart', response.cart);
                commit('usePoCache', cache);
            }
        }, 
		async refreshCart({dispatch}) {
            return await dispatch('refreshFullCart')
        }, 
        updateCart({commit}, cart) {
            if (cart != undefined)
				commit('setCart', cart)
			else {
                commit('resetCart');
			}
        }, 
        async emptyCart({commit}) {
            let output = await Vue.prototype.$ajax(`/ajax/functions_ajax_cart.php`, "POST", {
                action: 'EmptyCart',
            })


            if (output.status == "1") {
                commit('resetCart');
            }
        }
    }
}
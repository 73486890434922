<template>
<v-page title="My Dashboard" :subtitle="viewerLevel > 4 ? `Administration` : `Learner Dashboard`">
    <div>
        <LearnerDashboard skip-header v-if="viewerLevel > 4 && isSecondary" />
        <component :is="viewerLevel > 4 ? 'admin-dashboard' : 'learner-dashboard'"/>
    </div>
</v-page>
</template> 
<script>
import WidgetBox from "../components/common/WidgetBox.vue";
import VTable from "../components/common/VTable.vue";
import VFormModal from "../components/form/VFormModal.vue";
import VInput from "../components/form/VInput.vue";
import LearnerDashboard from './Dashboard/LearnerDashboard'
import AdminDashboard from './Dashboard/AdminDashboard';
import { mapState } from "vuex";
export default {
    name: 'dashboard',
    components: {
       LearnerDashboard,
       AdminDashboard
    }, 
    computed: {
        ...mapState('auth', ['viewerLevel', 'isSecondary']),
    }, 
}
</script>
<template>
    <div>
        <v-form @submit="onSubmit">
            <template slot-scope="{edit}">
                <v-input type="email" v-model="edit.email" :default="email" class="m-b-20" placeholder="Email Address" required large :readonly="readonly"/>
                <v-input type="password" v-model="edit.password" class="m-b-20" placeholder="Password" required large/>
                <v-input type="checkbox" class="m-b-20" v-model="edit.remember_me" inline>Remember Me</v-input>
                <v-input type="checkbox" class="m-b-20" v-model="edit.secondary" inline v-if="features.secondary">Secondary Company</v-input>

                <div v-show="remain" class="note note-danger" :class="errorClass">
                    <div class="note-content">
                        <h4><b>Incorrect username or passsword</b></h4>
                        <p>10 invalid attempts within 30 minutes will lock you out for 2 hours.<br><b>{{remain}} attempts remaining.</b></p>
                    </div>
                </div>


                <div class="login-buttons">
                    <button type="submit" class="btn btn-success btn-block btn-lg">Sign In</button>
                </div>

                <div v-if="ajaxLogin" class="m-t-10">
                    Change to another account? Click <a href="/logout.php">here</a> to login.
                </div>          
                <div class="m-t-10" v-if="features.register">
                    Not a member yet? Click <a href="javascript:;" @click.stop="$emit('update:state', 'register')">here</a> to register.
                </div>
                <div class="m-t-10">
                    Forgot Password? Click <a href="javascript:;" @click="$emit('update:state', 'recover')">here</a> to reset.
                </div>
            </template>
        </v-form>
    </div>
</template>

<script>
import _ from 'lodash';
import loginMixin from './loginMixin'
import { customPost } from '../../scripts/custom-all'
import { mapState, mapActions } from 'vuex'
export default {
    mixins: [loginMixin],
    name : "login-form",
    props: {
        email:  String,
        ajaxLogin: Boolean,
        lockCompany: Boolean,
        companyList: Array,
        state: String
    },
    data(){
        return {
            remain: null,
            errorShake : false
        };
    },
    computed : {
        ...mapState('auth', ['features']), 
        errorClass(){
            return {
                "animated shakeX" : this.errorShake
            }
        },
        readonly(){
            return _.size(this.email) > 0 && this.ajaxLogin
        }
    },
    methods : {
        ...mapActions('auth', ['register']),
        async onSubmit(edit){
            this.errorShake = false
            await this.$nextTick()
            edit.action = "login"
            
            if (this.ajaxLogin){
                edit.ajax = true;
            } else if (this.lockCompany){
                edit.comlock = true;
            }
    
            let server = await this.$store.dispatch('auth/login', edit)
            this.$emit('update:email', edit.email);
            let response = server.response;
            switch (response.result){
                case "member":
                    this.$emit("login");
                    break;
                case "fail":
                    this.errorShake = true;
                    this.remain = response.remain;
                    break;
                default:
                    this.$emit('update:state',response.result);
            }
        }
    },
};
</script>
<style>
    .v-chat .widget-box-body{
        background-color : #eceff1;
        padding : 0px 10px;
        height : 100%;
    }

    .v-chat .message {
        width : 80%;
    }

    .v-chat .message.owned {
        align-self: flex-end;
    }
    .v-chat .message-content { 
        max-width : 70%;
    }

    .v-chat .message.not-owned .message-content {
        margin-left : 1.5rem;    
    }

    .v-chat .message.owned .message-container {
        flex-direction: row-reverse;
    }

    .v-chat .message-container{
        margin-top : 1rem;
    }

    .v-chat .message-box {
        color : #000;
        background-color: #fff;
        background-clip: border-box;
        border: 1px solid rgba(0,0,0,.125);
        border-radius: .25rem;
        padding : .5rem;
        word-wrap : break-word; 
    }

    .v-chat .message.owned .message-box {
        background-color : #91ED61!important;
    }

    .v-chat .message.owned .message-tag{
        text-align: right;
    }


</style>
<script>
import _ from 'lodash';
import $ from 'jquery';
import { WidgetBox  } from '.';
import { VInput, VForm } from "../form";
import WidgetModal from './WidgetModal'
export default {
    components: {WidgetBox, WidgetModal, VForm, VInput}, 
        props : {
            // id (encrypted {id,type}), url, action
            ajax : {
                type : Object,
                required : true
            },
            disabled : Boolean,
            title : String,
            categories : Object,
            tag : {
                type : String,
                default : "widget-box"
            }
        },
        data() {
            return {
                messages : [],
                loading : true
            }
        },
        beforeDestroy(){
            this.$poll.remove("vChatPoll");
        },  
        watch  : {
            ajax : {
                deep : true,
                immediate: true,
                handler(...props){
                    this.onAjaxChange(...props)
                }
            }
        },
        methods : {
            onAjaxChange(ajax){
                if (ajax && ajax.id){
                    if (!this.registered){
                        this.$poll.register({
                            name  : "vChatPoll",
                            get : () => {
                                if (this.ajax.id){
                                    return {
                                        id : this.ajax.id,
                                        last : _.size(this.messages) ? this.messages[0].eid : -1
                                    };
                                }
                            },
                            set : (messages)=> {
                                _.each(messages, (nm)=>{
                                    if (_.some(this.messages,m=>m.id == nm.id)){
                                        return true;
                                    }
                                    this.messages.unshift(nm);
                                })
                            },
                            init : (message)=> {
                                this.loading = false
                            }
                        })
                        this.registered = true
                    } else {
                        this.loading = true
                        this.$poll.reset("vChatPoll")
                    }
                } else if (this.registered){
                    this.$poll.remove("vChatPoll")
                    this.loading = true
                    this.registeredw = false
                }
            },
            async addMessage(edit,reset,tab){
                if(edit.message){
                    let data = {
                        action : this.ajax.action,
                        id : this.ajax.id,
                        message : edit.message
                    };
                    if (tab && tab != 'All'){
                        data["category"] = this.categories[tab];
                    }
                    await this.$ajax(this.ajax.url,"post",data);
                    reset();
                    await this.$poll.update("vChatPoll");
                    $(this.$el).find(".v-chat-messages").animate({
                        scrollTop: 0
                    }, 100);
                }
            },
            tabMessages(tab){
                if (tab && tab != 'All'){
                    return _.filter(this.messages,message=>message.category == this.categories[tab]);
                }
                return this.messages;
            },
            open(ajax){
                _.assign(this.ajax,ajax)
                this.onAjaxChange(this.ajax)
                this.$refs.wm.open()
            },
            clear(){
                this.messages = [];
            }
        },
        computed : {
            dropdown(){
                if (this.categories && _.size(this.categories)){
                    let dropdown = Object.keys(this.categories);
                    dropdown.unshift("All");
                    return dropdown;
                }
                return null;
            }
        },
    }
</script>

<template>
    <component
        :is="tag"
        :title="title"
        class="v-chat" 
        :loading="loading"  
        :dropdown="dropdown"
        ref="wm"
        @closed="clear"
    >
        <template #default="{tab}">
            <div class="d-flex flex-column height-full">
                <transition-group 
                    tag="div"
                    class="flex-grow-1 overflow-auto d-flex flex-column v-chat-messages"
                    enter-active-class="animated fadeInDown"
                >      
                    <div class="message" :class="message.avatar ? 'not-owned' : 'owned'" v-for="message in tabMessages(tab)" :key="message.id">
                        <div class="d-flex width-full message-container">
                            <div
                                v-if = "message.avatar"
                                class="img rounded-corner width-30 height-30"
                                :style = "{'background-image' : `url('${message.avatar}')`}" 
                            ></div>
                            <div class="d-flex flex-column message-content">
                                <div class="message-box">
                                    <div class="f-s-14 f-w-600">{{message.content}}</div>
                                    <div class="message-tag f-s-9 text-grey-darker">{{message.name}} · {{$timeFormat(message.t_stamp)}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition-group>
                <div class="message-input m-t-5" v-if="!disabled">
                    <v-form @submit="(edit,reset)=>addMessage(edit,reset,tab)">
                        <template slot-scope="{ edit }">
                            <v-input 
                                placeholder = "Press ENTER to leave a message ..."
                                v-model.trim="edit.message"
                                class="ml-3 mr-3"
                            ></v-input>
                        </template>
                    </v-form>
                </div>
            </div>
        </template>
    </component>
</template>
<script>
const sidebarMenu = [
  { path: '/dashboard', icon: 'fas fa-tachometer-alt', title: 'Dashboard' , exact: true, },
  { path: '/courses/course_catalog', icon: 'fas fa-list', title: 'Course Catalog', level: 1, feature: 'course_catalog'},
  { path: '/courses/calendar', icon: 'fas fa-calendar-alt', title: "Calendar", level: 1, feature: 'schedule_learner'},
  { path: '/courses/mycourses', icon: 'fas fa-book', title: 'My Courses', level: 1, feature: 'schedule_learner'},
  { path: '/proctor/proctoring_disputes', icon: 'fa fa-gavel', title: "Proctoring Disputes", level: 1, feature: 'schedule_learner'},
  { path: '/Certificates', icon: 'fas fa-certificate', title: 'My Certificates', level: 1, feature: 'my_cetification'},
  { path: '/competencies', icon: 'fas fa-lightbulb', title: "My Competencies", level: 1, feature: 'my_competencies',
    children: [
      {path: '/competencies/critical', title: 'Critical Tasks'},
      {path: '/competencies/procedures', title: 'Associated Standards and Procedures'}
    ],
  },
  { path: '/tickets', icon: 'fas fa-question', title: 'My Support Tickets', level: 1, feature: 'my_tickets'},
  { path: '/memos', icon: 'fa fa-comments', title: 'Memos', level: 6, feature: 'memos', primary: true,
    children: [
      {path: '/memos/groups', title: 'Groups' },
      {path: '/memos/memos', title: 'Memos' },
    ]
  },
  { path: '/instructor', icon: 'fas fa-clipboard-list', title: 'Instructor Resources', instructor: true,
    children: [
      {path: '/instructor/schedule', title: 'My Schedule' },
      {path: '/instructor/calendars', title: 'My Calendars' },
      {path: '/instructors/instructor_classlists.php', title: 'Instrucotr Class Lists' },
      {path: '/instructor/profile', title: 'instructor Profile' },
      {path: '/instructor/resources', title: 'Course Resources' },
    ]
  },
  { path: '/cards/search', icon: 'far fa-id-card', title: 'Credential Cards', level: 4, feature: 'credential_cards'},
  { path: '/receipts', icon: 'fas fa-receipt', title: 'Receipts', level: 5, primary: true, 
    children: [
      {path: '/receipts/view', title: 'Create Receipt' },
      {path: '/receipts/search', title: 'Receipt Search' },
      {path: '/paypal-receipts/search', title: 'Paypal Search' },
    ]
  },
  { path: '/student', icon: 'fas  fa-file-user', title: 'Students', level: 4, feature: 'students',
    children: [
      {path: '/student/search', title: 'Student Search' },
      {path: '/scheduling/bookings.php', title: 'Assign Students' },
      {path: '/noshow/search', title: "No Show Search"},
      {path: '/feedback/search', title: "Feedback Search"}
    ]
  },
  { path: '/classes', icon: 'fas fa-users-class', title: 'Classes', level: 4, feature: 'classes', primary: true,
    children: [
      {path: '/class/search', title: 'Class Search' },
      {path: '/class-files/search', title: 'Files Search' },
      {path: '/class-sales/search', title: 'Sales Search' },
      {path: '/ossa-numbers/search', title: 'OSSA Training Numbers' },
      {path: '/ossa-training/search', title: 'OSSA Training Locations' },
    ]
  },
  { path: '/scheduling', icon: 'fas fa-calendar-edit', title: 'Scheduling System',  level: 4, feature: 'scheduling_system', primary: true,
    children: [
      {path: '/scheduling/scheduling', title: 'Scheduling'},
      {path: '/requests/search', title: 'Requests Search'},
      {path: '/scheduling/calendars', title: 'Calendars'},
      {path: '/scheduling/sites', title: 'Sites / Training Locations'},
      {path: '/scheduling/customers', title: 'Customers / Vendors'},
      {path: '/scheduling/shifts', title: 'Shifts'},
      {path: '/scheduling/shortcuts', title: 'Shortcuts'},
    ]
  },
  { path: '/instructors', icon: 'fas fa-chalkboard-teacher', title: 'Instructor Management', level: 5, feature: 'instructor_management', primary: true,
    children: [
      {path: '/instructors/instructors', title: 'Instructors' },
      {path: '/audit/search', title: 'Audit Reports' },
      {path: '/infractions/search', title: 'Infractions' },
      {path: '/instructors/instructor_classlists.php', title: 'Instrucotr Class Lists' },
    ]
  },
  {
    path: '/timesheets/', icon: 'fas fa-chalkboard-teacher', title: 'Timesheets',  level: 3, instructor: true, primary: true, feature: 'timesheet', 
    children: [
      {path: '/timesheets/search', title: 'Time Sheet Search'},
      {path: '/timesheets/accounting', title: 'Accounting'},
      {path: '/timesheets/settings', title: 'Time Sheet Settings'},
      {path: '/timesheets/dashboard', title: "Approve Time Sheets"},
      {path: '/checkin/search', title: "Check In/Out Search"},
      {path: '/timesheets/mine', title: "My Time Sheets"}
    ]
  },
  { path: '/users', icon: 'fas fa-users', title: 'Users', feature: 'users', level: 4, 
    children: [
      {path: '/users/search', title: 'Search / Add Users' },
      {path: '/users/name_change', title: 'Name Change Requests', feature: 'change_name' },
    ]
  },
  {
    path: '/shared-files/search', icon: 'fas fa-file-alt', title: 'Search Shared Files', level: 4, feature: 'students', 
  },
  {
    path: '/public-profiles/search', icon: 'fas fa-user-alt', title: "Public Profiles", level: 3, feature: 'public_profiles'
  },
  {
    path: '/proctoring', icon: 'fas fa-address-card', title: "Proctoring Manager", level: 3, prosper: true, primary: true, feature: 'proctoring_manager',
    children: [
      {path: '/proctoring/sessions', title: "Proctoring Sessions"},
      {path: '/proctoring/search', title: "Search Proctoring Disputes"}
    ]
  },
  {
    path: '/accounting', icon: 'fa fa-dollar-sign', title: 'Accounting', feature: 'accounting', primary: true, level: 8,
    children: [
      { path: '/reports/onsite_class_report', title: 'On-Site Invoicing'},
      { path: '/reports/paypal_transactions', title: "Paypal Transactions"},
      { path: '/reports/inhouse_revenue', title: 'In-House Revenue'},
      { path: '/invoice/search', title: 'Search Invoices' },
      { path: '/bill/search', title: 'Billing Transactions'},
      { path: '/invoices/agreements', title: 'Invoice Agreements'},
      { path: '/accounting/settings', title: 'Accounting Settings'}
    ]
  },
  {
    path: '/company', icon: 'fa fa-cog', title: 'My Company', feature: 'my_company', level: 7, primary: true, 
    children: [
      {path: '/company/settings', title: 'Company Settings'},
      {path: '/company/employees', title: "Employees"}
    ]
  },
  {
    path: '/support', icon: 'fa fa-cog', title: "ABCS IT Support", level: 10, 
    children: [
      {path: '/tickets/search', title: 'Support Tickets'},
      {path: '/company/features', title: 'Company Features'},
      {path: '/users/merge', title: 'Merge Users'},
      {path: '/tools', title: 'Developing Tools', target: '_blank'}
    ]
  }
]

export default sidebarMenu;
</script>

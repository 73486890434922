<template>
    <span class="row">
        <slot name="theName" :label="label"><label  class="col-md-3">{{ label }}</label></slot>
        <span class="col" style="margin-bottom:0.7rem">
            <slot name="theValue" :value="value">{{ value }}</slot>
        </span>
    </span>
</template>
<script>
export default {
    name: 'v-item', 
    props: [ 'label', 'value' ],
};
</script>
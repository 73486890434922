<template>
    <div id="site-picker" ref="modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{course_name}} is offered at the following locations:</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <ul>
                        <li v-for="site in sites" :key="site.address" class="mb-3">
                            <div class ="row">
                                <div class="col">
                                    <h5 class="mb-0 mb-0">{{site.site_name}}</h5>
                                    <span><a :href="getMapURL(site)" target="_blank">{{site.address}}, {{site.city}}, {{site.postal_code}}</a></span>
                                    <br><span v-if="site.next_course"><b>Next Course:</b> {{site.next_course}}</span>
                                </div>
                                <div class="col-3 d-flex align-items-center justify-content-center">
                                    <button @click="openSchedule(site)" class="btn btn-lime">View Schedule<i class="ml-2 fas fa-calendar-alt"></i></button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div v-if="action" class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button @click="action"  data-dismiss="modal" type="button" class="btn btn-primary">Add To Cart<i class="ml-2 fas fa-shopping-cart"></i></button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import $ from 'jquery'
import _ from 'lodash';
import Swal from 'sweetalert2';
import { customPost } from '../../scripts/custom-all'
import courseButtonActionHelpers from './cabh';
import getMapURL from './getMapURL';
export default {
    name: 'site-picker',
    created(){
        courseButtonActionHelpers[this.$options.name] = this;
    },
    data(){
        return {
            window : window,
            sites : [],
			course_name: '',
            action : null
        };
    },
    beforeDestroy() {
        $(this.$refs.modal).modal('hide');
    }, 
    methods : {
        open(id,action) {
            this.action = null;
            customPost(`${ process.env.VUE_APP_AJAX_PATH}/ajax/functions_ajax_courses.php`,{
                action : "getSites",
                id : id
            }).then((output)=>{
                this.sites = output.sites;
				this.course_name = output.course_name;
                this.action = action;
                if (_.size(this.sites)){
                    $(this.$refs.modal).modal('show');
                } else {
                    Swal.fire({
                        title: "Course not available",
                        text: "Please contact us to book an on-site class.",
                        type: "warning"
                    });
                }
            });
        },

        getMapURL,

        openSchedule(site){
            // let target = `/scheduling/calendar/?id=${encodeURIComponent(site.id)}`;
            this.$router.push({name: 'calendar', params: {id: site.id}})
            // if (this.action){
            //     // window.open(target);
            // } else {
            //     $(this.$refs.modal).modal('hide');
            //     this.$router.push(target)
            //     // window.location.href = target;
            // }
        }
    }
};
</script>
<script>
import VNode from './VNode';
export default {
    name: "v-tree",
    components: {VNode}, 
    props : {
        root : [String, Number],
        getName : {
            type : Function,
            required : true
        },
        getChildren: {
            type : Function,
            required : true
        }
    },
    provide(){
        return {
            getName : this.getName,
            getChildren : this.getChildren,
            emit : (...props)=>{
                this.$emit(...props);
            }
        }
    },
    template : '<v-node :id="root" class="v-tree" :draggable="false"></v-node>'
};
</script>

<template>
    <div class="checkbox checkbox-css" :class = "{'checkbox-inline' : inline }">
        <input  type="checkbox" :id="id" v-model="checked" :disabled="disabled"/>
        <label :for="id" class="noselect" :class="{'cursor-pointer': !disabled}">{{label}}<slot></slot></label>
	</div>
</template>

<script>
    import _ from 'lodash';
    export default {
        name: 'check-box',
        data() {
            return {
                id :  _.uniqueId('checkbox_')
            }
        },
        computed : {
            checked : {
                get(){
                    return (this.value == this.option[0] ? true : false);
                },
                set(val){
                    this.$emit('input', val ? this.option[0] : this.option[1]);
                }
            }
        },
        props : {
            label : {
                type : String
            },
            inline : {
                type : Boolean,
                default : false
            },
            disabled : {
                type : Boolean,
                default : false
            },
            value : {
                required : true
            },
            option : {
                type : Array,
                default : ()=>[1,0]
            }
        },
    };
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.menu.children
    ? _c(
        "li",
        {
          staticClass: "has-sub",
          class: { active: _vm.subIsActive(_vm.menu.children) }
        },
        [
          _c("a", { attrs: { href: "javascript:;" } }, [
            _vm.menu.badge
              ? _c("span", { staticClass: "badge pull-right" }, [
                  _vm._v(_vm._s(_vm.menu.badge))
                ])
              : _c("b", { staticClass: "caret" }),
            _vm._v(" "),
            _vm.menu.icon
              ? _c("i", { staticClass: "fas", class: _vm.menu.icon })
              : _vm._e(),
            _vm._v(" "),
            _vm.menu.img
              ? _c("div", { staticClass: "icon-img" }, [
                  _c("img", { attrs: { src: _vm.menu.img, alt: "" } })
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("span", [
              _vm._v("\n\t\t\t" + _vm._s(_vm.menu.title) + "\n\t\t\t"),
              _vm.menu.label
                ? _c("span", { staticClass: "label label-theme" }, [
                    _vm._v(_vm._s(_vm.menu.label))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.menu.highlight
                ? _c("i", { staticClass: "fa fa-paper-plane text-theme" })
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c(
            "ul",
            {
              staticClass: "sub-menu",
              style: {
                marginTop: _vm.pageOptions.pageSidebarMinified
                  ? -(_vm.scrollTop + 40) + "px"
                  : ""
              }
            },
            [
              _vm._l(_vm.menu.children, function(submenu) {
                return [
                  _c("sidebar-nav-list", {
                    key: submenu.path,
                    ref: "sidebarNavList",
                    refInFor: true,
                    attrs: { menu: submenu }
                  })
                ]
              })
            ],
            2
          )
        ]
      )
    : _c(
        "li",
        [
          _c(
            "router-link",
            {
              class: { "d-none": this.stat == "hide" },
              attrs: {
                to: _vm.menu.path,
                target: _vm.menu.target || "",
                exact: _vm.menu.exact || false,
                "active-class": "active"
              }
            },
            [
              _vm.menu.badge
                ? _c("span", { staticClass: "badge pull-right" }, [
                    _vm._v(_vm._s(_vm.menu.badge))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.menu.icon
                ? _c("i", { staticClass: "fas", class: _vm.menu.icon })
                : _vm._e(),
              _vm._v(" "),
              _vm.menu.img
                ? _c("div", { staticClass: "icon-img" }, [
                    _c("img", { attrs: { src: _vm.menu.img, alt: "" } })
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("span", [
                _vm._v("\n\t\t\t" + _vm._s(_vm.menu.title) + "\n\t\t\t"),
                _vm.menu.highlight
                  ? _c("i", { staticClass: "fa fa-paper-plane text-theme" })
                  : _vm._e(),
                _vm._v(" "),
                _vm.menu.label
                  ? _c("span", { staticClass: "label label-theme" }, [
                      _vm._v(_vm._s(_vm.menu.label))
                    ])
                  : _vm._e()
              ])
            ]
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "modal",
      staticClass: "modal fade",
      attrs: { tabindex: "-1", role: "dialog", "aria-hidden": "true" }
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body f-s-14" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-3" },
                [_c("Strong", [_vm._v("Site: ")])],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.event.site_name) +
                    "\n                    "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mt-3" }, [
              _c(
                "div",
                { staticClass: "col-3" },
                [_c("Strong", [_vm._v("Time: ")])],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.event.start_datetime) +
                    " To " +
                    _vm._s(_vm.event.end_datetime) +
                    "\n                    "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mt-3" }, [
              _c(
                "div",
                { staticClass: "col-3" },
                [_c("Strong", [_vm._v("Address: ")])],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c("span", [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.getMapURL(_vm.event),
                        target: "_blank"
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.event.address) +
                          ", " +
                          _vm._s(_vm.event.city) +
                          ", " +
                          _vm._s(_vm.event.postal_code)
                      )
                    ]
                  )
                ])
              ])
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [_vm._v("Event Details")]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
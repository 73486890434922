<script>
import _ from 'lodash'
import $ from 'jquery'
import {WidgetBox} from './';
export default {
    name: 'widget-modal',
    components: {WidgetBox},
    props : {
        closeable : {
            type : Boolean,
            default : true
        }
    },
    data : ()=>({edit : {}}),
    mounted(){
        $(this.$el).modal("hide");
        $(this.$el).on("hidden.bs.modal",(e)=>{
            if (e.target == e.currentTarget){
                this.$emit("closed");
            }
        });
        $(this.$el).on("shown.bs.modal",(e)=>{
            if (e.target == e.currentTarget){
                this.$emit("opened");
            }
        });
    },
    beforeDestroy(){
        /* where is this used */
        this.close();
        // $(this.$el).off();

    },
    methods :{
        open(edit){
            if (edit){
                this.edit = edit;
            } else {
                this.edit = {};
            }
            $(this.$el).modal("show");
        },
        close(){
            $(this.$el).modal("hide");
        },
    },
    render(createElement){
        let slots = this.$scopedSlots;
        let originDefault = slots.default;
        slots.default = (prop)=>{
            if (originDefault){
                prop.edit = this.edit;
                return originDefault(prop);
            }
        }
        return createElement("div",
        {
            ref : "modal",
            "class" : "widget-modal modal fade",
            attrs : {
                tabindex : "-1",
                role : "dialog",
                "aria-hidden" : "true",
                "data-backdrop" : "static",
                "data-keyboard" : "false",
                "data-focus" : "false"
            }
        },[
            createElement("div",
            {
                "class" : "modal-dialog modal-dialog-centered modal-lg",
                attrs : {
                    role : "document"
                }
            },
            [
                createElement("div",
                {
                    "class" : "modal-content"
                },
                [
                    createElement("widget-box",{
                        attrs : this.$attrs,
                        props : {closeable : this.closeable},
                        on : _.merge({},this.$listeners,{close : this.close}),
                        scopedSlots : slots
                    })
                ])
            ])
        ]);
    }
};
</script>
<style>
.widget-modal .panel{
    margin-bottom : 0px;
}
</style>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "content",
      class: {
        "content-full-width": _vm.pageOptions.pageContentFullWidth,
        "content-inverse-mode": _vm.pageOptions.pageContentInverseMode
      },
      attrs: { id: "content" }
    },
    [
      _c(
        "ol",
        { staticClass: "breadcrumb pull-right" },
        _vm._l(_vm.$vstore.breadcrumbs, function(item, i) {
          return _c(
            "li",
            { key: i, staticClass: "breadcrumb-item" },
            [
              typeof item == "object"
                ? _c("router-link", { attrs: { to: item.to } }, [
                    _vm._v(_vm._s(item.name))
                  ])
                : _c("span", [_vm._v(_vm._s(item))])
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "h1",
        { staticClass: "page-header" },
        [
          _vm._t("title", [_vm._v(_vm._s(_vm.actualTitle))]),
          _vm._v(" "),
          _c(
            "transition-group",
            {
              staticClass: "d-inline-flex flex-wrap",
              attrs: { "enter-active-class": "animated fadeInRight" }
            },
            [
              _vm._t("subtitle", [
                _c(
                  "small",
                  {
                    key: _vm.$vstore.subtitle || 0,
                    staticStyle: { "padding-left": "5px" }
                  },
                  [_vm._v(_vm._s(_vm.$vstore.subtitle || _vm.subtitle))]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  { key: "PLACEHOLDER", staticStyle: { height: "24px" } },
                  [_vm._v(" ")]
                )
              ])
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { "enter-active-class": "animated fadeIn" } },
            [
              _vm.$vstore.titleButton.html
                ? _c("button", {
                    staticClass: "float-right btn btn-sm mr-2",
                    class:
                      "btn-" +
                      _vm.$vstore.titleButton.type +
                      " " +
                      _vm.$vstore.titleButton.className,
                    domProps: {
                      innerHTML: _vm._s(_vm.$vstore.titleButton.html)
                    },
                    on: { click: _vm.$vstore.titleButton.callback }
                  })
                : _vm._e()
            ]
          )
        ],
        2
      ),
      _vm._v(" "),
      !_vm.noAuth && !_vm.noFeature ? _vm._t("default") : _vm._e(),
      _vm._v(" "),
      _vm.noAuth
        ? _vm._t("no-auth", [
            _c("v-message", { attrs: { title: "Access Denied" } }, [
              _vm._v(
                "You do not have permission to access this page.  Please contact your administrator to request access."
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.noFeature
        ? _vm._t("no-feature", [
            _c("v-message", { attrs: { title: "Access Denied" } }, [
              _vm._v("This feature has been disabled for your company.")
            ])
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import _ from 'lodash'
export default  {
    beforeCreate(){
        const setUp = ()=>{
            this._loadingMixinProperties = {
                tasks : []
            } 
            let p  = new Promise(resolve=>this._loadingMixinProperties.loadPromiseResolve = resolve)
            p.load = async ()=>{
                let tasks = this._loadingMixinProperties.tasks
                let results;
                try { 
                    results = await Promise.all(_.map(tasks,t=>t.fn())).onLoading(()=>{
                        this.loadingStatus = "loading"
                    })
                } catch(e) {
                    this.loadingStatus = "error"
                    throw e                       
                }
                _.each(results,(r,i)=>{
                    let task = tasks[i]
                    if (task.name){
                        _.set(this,task.name,r)
                    } else if (task.autoset && _.isObject(r)){
                        _.each(r,(val,key)=>{
                            _.set(this,key,val)
                        })
                    }
                })        
                this._loadingMixinProperties.tasks = []
                this.loadingStatus = "success"
                this._loadingMixinProperties.loadPromiseResolve()
                setUp()
            }
            this._loadingMixinProperties.loadPromise = p
        }
        setUp()
    },
    data(){
        return { 
            loadingStatus : "loading"
        }
    },
    methods : {
        asyncData(data){
            let tasks = this._loadingMixinProperties.tasks
            if (_.isFunction(data)){
                tasks.push({ fn : data, autoset : true})    
            } else if (_.isArray(data)){
                _.each(data,fn=>tasks.push({ fn, autoset : true }))
            } else if (_.isObject(data)){
                _.each(data,(fn,name)=>tasks.push({ fn,name }))
            }
            return this._loadingMixinProperties.loadPromise
        }
    }
}
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "d-flex justify-content-center align-items-center pb-5 pt-5"
      },
      [
        _c("div", [
          _c("i", {
            staticClass: "far fa-spinner fa-spin",
            staticStyle: { "font-size": "32px" }
          })
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "f-w-700 ml-3", staticStyle: { "font-size": "24px" } },
          [_vm._v("Loading ...")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "dropdown", attrs: { id: "notifications" } }, [
    _c(
      "a",
      {
        staticClass: "dropdown-toggle f-s-14",
        attrs: { href: "javascript:;", "data-toggle": "dropdown" },
        on: { click: _vm.open }
      },
      [
        _c("i", { staticClass: "fa fa-bell" }),
        _vm._v(" "),
        _vm.unread != "0"
          ? _c("span", { staticClass: "label" }, [_vm._v(_vm._s(_vm.unread))])
          : _vm._e()
      ]
    ),
    _vm._v(" "),
    _c(
      "ul",
      {
        staticClass: "dropdown-menu media-list dropdown-menu-right",
        attrs: { css: false },
        on: {
          "before-enter": _vm.beforeEnter,
          enter: _vm.enter,
          "after-enter": _vm.afterEnter
        }
      },
      [
        _c("li", { key: "title", staticClass: "dropdown-header" }, [
          _vm._v("NOTIFICATIONS")
        ]),
        _vm._v(" "),
        !_vm.loading && _vm.notifications && !_vm.notifications.length
          ? _c("li", { key: "none", staticClass: "media" }, [_vm._m(0)])
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.notifications, function(notification, index) {
          return _c(
            "li",
            {
              key: notification.id,
              staticClass: "media",
              attrs: { "data-index": index }
            },
            [
              _c(
                "a",
                {
                  attrs: {
                    href: notification.href || "javascript:;",
                    target: "_blank"
                  },
                  on: {
                    click: function($event) {
                      return _vm.click($event, notification)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "media-left" }, [
                    notification.img[0] == "/" || notification.img[0] == "h"
                      ? _c("img", {
                          staticClass: "media-object",
                          attrs: { src: notification.img, alt: "" }
                        })
                      : _c("i", {
                          staticClass: "fas media-object bg-silver-darker",
                          class: notification.img
                        }),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "fas media-object-icon",
                      class: notification.icon
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "media-body" }, [
                    _c("h6", { staticClass: "media-heading" }, [
                      _vm._v(_vm._s(notification.title))
                    ]),
                    _vm._v(" "),
                    notification.text
                      ? _c("p", {
                          domProps: { innerHTML: _vm._s(notification.text) }
                        })
                      : _c("p", [_vm._v(" ")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-muted f-s-11" }, [
                      _vm._v(_vm._s(_vm.$timeFormat(notification.t_stamp)))
                    ])
                  ])
                ]
              )
            ]
          )
        }),
        _vm._v(" "),
        _vm.loading
          ? _c(
              "li",
              {
                key: "loading",
                staticClass: "media",
                attrs: { "data-name": "loading" }
              },
              [_vm._m(1)]
            )
          : _vm.notifications.length < _vm.total
          ? _c(
              "li",
              { key: "view_more", staticClass: "dropdown-footer text-center" },
              [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.getNotifications()
                      }
                    }
                  },
                  [_vm._v("View more")]
                )
              ]
            )
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "media-body mt-3 mb-3" }, [
      _c("h4", { staticClass: "text-center text-muted" }, [
        _vm._v("No Notifications")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "media-body mt-5 mb-5" }, [
      _c("h4", { staticClass: "text-center" }, [
        _c("i", { staticClass: "fas fa-spinner fa-spin mr-3" }),
        _vm._v("Loading ...")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "panel panel-inverse v-menu",
      attrs: { "data-sortable": "false" }
    },
    [
      _c("div", { staticClass: "panel-heading cursor-reg" }, [
        _c(
          "div",
          { staticClass: "panel-heading-btn" },
          [_vm._t("title-btns")],
          2
        ),
        _vm._v(" "),
        _c("h4", { staticClass: "panel-title noselect" }, [
          _vm._v(_vm._s(_vm.title))
        ])
      ]),
      _vm._v(" "),
      _c(
        "ul",
        _vm._l(_vm.groups, function(ref) {
          var gid = ref.id
          var gt = ref.title
          var items = ref.items
          return _c(
            "li",
            { key: gid },
            [
              _c(
                "div",
                {
                  staticClass: "title title-group",
                  class: {
                    active: gid == _vm.selected[0],
                    opened: gid == _vm.opened
                  },
                  on: {
                    click: function($event) {
                      _vm.opened = gid
                    }
                  }
                },
                [
                  _c("span", { staticClass: "noselect" }, [
                    _c("b", { staticClass: "menu-caret mr-3" }),
                    _vm._v(
                      "\n                    " +
                        _vm._s(gt) +
                        "\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "badge pull-right" }, [
                    _vm._v(_vm._s(_vm._.size(items)))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "panel-heading-btn" },
                    [_vm._t("group-btns", null, { id: gid })],
                    2
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "transition-group",
                {
                  directives: [
                    {
                      name: "collapse",
                      rawName: "v-collapse",
                      value: gid == _vm.opened,
                      expression: "gid == opened"
                    }
                  ],
                  tag: "ul"
                },
                _vm._l(items, function(ref) {
                  var iid = ref.id
                  var it = ref.title
                  var icon = ref.icon
                  return _c("li", { key: iid }, [
                    _c(
                      "div",
                      {
                        staticClass: "title title-item",
                        class: { active: iid == _vm.selected[1] },
                        on: {
                          click: function($event) {
                            return _vm.$emit("click", iid, gid)
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "ml-3 noselect" }, [
                          icon
                            ? _c("i", {
                                staticClass: "mr-1 icon",
                                class: _vm.icons[icon]
                              })
                            : _vm._e(),
                          _vm._v(
                            "\n                            " +
                              _vm._s(it) +
                              "\n                        "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "panel-heading-btn" },
                          [_vm._t("item-btns", null, { id: iid, pid: gid })],
                          2
                        )
                      ]
                    )
                  ])
                }),
                0
              )
            ],
            1
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "top-menu",
      class: { "d-block": _vm.pageOptions.pageMobileTopMenu },
      attrs: { id: "top-menu" }
    },
    [_c("top-menu-nav")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
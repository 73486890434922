<template type="text/x-template" id="login-company-selector-template">
    <v-form @submit="onSubmit">
        <template slot-scope="{edit}">
            <div class='alert alert-success'>Select a company to sign-in</div>
            <v-input large type='select2' placeholder="Select a  company" required v-model="edit.cid">
                <optgroup v-for="group in groups" :key="group.id" :label="group.name">
                    <option v-for="company in group.children" :key="company.key.h" :value="company.key.e">
                        {{company.name}}
                    </option>
                </optgroup>
            </v-input>
            <div class="login-buttons">
                <button type="submit" class="btn btn-success btn-block btn-lg">Continue</button>
            </div>
        </template>
    </v-form> 
</template>

<script>
import _ from 'lodash';
import { customPost } from '../../scripts/custom-all'
import { mapState } from 'vuex';
export default {
    name : "company-selector",
    computed : {
        ...mapState('auth', ['company_list', 'token', 'email']),
        groups(){
            let groups = {};
            _.each(this.company_list,company => {
                if (!groups[company.parent.id]){
                    groups[company.parent.id] = {
                        name : company.parent.name,
                        id : company.parent.id,
                        children : []
                    }
                }
                groups[company.parent.id].children.push(company)
            })
            _.each(groups,group=>{
                group.children = _.sortBy(group.children,c=>c.key.h == group.id ? "" : c.name) 
            })
            return _.chain(groups).values().sortBy("name").value()
        }
    },
    methods : {
        async onSubmit(edit){
            edit.action = "login_by_token"
            edit.token = this.token
            edit.email = this.email
            let {response} = await customPost("/ajax/functions_ajax_login.php",edit);
            switch (response.result){
                case "member":
                    this.$emit("login");
                    break;
                default:
                    this.$emit('update:state', response.result);
            }
        }
    },
}
</script>
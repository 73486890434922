var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-input", {
    ref: "dropdownThingy",
    attrs: {
      inline: "",
      type: "select2",
      required: _vm.required,
      disabled: _vm.readonly,
      placeholder: "Start typing to search...",
      config: _vm.config,
      label: "Credential Card No."
    },
    model: {
      value: _vm.k,
      callback: function($$v) {
        _vm.k = $$v
      },
      expression: "k"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "panel panel-inverse" }, [
    _c("span", [
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-center",
          staticStyle: { padding: "25px 0px", "font-size": "10em" }
        },
        [_c("i", { class: _vm.icon + " " + _vm.iconColor })]
      ),
      _vm._v(" "),
      _vm.title
        ? _c(
            "h4",
            {
              staticStyle: {
                "text-align": "center",
                "font-weight": "700",
                "font-size": "24px",
                color: "#707478"
              }
            },
            [_vm._v(_vm._s(_vm.title))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "p",
        { staticStyle: { "text-align": "center", "font-size": "14px" } },
        [_vm._v(_vm._s(_vm.text)), _vm._t("default")],
        2
      )
    ]),
    _vm._v(" "),
    _c("br")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<script>
// Scorm Package
import courseMixin from './courseMixin';
export default {
    name: 'course-wrapper-6',
    mixins : [courseMixin],
    methods : {
        buyAction(){
            this.purchase()
        }
    }
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-page",
    {
      attrs: {
        title: "My Dashboard",
        subtitle: _vm.viewerLevel > 4 ? "Administration" : "Learner Dashboard"
      }
    },
    [
      _c(
        "div",
        [
          _vm.viewerLevel > 4 && _vm.isSecondary
            ? _c("LearnerDashboard", { attrs: { "skip-header": "" } })
            : _vm._e(),
          _vm._v(" "),
          _c(_vm.viewerLevel > 4 ? "admin-dashboard" : "learner-dashboard", {
            tag: "component"
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "panel panel-inverse widget-box d-flex flex-column",
      class: { "panel-loading": _vm.loading },
      attrs: { "data-sortable": "false" }
    },
    [
      _c(
        "div",
        {
          staticClass: "panel-heading",
          class: _vm.$listeners.click ? "pointer-cursor" : "",
          on: {
            click: function($event) {
              return _vm.$emit("click", _vm.hide)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "panel-heading-btn" },
            [
              _vm._t("buttons", null, { tab: _vm.tab }),
              _vm._v(" "),
              _vm.resizable
                ? _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: "Expand",
                          expression: "'Expand'"
                        }
                      ],
                      staticClass: "btn btn-xs btn-icon btn-circle btn-default",
                      attrs: {
                        href: "javascript:;",
                        "data-click": "panel-expand"
                      }
                    },
                    [_c("i", { staticClass: "fa fa-expand" })]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.reloadable
                ? _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: "Reload",
                          expression: "'Reload'"
                        }
                      ],
                      staticClass: "btn btn-xs btn-icon btn-circle btn-success",
                      attrs: {
                        href: "javascript:;",
                        "data-click": "panel-reload"
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.$emit("reload")
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-redo" })]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.expandable
                ? _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.show ? "Minimize" : "Maximize",
                          expression: "show ? 'Minimize' : 'Maximize'"
                        }
                      ],
                      staticClass: "btn btn-xs btn-icon btn-circle btn-warning",
                      attrs: {
                        href: "javascript:;",
                        "data-click": "panel-collapse"
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.show = !_vm.show
                          _vm.$emit("expand", _vm.show)
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fa",
                        class: _vm.show ? "fa-minus" : "fa-window-maximize"
                      })
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.addable
                ? _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: "Add",
                          expression: "'Add'"
                        }
                      ],
                      staticClass: "btn btn-xs btn-icon btn-circle btn-green",
                      attrs: { href: "javascript:;" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.$emit("add")
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-plus" })]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.editable
                ? _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: "Edit",
                          expression: "'Edit'"
                        }
                      ],
                      staticClass: "btn btn-xs btn-icon btn-circle btn-info",
                      attrs: { href: "javascript:;" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.$emit("edit")
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-edit" })]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.closeable
                ? _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: "Close",
                          expression: "'Close'"
                        }
                      ],
                      staticClass: "btn btn-xs btn-icon btn-circle btn-danger",
                      attrs: { href: "javascript:;" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.$emit("close")
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-times" })]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.assignable
                ? _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: "Assign",
                          expression: "'Assign'"
                        }
                      ],
                      staticClass: "btn btn-xs btn-icon btn-circle btn-green",
                      attrs: { href: "javascript:;" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.$emit("assign")
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-user-plus" })]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.unassignable
                ? _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: "Unassign",
                          expression: "'Unassign'"
                        }
                      ],
                      staticClass: "btn btn-xs btn-icon btn-circle btn-warning",
                      attrs: { href: "javascript:;" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.$emit("unassign")
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-user-slash" })]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.deletable
                ? _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: "Delete",
                          expression: "'Delete'"
                        }
                      ],
                      staticClass: "btn btn-xs btn-icon btn-circle btn-danger",
                      attrs: { href: "javascript:;" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.$emit("delete")
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-trash" })]
                  )
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "h4",
            { staticClass: "panel-title" },
            [
              _vm._t("title", [_vm._v(_vm._s(_vm.title) + " ")]),
              _vm._v(" "),
              _vm.dropdown
                ? _c("div", { staticClass: "pull-right" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.dropdownSelected,
                            expression: "dropdownSelected"
                          }
                        ],
                        staticClass: "btn btn btn-inverse btn-xs",
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.dropdownSelected = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      _vm._l(_vm.dropdown, function(val) {
                        return _c(
                          "option",
                          { key: val, domProps: { value: val } },
                          [_vm._v(_vm._s(val))]
                        )
                      }),
                      0
                    )
                  ])
                : _vm._e()
            ],
            2
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "collapse",
              rawName: "v-collapse",
              value: _vm.show,
              expression: "show"
            }
          ],
          staticClass: "panel-body flex-grow-1 p-0",
          class: _vm.fullColor ? "bg-black" : ""
        },
        [
          _c(
            "div",
            { staticClass: "p-15 widget-box-body" },
            [
              _vm._t("default", null, { tab: _vm.tab }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  staticClass: "overlay bg-white"
                },
                [_c("span", { staticClass: "spinner" })]
              )
            ],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
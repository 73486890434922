import VeeValidate from 'vee-validate';
import Swal from 'sweetalert2';
import _ from 'lodash'
import $ from 'jquery'
import moment from 'moment';
import store from './store';
const default_config = {
    method : "GET",
    dataType: 'json',
    dataFilter(data){
        if (!data.length){
            return null;
        }
        return data;
    }
}   

function prepare(callbacks,args){
    let config = _.assign({},default_config,callbacks)
    switch (args.length){
        case 1:
            // $ajax(config)
            if (_.isObject(args[0]))
            {
                _.assign(config,args[0]);
                if (config.type){
                    config.method = config.type
                    delete config.type
                }
            } 
            // $ajax(url)
            else if (_.isString(args[0]))
            {
                config.url = args[0]
                config.method = "GET"
            } 
            else 
            {
                return null
            }
            break

        case 2:
            config.url = args[0]
            if (_.isObject(args[1])){
                config.data = args[1]
                config.method = "POST"
            } else if (_.isString(args[1])) {
                config.method = args[1]
            } else {
                return null
            }
            break
        case 3:
            config.url = args[0]
            config.method = args[1]
            config.data = args[2]
            break
        default:
            return null
    }
    config.headers = _.assign({},config.headers,{"X-CSRF-TOKEN" : store.state.auth.CSRF_TOKEN})
    config.xhrFields = _.assign({}, config.xhrFields, {withCredentials: process.env.NODE_ENV != 'production'})
    config.url = config.url.charAt(0) != 'h' ? `${process.env.VUE_APP_AJAX_PATH}${config.url}` : config.url;
    return config
}
export default {
    install(Vue) {
        window.appLoading.push(new Promise((resolve)=>{
            Vue.prototype.$appReady = resolve;
        }));
        window.appLoading.push(new Promise((resolve)=>{
            $(document).ready(resolve);
        }));
        let ready = Promise.all(window.appLoading);

        window.onAppReady = fn => ready.then(fn);
        window.onAppReady(()=>{
            window.App.init()
        });
        _.each(window.appReady,fn=>window.onAppReady(fn));

        /*
        ********************* 
        *       Utils       *
        * *******************
        */
        Vue.prototype.Color = (hex)=>{
            let r,g,b,a;
            r = g = b = 0;
            a = 1.0;
            if (hex.indexOf('#') === 0) {
                hex = hex.slice(1);
            }
            // convert 3-digit hex to 6-digits.
            if (hex.length === 3) {
                hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
            }
            if (hex.length >= 6){
                r = parseInt(hex.slice(0, 2), 16);
                g = parseInt(hex.slice(2, 4), 16);
                b = parseInt(hex.slice(4, 6), 16);
            }

            if (hex.length == 8){
                a = parseInt(hex.slice(6, 8), 16) / 255.0;
            }

            return {
                r,g,b,a,
                toString(){
                    return `rgba(${this.r},${this.g},${this.b},${this.a})`;
                },
                get invert(){
                    return (this.r * 0.299 + this.g * 0.587 + this.b * 0.114) > 186 ? 'black' : 'white';
                }
            }
        };

        Vue.prototype.$invertColor = (hex)=>{
            return Vue.prototype.Color(hex).invert;
        };

        Vue.prototype.$timeFormat = (time,format="llll") =>  moment(time).format(format);

        Vue.prototype.$parseDaterange = (daterange) => {

            if (!_.isString(daterange)){
                return daterange;
            }

            return _.map(_.split(daterange,","),(s,index)=>{
                if (s == "INF") return null;
                let m = moment();
                let number = Number.parseInt(s.slice(0,-1));
                let type = s.slice(-1);
                if (type == 'd'){
                    m.add(number,"d");
                } else if (type == 'm'){
                    m.date(1);
                    m.add(number,"M");
                } else if (type == 'y'){
                    m.dayOfYear(1);
                    m.add(number,"y");
                }
                if (index == 1 && type!="d"){
                    m.add(-1,"d");
                }
                return m.format().substr(0,10);
            });
        }

        // globals
        Vue.prototype.$assets_path = process.env.VUE_APP_ASSETS_PATH
        Vue.prototype.$ajax_path = process.env.VUE_APP_AJAX_PATH

        // ajax
        window.addEventListener("unhandledrejection", e => {
            if (_.get(e,"reason.type") == "AJAX Response"){
                e.preventDefault()
                Swal.fire("Error",_.get(e,"reason.msg"),"error")
            }
        });
        Vue.prototype.$ajax=(...args)=>new Promise((resolve,reject)=>{
            let callbacks = {
                success(output){
                    resolve(output);
                },
                error(xhr,textStatus,errorThrown){
                    if (!xhr.readyState){
                        reject("XHR Send Failed");
                        return;
                    }
                    switch(textStatus){
                        case "timeout":
                            window.Swal.fire("Request Timeout","","error")
                            break;
                        case "error":
                            if (errorThrown == "Unauthorized" && xhr.status == 401 && xhr.responseText){
                                _.invoke(window,"VueApp.openLoginBox",xhr.responseText)
                            } else if (xhr.responseText) {
                                reject({type : "AJAX Response", msg : _.chain(xhr.responseText).split("\n").get(0).value()})
                            } else {
                                window.Swal.fire("Please Try Again",errorThrown,"error")
                            }
                            break;
                        default:
                            if (textStatus == "parsererror"){
								window.Swal.fire({
                                    title : "Parse Error",
									html : xhr.responseText
                                })
                            } else {
                                window.Swal.fire("Please Try Again",textStatus,"error")
                            }
                    }
                    reject(textStatus);
                }
            };
            let config = prepare(callbacks,args)
            if (!config){
                throw "Invalid paramters"
            }
            $.ajax(config);
        });

        /*
        ***********************    
        *       Directive     *
        * *********************
        */
        Vue.use(VeeValidate);

        VeeValidate.Validator.extend("hasLower",{
            getMessage : field => 'The ' + field + ' should contain at least one lower letter',
            validate : value => value !== _.toUpper(value)
        });
        VeeValidate.Validator.extend("hasNumber",{
            getMessage : field => 'The ' + field + ' should contain at least one number',
            validate : value => value !== _.replace(value , new RegExp("[0-9]","g") ,"")
        });
        VeeValidate.Validator.extend("hasUpper",{
            getMessage : field => 'The ' + field + ' should contain at least one upper letter',
            validate : value => value !== _.toLower(value)
        });


        Vue.directive('tooltip',{
            bind(el,{value : title}){
                el.title = title;
                $(el).tooltip({trigger : "hover"});
            },
            update(el,{value : title}){
                $(el).tooltip("dispose");
                el.title = title;
                $(el).tooltip({trigger : "hover"});
            },
            unbind(el){
                $(el).tooltip("dispose");
            }
        });
        Vue.directive('collapse',{
            bind(el,{value}){
                $(el).addClass("collapse");
                if (value){
                    $(el).addClass("show");
                }
            },
            update(el,{value}){
                if (value){
                    $(el).collapse("show");
                } else {
                    $(el).collapse("hide");
                }
            },
            // unbind(el){
            //     $(el).collapse("dispose");
            // }
        });


        /*
        *********************    
        *       Plugins     *
        * *******************
        */
        // noop
        Vue.prototype.$noop = function(){return undefined;}
        Vue.prototype.$noop._isNoop = true;
        Vue.prototype.$noListener = function(name){
            let fn = _.get(this,["$listeners",name,"fns"],this.$noop)
            return fn._isNoop == true
        }

        // store
        Vue.prototype.$vstore = Vue.observable({});
        Vue.set(Vue.prototype.$vstore, 'titleButton', {
            type : "primary",
            html : null,
            callback : null
        })

        // bus
        // Vue.use(VueBus);
        Vue.prototype.$bus = new Vue();

        // poll controller
        Vue.prototype.$poll = new Vue ({
            created(){
                this.tasks = new Map();
                window.onAppReady(()=>{
                    this.update();
                    this.interval = setInterval(this.update, _.get(window,["_global_configs","POLLING_INTERVAL"], 15000))
                });
                this.seq = 0;
                this.ack = -1;
                this.$bus.$on("login",this.startService);
                this.startService();
            },
            beforeDestroy(){
                this.$bus.$off("login",this.startService);
                clearInterval(this.interval);
            },
            methods : {
                register(task){
                    this.tasks.set(task.name,task)
                    if (this.seq > 0){
                        this.update(task.name)
                    }
                },
                remove(name){
                    this.tasks.delete(name);
                },
                reset(name){
                    let task = this.tasks.get(name)
                    if (!task) return
                    task.inited = false
                    this.update(name)
                },
                startService(){
                    this.running = true;
                },
                async update(name=null){
                    let data = {};
                    if (name) {
                        let task = this.tasks.get(name);
                        if (!task) return;
                        data[name] = task.get ? task.get() : null;
                    } else {
                        // No auto update if service stopped
                        if (!this.running){
                            return;
                        }

                        // Get all tasks
                        this.tasks.forEach((task,name) => {
                            if (task){
                                if (task.get){
                                    data[name] = task.get()
                                } else {
                                    data[name] = true
                                }
                            }
                        });
                    }
                    if (!_.size(data)){
                        return;
                    }

                    let loading = _.transform(data,(result,value,name)=>{
                        if (!value) return
                        let task = this.tasks.get(name)
                        if (task.init && !task.inited) result.push(task)
                    },[])

                    try {
                        let seq = this.seq++;
                        let events = await this.$ajax(`${process.env.VUE_APP_AJAX_PATH}/ajax/poll.php?seq=${seq}`,"post",data);

                        // drop outdate response
                        if (seq < this.ack) {
                            return;
                        }
                        this.ack = seq;

                        _.each(events,(event,name)=>{
                            let task = this.tasks.get(name);
                            if (task && task.set){
                                task.set(event);
                            }     
                        });
                        _.each(loading,(task)=>{
                            task.init();
                            task.inited = true;
                        });
                    } catch (e){  
                        this.running = false;
                    }
                }
            },
        });       
    }
};
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.menu.children
    ? _c(
        "li",
        {
          staticClass: "has-sub",
          class: { active: _vm.subIsActive(_vm.menu.path) }
        },
        [
          _c(
            "a",
            {
              attrs: { href: "javascript:;" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.expand()
                }
              }
            },
            [
              _vm.menu.icon ? _c("i", { class: _vm.menu.icon }) : _vm._e(),
              _vm._v(" "),
              _vm.menu.img
                ? _c("div", { staticClass: "icon-img" }, [
                    _c("img", { attrs: { src: _vm.menu.img, alt: "" } })
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.menu.title))]),
              _vm._v(" "),
              _vm.menu.badge
                ? _c("span", { staticClass: "badge pull-right" }, [
                    _vm._v(_vm._s(_vm.menu.badge))
                  ])
                : _c("b", { staticClass: "caret" }),
              _vm._v(" "),
              _vm.menu.highlight
                ? _c("i", { staticClass: "fa fa-paper-plane text-theme m-l-5" })
                : _vm._e(),
              _vm._v(" "),
              _vm.menu.label
                ? _c("span", { staticClass: "label label-theme m-l-5" }, [
                    _vm._v(_vm._s(_vm.menu.label))
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "ul",
            {
              staticClass: "sub-menu",
              class: {
                "d-block": this.stat == "expand",
                "d-none": this.stat == "collapse"
              },
              style: {
                marginTop: _vm.pageOptions.pageSidebarMinified
                  ? -(_vm.scrollTop + 40) + "px"
                  : ""
              }
            },
            [
              _vm._l(_vm.menu.children, function(submenu) {
                return [
                  _c("top-menu-nav-list", {
                    key: submenu.path,
                    ref: "topMenuNavList",
                    refInFor: true,
                    attrs: { menu: submenu },
                    on: {
                      "collapse-other": function($event) {
                        return _vm.handleCollapseOther(submenu)
                      }
                    }
                  })
                ]
              })
            ],
            2
          )
        ]
      )
    : _c(
        "router-link",
        {
          attrs: { to: _vm.menu.path, "active-class": "active", tag: "li" },
          nativeOn: {
            click: function($event) {
              return _vm.collapseOther()
            }
          }
        },
        [
          _c("a", [
            _vm.menu.badge
              ? _c("span", { staticClass: "badge pull-right" }, [
                  _vm._v(_vm._s(_vm.menu.badge))
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.menu.icon ? _c("i", { class: _vm.menu.icon }) : _vm._e(),
            _vm._v(" "),
            _vm.menu.img
              ? _c("div", { staticClass: "icon-img" }, [
                  _c("img", { attrs: { src: _vm.menu.img, alt: "" } })
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.menu.title))]),
            _vm._v(" "),
            _vm.menu.highlight
              ? _c("i", { staticClass: "fa fa-paper-plane text-theme m-l-5" })
              : _vm._e(),
            _vm._v(" "),
            _vm.menu.label
              ? _c("span", { staticClass: "label label-theme m-l-5" }, [
                  _vm._v(_vm._s(_vm.menu.label))
                ])
              : _vm._e()
          ])
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
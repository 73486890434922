import Vue from 'vue'

if (localStorage.dark) {
  document.getElementById('css-theme').href=`${process.env.BASE_URL}assets/css/style_dark.min.css`;
}


// plugins
import VueBootstrap from 'bootstrap-vue'
// import VuePanel from './plugins/panel/'
// import VueSlider from 'vue-slider-component'
import VueCustomScrollbar from 'vue-custom-scrollbar'

// plugins css -if any of these are available by cdn, we might want to load them from there
import 'bootstrap-vue/dist/bootstrap-vue.css'
// import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
// import 'simple-line-icons/css/simple-line-icons.css'
// import 'flag-icon-css/css/flag-icon.min.css'
// import 'ionicons/dist/css/ionicons.min.css'
// import 'fullcalendar/dist/fullcalendar.css'
// import 'vue-slider-component/theme/antd.css'

import _ from 'lodash'
// color admin css
import './assets/css/legacy/style-responsive.min.css'
import './assets/css/legacy/theme/red.css'
// legacy color
// color admin customizations
import './scss/vue.scss'
import 'bootstrap-social/bootstrap-social.css'
import 'select2/dist/css/select2.min.css'
// datepicker css
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css'
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.min'
// prosper lms additions
import './assets/custom-css.css'
import './assets/custom-all.css'
import AsyncComputed from 'vue-async-computed';
import router from './router'
import core from './core';
import icons from './icons';
import App from './App.vue'
import { VMessage, WidgetBox, VPage, ThemePanel, VTable  } from './components/common'
import { VFormModal, VForm, VInput, CheckBox } from "./components/form";
import store from './store'
Vue.use(VueBootstrap)
// Vue.use(VuePanel)
// Vue.use(VueGoogleMaps, {
//   load: {
//     key: '',
//     libraries: 'places'
//   }
// // })
// Vue.component('vue-slider', VueSlider)
Vue.component('vue-custom-scrollbar', VueCustomScrollbar)
Vue.component('v-message', VMessage);
Vue.component('VFormModal', VFormModal);
Vue.component('VTable', VTable);
Vue.component('VForm', VForm);
Vue.component('VInput', VInput);
Vue.component('VPage', VPage)
Vue.component("CheckBox", CheckBox);
Vue.component('ThemePanel', ThemePanel)
Vue.component('widget-box', WidgetBox);
Vue.use(core);
Vue.use(icons);
// make lodash available everywhere. 
Vue.use({
  install: Vue => Vue.prototype._ = _
})
Vue.use(AsyncComputed);
import $ from 'jquery'
(async()=>{
  let res = await $.ajax({
    url:`${process.env.VUE_APP_AJAX_PATH}/ajax/functions_ajax_core.php?action=core`,
    method: 'get', 
    xhrFields: {
      withCredentials: process.env.NODE_ENV == 'production' ? false : true
    },  
  });
  store.commit('auth/core', res.result);
  // get cart contents on page load
  store.dispatch('cart/refreshFullCart')
  window.VueApp = new Vue({
    router,
    store, 
    render: h => h(App)
  }).$mount('#app')
})();

<script>
// online
import courseMixin from './courseMixin';
export default {
    name: 'course-wrapper-1',
    mixins : [courseMixin],
    methods : {
        buyAction(){
            this.purchase()
        }
    }
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "modal",
      staticClass: "modal fade",
      attrs: {
        id: "site-picker",
        tabindex: "-1",
        role: "dialog",
        "aria-hidden": "true"
      }
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-lg", attrs: { role: "document" } },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("h4", { staticClass: "modal-title" }, [
                _vm._v(
                  _vm._s(_vm.course_name) +
                    " is offered at the following locations:"
                )
              ]),
              _vm._v(" "),
              _vm._m(0)
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c(
                "ul",
                _vm._l(_vm.sites, function(site) {
                  return _c("li", { key: site.address, staticClass: "mb-3" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _c("h5", { staticClass: "mb-0 mb-0" }, [
                          _vm._v(_vm._s(site.site_name))
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.getMapURL(site),
                                target: "_blank"
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(site.address) +
                                  ", " +
                                  _vm._s(site.city) +
                                  ", " +
                                  _vm._s(site.postal_code)
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        site.next_course
                          ? _c("span", [
                              _c("b", [_vm._v("Next Course:")]),
                              _vm._v(" " + _vm._s(site.next_course))
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-3 d-flex align-items-center justify-content-center"
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-lime",
                              on: {
                                click: function($event) {
                                  return _vm.openSchedule(site)
                                }
                              }
                            },
                            [
                              _vm._v("View Schedule"),
                              _c("i", {
                                staticClass: "ml-2 fas fa-calendar-alt"
                              })
                            ]
                          )
                        ]
                      )
                    ])
                  ])
                }),
                0
              )
            ]),
            _vm._v(" "),
            _vm.action
              ? _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button", "data-dismiss": "modal" }
                    },
                    [_vm._v("Close")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { "data-dismiss": "modal", type: "button" },
                      on: { click: _vm.action }
                    },
                    [
                      _vm._v("Add To Cart"),
                      _c("i", { staticClass: "ml-2 fas fa-shopping-cart" })
                    ]
                  )
                ])
              : _vm._e()
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>
	<div id="vue-container">
        <!-- <v-message title="Loading..." icon="fad fa-spinner-third fa-spin" text="Please wait" /> -->

		<div class="row" v-if="_.size(components) == 0">
			<div class="col-12">
				<v-message title="No components pinned to dashboard" icon="fal fa-shapes">
					Click <router-link to="/dashboard/settings">here</router-link> to enable components
				</v-message>
			</div>
		</div>
        
		<transition-group 
			tag="div"
			class="d-flex state-components flex-wrap" 
			leave-active-class="d-none"
		>
			<div 
                v-show="show"
				:style="{
					width : UI.sw + 'px'
				}"
				v-for="component in UI.state" :key="component.key.h"
				class="state-component"
				:data-key="component.key.h"
			>
				<v-state
					:category="component.category"
					:title="component.title"
					:color="component.color"
					:filter="component.filter"
					:icon="component.icon"
					:label="component.label"
					class="hvr-float width-full"
				></v-state>
			</div>
		</transition-group >


		<transition-group  
			tag="div"
			class="d-flex flex-column flex-wrap search-components" 
			:style="{height : UI.sh + 'px'}"	
			leave-active-class="d-none"
			keep-alive
		>
			<div 
                v-show="show"
				v-for="{key,category,endHeight,deleting} in UI.search" 
				:key="key.h"  
				:data-key="key.h"
				class="search-component"
				:style="{
					width : UI.sw + 'px',
					'margin-bottom': endHeight ? UI.sh - endHeight + 'px' : '',
					visibility: deleting ? 'hidden' : ''
				}"	
			>
				<component
					:is="`${category}-search-filter`"
					@search="onSearch"
					@unpin="unpin"
				></component>
			</div>
		</transition-group>
        <div>

			<transition-group
				enter-active-class="animated fadeInRight"
				leave-active-class="d-none position-absolute"
				tag = "div"
				class="row"
			>
				<div  class="col-12" v-for="(item,index) in items" :key="item.id">
					<component :is="`${item.type}-search-result`" :item="item" :results="results[item.type]" @close="onClose(index)"></component>
				</div>
			</transition-group>
		</div>
	</div>
</template>

<script>
import $ from 'jquery'
import _ from 'lodash';
import { createStore } from '../../scripts/store'
import comps from '../../components/search';
import VState from '../../components/state/VState'
import { mapState } from "vuex";
import { loadSearchComponents } from '../../scripts/custom-all';
let dragging = null;
let resizeEventListener = null;
let store = createStore("DashboardAdmin",{
    default : {
        search : {},
        state :{}
    }
})
export default {
    components: {
        // whatever search filter
        ...comps, 
        VState
    },
    data(){	
        let components = {};
        let UI = {
            sw : null,
            sh : null,
            nc : 0,
            search : null,
            state : null
        };
        
        return {
            results : {},
            items : [],
            components : [],
            UI,
            eventListeners: false,
            show: false
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', resizeEventListener)
    }, 
    async mounted() {
        let components = await this.getComponents();
        let UI = {
            sw : null,
            sh : null,
            nc : 0,
            search : null,
            state : null
        };
        _.each(["search","state"],type => {
            _.each(components[type],component=>{
                component.order = _.get(store,[type,component.key.h])
                component.deleting = 0
            })
            UI[type] = _.chain(components[type])
                .values()
                .sortBy("order")
                .value()
        })
        this.UI = UI;
        this.components = components;
        // when components are mounted, 
        // they need to load async.
        // wait ~2 secs until the component is mounted
        await new Promise(r=>setTimeout(r,1000));
        await this.$nextTick();
        // now the real function starts
        let fn = ()=>{
            let container_width = $("#vue-container").width()
            const MIN_COL_WIDTH = 350
            let num_cols = Math.floor(container_width / MIN_COL_WIDTH)
            if (num_cols == 0) {
                num_cols = 1
            }
            this.UI.sw = (container_width - 20 * (num_cols - 1)) / num_cols
            if (this.UI.nc != num_cols){
                this.UI.nc = num_cols
                this.$nextTick(this.updateUI)
            }
        }
        fn()
        resizeEventListener = _.debounce(fn,300);
        window.addEventListener('resize', resizeEventListener)
        this.show = true;
        _.each(["search","state"],type=>{
            $(`.${type}-component` + (type=="search" ? " .panel-heading" : ""))
                .css("cursor","all-scroll")
                .prop("draggable",true)
                .on("dragstart",(ev)=>{
                    let key = $(ev.target).closest(`.${type}-component`).data("key")
                    this.dragging = {type,key}
                    ev.originalEvent.dataTransfer.setData("text/plain",`${key}`)
                })
                .on("dragend",()=>{
                    $(".dragging-over").removeClass("dragging-over")
                    this.dragging = null
                })

            $(`.${type}-component`)
                .on("dragover",ev=>{
                    let el = $(ev.target).closest(`.${type}-component`)
                    let key = el.data("key")
                    if (key && this.dragging.key != key && this.dragging.type == type){
                        $(".dragging-over").removeClass("dragging-over")
                        ev.preventDefault();
                        el.addClass("dragging-over")
                    }
                })
                .on("dragleave",ev=>{
                    if ($(ev.target).hasClass(`${type}-component`)){
                        $(".dragging-over").removeClass("dragging-over")
                    }
                })
                .on("drop",ev=>{
                    ev.preventDefault()
                    let key = $(ev.target).closest(`.${type}-component`).data("key")

                    let from = this.components[type][this.dragging.key]
                    let to = this.components[type][key]

                    if (from && to){
                        let temp = from.order
                        from.order = to.order
                        to.order = temp
                        this.updateUI()
                    }

                    $(".dragging-over").removeClass("dragging-over")
                    this.dragging = null
                })
        })		

    }, 
    methods:{
        async getComponents() {
            let response = await this.$ajax(`/ajax/functions_ajax_dashboard.php`, "POST", {
                action: "get_dashboard_components"
            });
            return response.result;
        },
        onClose(index){
            this.items.splice(index,1);
        },
        onSearch(result,type,filter){
            let item = {
                id : _.uniqueId("result"),
                type,
                filter
            };

            if (!this.results[type]){
                this.$set(this.results,type,{});
            }
            let rows = [];
            _.each(result, (row)=>{
                this.$set(this.results[type],row.id,row);
                rows.unshift(row.id);
            });
            item.rows = rows;
            this.items.unshift(item);
        },

        unpin(type){
            for (let index = 0; index < this.components.search.length; ++index){
                if (this.components.search[index].category == type){
                    this.components.search.splice(index,1);
                    return;
                }
            }
        },
        
        updateUI(){
            let components = {}

            _.each(["search","state"],type=>{
                components[type] =  _.sortBy(this.components[type],["deleting","order"])
                let orders = {}
                _.each(components[type],(component,index)=>{
                    component.order = index
                    orders[component.key.h] = index
                    if (type == "search"){
                        component.height = component.deleting ? 0 : $(`.search-component[data-key=${component.key.h}]`).height()
                        if (component.deleting){
                            requestAnimationFrame(()=>{
                                this.UI.search.splice(-1,1)
                            })
                            this.$delete(this.components.search,component.key.h)
                        }
                    }
                })
                store[type] = orders
            })

            let cols = this.partition_list(components.search,this.UI.nc)
            this.UI.sh = _.chain(cols)
                .map(chunk => {
                    let sum = 0;
                    _.each(chunk,(component,index)=>{
                        sum += component.height
                        component.endHeight = index == chunk.length - 1 ? sum : null
                    })
                    return sum
                })
                .reduce((max,n)=>{
                    return n > max ? n : max
                },0)
                .value()

            this.UI.search = components.search
            this.UI.state = components.state
        },
        // partition list a into k partitions
        partition_list(a,k){				
            // calculate presum
            let presum = [0]
            for (let i = 0; i < a.length; ++i){
                presum[i + 1] = presum[i] + a[i].height
            }

            // greedy calculate pivots 
            let total = _.sumBy(a,"height")
            let pivots = []
            let last = 0
            let threshold = total/ k
            for (let i = 0; i < a.length; ++i){
                if (pivots.length + 1 >= k){
                    break
                }
                if (k - 1 - pivots.length >= a.length - i){
                    pivots.push(i)
                    continue
                }
                let chunk_size = presum[i] - presum[last]
                let included = presum[i+1] - presum[last]
                if (Math.abs(chunk_size - threshold) <= Math.abs(included - threshold)){
                    pivots.push(i)
                    last = i
                    threshold = (total - presum[i]) / (k - pivots.length)
                }
            }
            
            // get partitions by pivots
            let partitions = []
            let index = 0
            _.each(pivots,p=>{
                partitions.push(_.slice(a,index,p))
                index = p
            })
            partitions.push(_.slice(a,index))
            return partitions
        }
    },
}

</script>
<style>
.search-components,.state-components {
    margin-left : -10px;
    margin-right : -10px;
    align-content: flex-start;
}
.search-components>.search-component,.state-components>.state-component{
    margin-left:10px;
    margin-right:10px;
}
.search-component.dragging-over .widget-box, .state-component.dragging-over .v-state{
    outline: 3px #ff5b57 dashed;
}
</style>


<script>
import VForm from './VForm.vue';
import _ from 'lodash';
import 'bootstrap';
import $ from 'jquery';
 export default {
        name: 'v-form-modal',
        components: {
            VForm
        },
        props : {
            large : Boolean,
            noFooter : Boolean,
            title : String,
            pre : Function,
            post : Function,
            backdrop : {
                type: String,
                default: 'static'
            }
        },
        data(){
            return {
                key : null,
                obj : {},
                config : {}
            };
        },
        async mounted(){
            $(this.$refs.modal).on("hidden.bs.modal",(e)=>{
                if (e.target == e.currentTarget){
                    this.key=null;
                }   
                if (this.reason){
                    this.reject(this.reason);
                }         
            });
            $(this.$refs.modal).on("shown.bs.modal",()=>{
                $(this.$refs.form.$el).find('.v-input [autofocus]').focus();
            });
            // adds keybaord support for checkbox (just add class ch_overlay)
        },
        beforeDestroy(){
            this.close();
            $(this.$refs.modal).off("hidden.bs.modal");
            $(this.$refs.modal).off("shown.bs.modal");
        },
        methods : {
            onSubmit(edit){
                this.close();
                if (this.post) edit = this.post(edit);
                this.resolve(edit);
            },
            open(obj={},config={}){
                if (this.pre) obj = this.pre(obj);                
                this.obj = obj;
                this.config = config;
                this.promise = new Promise((resolve,reject)=>{
                    this.resolve = resolve;
                    this.reject = reject;
                });
                this.key = _.uniqueId("v-form-modal");
                this.$nextTick(()=>{   
                    $(this.$refs.modal).modal("show");
                    this.$nextTick().then(() => {
                        $(".ch_overlay").off('keydown').keydown((event) => {
                            if (event.which == 32) {
                                let cb = $(event.currentTarget).find("input");
                                cb.attr("checked", !cb.attr("checked"));
                                cb.click();
                            }
                        });
                    });
                });
                return this.promise;
            },
            close(reason=null){
                this.reason = reason;
                $(this.$refs.modal).modal("hide");
            },
            reset(){
                return this.$refs.form.reset();
            }
        },
    };
</script>

<template>
    <div ref="modal" class="modal fade" tabindex="-1" role="dialog" :data-backdrop="backdrop" aria-hidden="true" data-focus="false">
        <div class="modal-dialog modal-dialog-centered" :class="{'modal-lg' : large}" role="document">
            <div class="modal-content">
                <VForm v-if="key" ref="form" @submit="onSubmit" :key="key" :disabled="config.disabled || $attrs.disabled" :object="obj" v-bind="$attrs" >
                    <template slot-scope="{ edit,disabled }">
                        <div v-if="$scopedSlots.title || title || config.title" class="modal-header">
                            <h5 class="modal-title width-full" ><slot name="title" :edit="edit" :close="close">{{title || config.title}}</slot></h5>
                            <button type="button" class="close" @click="close()" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <slot :edit = "edit" :close="close" :disabled="disabled" :config="config"> </slot>
                        </div>
                        <div v-if="!noFooter && !config.noFooter" class="modal-footer">
                            <slot name="footer"  :close="close" :edit="edit">
                                <button type="button" class="btn btn-secondary" @click="close()">Close</button>
                                <button class="btn btn-green">Submit</button>
                            </slot>
                        </div>
                    </template>
                </VForm>
            </div>
        </div>
    </div>
</template>
import { render, staticRenderFns } from "./VTag.vue?vue&type=template&id=5cf0c328&scoped=true&"
import script from "./VTag.vue?vue&type=script&lang=js&"
export * from "./VTag.vue?vue&type=script&lang=js&"
import style0 from "./VTag.vue?vue&type=style&index=0&id=5cf0c328&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cf0c328",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/work/webpack/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5cf0c328')) {
      api.createRecord('5cf0c328', component.options)
    } else {
      api.reload('5cf0c328', component.options)
    }
    module.hot.accept("./VTag.vue?vue&type=template&id=5cf0c328&scoped=true&", function () {
      api.rerender('5cf0c328', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/VTag.vue"
export default component.exports
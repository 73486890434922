<script>
// blended
import _ from 'lodash';
import courseButtonActionHelpers from './cabh';
import courseMixin from './courseMixin';
export default {
    name: 'course-wrapper-2',
    mixins : [courseMixin],
    created(){
        _.assign(this.passStateOptions,{
            "9" : "schedule",
            "10" : "schedule"
        });
        this.btnOptions["schedule"] = {
            icon : "fa-calendar-alt",
            content : "Schedule",
            type : "btn-lime",
            onclick : ()=>{courseButtonActionHelpers["site-picker"].open(this.course.id);}
        }
    },
    methods : {
        buyAction(){
            courseButtonActionHelpers["site-picker"].open(this.course.id,()=>{
                this.purchase();
            });
        }
    }
};
</script>
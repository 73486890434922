
import { 
    CourseWrapper1, 
    CourseWrapper2, 
    CourseWrapper3, 
    CourseWrapper4, 
    CourseWrapper5, 
    CourseWrapper6, 
    CourseWrapper7,
    CourseWrapper8
} from '../wrapper'
import {StarRating } from '../form'
export default {
    components: {
            
        CourseWrapper1, 
        CourseWrapper2, 
        CourseWrapper3, 
        CourseWrapper4, 
        CourseWrapper5, 
        CourseWrapper6, 
        CourseWrapper7,
        CourseWrapper8,
        StarRating
    }
}
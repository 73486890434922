<template>
    <li class="dropdown" id="notifications">
        <a @click="open" href="javascript:;" data-toggle="dropdown" class="dropdown-toggle f-s-14">
            <i class="fa fa-bell"></i>
            <span class="label" v-if="unread != '0'">{{unread}}</span>
        </a>
        <ul 
            class="dropdown-menu media-list dropdown-menu-right"
            @before-enter="beforeEnter"
            @enter="enter"
            @after-enter="afterEnter"
            :css="false"
        >
            <li class="dropdown-header" key="title">NOTIFICATIONS</li>
            <li v-if="!loading && notifications && !notifications.length" class = "media" key="none">
                <div class="media-body mt-3 mb-3">
                    <h4 class="text-center text-muted">No Notifications</h4>
                </div>
            </li>
            <li class="media" v-for="(notification,index) in notifications" :key="notification.id" :data-index="index">
                <a :href="notification.href || 'javascript:;'" target="_blank" @click="click($event, notification)">
                    <div class="media-left">
                        <img v-if="notification.img[0] == '/' || notification.img[0] == 'h'" :src="notification.img" class="media-object" alt="">
                        <i v-else class="fas media-object bg-silver-darker" :class="notification.img"></i>
                        <i class="fas media-object-icon" :class="notification.icon"></i>
                    </div>
                    <div class="media-body">
                        <h6 class="media-heading">{{notification.title}}</h6>
                        <p v-html="notification.text" v-if="notification.text"></p>
                        <p v-else>&nbsp;</p>
                        <div class="text-muted f-s-11">{{$timeFormat(notification.t_stamp)}}</div>
                    </div>
                </a>
            </li>
            <li v-if="loading" class = "media" key="loading" data-name="loading">
                <div class="media-body mt-5 mb-5">
                    <h4 class="text-center"><i class="fas fa-spinner fa-spin mr-3" ></i>Loading ...</h4>
                </div>
            </li>
            <li v-else-if="notifications.length < total" class="dropdown-footer text-center" key="view_more">
                <a @click.stop="getNotifications()" href="javascript:;">View more</a>
            </li>
        </ul>
    </li>
</template>
<script>
import jQuery from 'jquery';
import 'velocity-animate'
const $ = jQuery;
import _ from 'lodash';
import { customPost, timeout } from '../scripts/custom-all';
export default {
    name : "notifications",
    data() {
        return {
            notifications : null,
            loading : true,
            unread : 0,
            total : 0,
            showing : {},
            init : true
        }
    },
    created(){
        this.$poll.register({
            name : "updateNotification",
            get : () => ({
                total : this.total
            }),
            set : (data) => {
                let new_coming = 0;
                if (this.init) {
                    this.init = false;
                } else {
                    new_coming = data.unread - this.unread
                }
                this.total = data.total
                this.unread = data.unread
                if (new_coming) {
                    this.getNewComing(new_coming)
                }
            }
        });
    },
    beforeDestroy(){
        this.$poll.remove("updateNotification");
    },
    methods : {
        open(){
            if (this.unread != 0 || this.notifications == null){
                this.notifications = [];
                this.getNotifications();
            }
        },
        click(e,notification){
            if (notification.href){
                return;
            }
            e.preventDefault();
            this.show(notification)
        },

        show(notification){
            if (this.showing[notification.id]) return
            $.gritter.add({
                title : notification.title,
                text : notification.text,
                after_close: ()=>{
                    this.showing[notification.id] = false;
                }
            });
            this.showing[notification.id] = true;
        },
        async getNewComing(len){
            let notifications = (await customPost(`/ajax/functions_notification.php`,{
                limit : len,
                update : 0
            })).notifications
            _.each(notifications,notification=>this.show(notification))
        },
        async getNotifications(){
            this.loading = true;
            this.prevLen = this.notifications ? this.notifications.length : 0;
            let notifications = (await customPost(`/ajax/functions_notification.php`,{
                limit : this.prevLen + 10
            })).notifications;
            await timeout(500);
            this.notifications = notifications;
            this.loading = false;
            this.unread = 0;
        },
        beforeEnter: function (el) {
            if(el.dataset.index){
                el.style.opacity = 0;
                el.style.height = 0;
            }
        },
        enter: function (el, done) {
            let height;
            if(!el.dataset.index){
                done(el);
                return;
            } 
            let delay = (el.dataset.index - this.prevLen ) * 150;
            setTimeout(function () {
                jQuery.Velocity(
                    el,
                    { opacity: 1, height: '72' },
                    { complete: done }
                )
            }, delay);
        },
        afterEnter : function(el){
            if (el.dataset.name == "loading"){
                let list = this.$el.querySelector("ul");
                list.scrollTop = list.scrollHeight;
            }
        }
    }
}
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "sidebar sidebar-right", attrs: { id: "sidebar-right" } },
      [
        _c("vue-custom-scrollbar", { staticClass: "height-full" }, [
          _c("ul", { staticClass: "nav" }, [
            _c("li", { staticClass: "nav-widget p-0" }, [
              _c("p", { staticClass: "text-white p-15 m-0" }, [
                _vm._v("Accordion")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "accordion", attrs: { id: "accordion" } },
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "bg-dark text-white",
                      attrs: { "no-body": "" }
                    },
                    [
                      _c(
                        "b-card-header",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion1",
                              modifiers: { accordion1: true }
                            }
                          ],
                          staticClass:
                            "card-header bg-dark-darker b-0 text-white pointer-cursor d-flex align-items-center",
                          attrs: { "header-tag": "header" }
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\tAccordion #1\n\t\t\t\t\t\t\t\t"
                          ),
                          _c("i", {
                            staticClass:
                              "fa fa-plus-circle text-blue ml-auto mr-0"
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "accordion1",
                            visible: "",
                            accordion: "my-accordion"
                          }
                        },
                        [
                          _c("b-card-body", [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t\tAnim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.\n\t\t\t\t\t\t\t\t"
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-card",
                    {
                      staticClass: "bg-dark text-white",
                      attrs: { "no-body": "" }
                    },
                    [
                      _c(
                        "b-card-header",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion2",
                              modifiers: { accordion2: true }
                            }
                          ],
                          staticClass:
                            "card-header bg-dark-darker b-0 text-white pointer-cursor d-flex align-items-center",
                          attrs: { "header-tag": "header" }
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\tAccordion #2\n\t\t\t\t\t\t\t\t"
                          ),
                          _c("i", {
                            staticClass:
                              "fa fa-plus-circle text-purple ml-auto mr-0"
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: { id: "accordion2", accordion: "my-accordion" }
                        },
                        [
                          _c("b-card-body", [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t\tAnim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.\n\t\t\t\t\t\t\t\t"
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-card",
                    {
                      staticClass: "bg-dark text-white",
                      attrs: { "no-body": "" }
                    },
                    [
                      _c(
                        "b-card-header",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion3",
                              modifiers: { accordion3: true }
                            }
                          ],
                          staticClass:
                            "card-header bg-dark-darker b-0 text-white pointer-cursor d-flex align-items-center",
                          attrs: { "header-tag": "header" }
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\tAccordion #3\n\t\t\t\t\t\t\t\t"
                          ),
                          _c("i", {
                            staticClass:
                              "fa fa-plus-circle text-teal ml-auto mr-0"
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: { id: "accordion3", accordion: "my-accordion" }
                        },
                        [
                          _c("b-card-body", [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t\tAnim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.\n\t\t\t\t\t\t\t\t"
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-card",
                    {
                      staticClass: "bg-dark text-white",
                      attrs: { "no-body": "" }
                    },
                    [
                      _c(
                        "b-card-header",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion4",
                              modifiers: { accordion4: true }
                            }
                          ],
                          staticClass:
                            "card-header bg-dark-darker b-0 text-white pointer-cursor d-flex align-items-center",
                          attrs: { "header-tag": "header" }
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\tAccordion #4\n\t\t\t\t\t\t\t\t"
                          ),
                          _c("i", {
                            staticClass:
                              "fa fa-plus-circle text-info ml-auto mr-0"
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: { id: "accordion4", accordion: "my-accordion" }
                        },
                        [
                          _c("b-card-body", [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t\tAnim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.\n\t\t\t\t\t\t\t\t"
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-card",
                    {
                      staticClass: "bg-dark text-white",
                      attrs: { "no-body": "" }
                    },
                    [
                      _c(
                        "b-card-header",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion5",
                              modifiers: { accordion5: true }
                            }
                          ],
                          staticClass:
                            "card-header bg-dark-darker b-0 text-white pointer-cursor d-flex align-items-center",
                          attrs: { "header-tag": "header" }
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\tAccordion #5\n\t\t\t\t\t\t\t\t"
                          ),
                          _c("i", {
                            staticClass:
                              "fa fa-plus-circle text-warning ml-auto mr-0"
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: { id: "accordion5", accordion: "my-accordion" }
                        },
                        [
                          _c("b-card-body", [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t\tAnim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.\n\t\t\t\t\t\t\t\t"
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-card",
                    {
                      staticClass: "bg-dark text-white",
                      attrs: { "no-body": "" }
                    },
                    [
                      _c(
                        "b-card-header",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion6",
                              modifiers: { accordion6: true }
                            }
                          ],
                          staticClass:
                            "card-header bg-dark-darker b-0 text-white pointer-cursor d-flex align-items-center",
                          attrs: { "header-tag": "header" }
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\tAccordion #6\n\t\t\t\t\t\t\t\t"
                          ),
                          _c("i", {
                            staticClass:
                              "fa fa-plus-circle text-danger ml-auto mr-0"
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: { id: "accordion6", accordion: "my-accordion" }
                        },
                        [
                          _c("b-card-body", [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t\tAnim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.\n\t\t\t\t\t\t\t\t"
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-card",
                    {
                      staticClass: "bg-dark text-white",
                      attrs: { "no-body": "" }
                    },
                    [
                      _c(
                        "b-card-header",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion7",
                              modifiers: { accordion7: true }
                            }
                          ],
                          staticClass:
                            "card-header bg-dark-darker b-0 text-white pointer-cursor d-flex align-items-center",
                          attrs: { "header-tag": "header" }
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\tAccordion #7\n\t\t\t\t\t\t\t\t"
                          ),
                          _c("i", {
                            staticClass:
                              "fa fa-plus-circle text-muted ml-auto mr-0"
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: { id: "accordion7", accordion: "my-accordion" }
                        },
                        [
                          _c("b-card-body", [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t\tAnim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.\n\t\t\t\t\t\t\t\t"
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "sidebar-bg sidebar-right" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <li class="dropdown ">
        <a href="javascript:;" data-toggle="dropdown" class="f-s-14">
            <i class="fas fa-shopping-cart"></i>
            <span id='shoppingcartcount' class="label">{{quantity || ''}}</span>
        </a>
        <ul id='myshoppingcart' class="dropdown-menu media-list dropdown-menu-right">
            <li class='dropdown-header' style='margin-bottom: 0px;'>SHOPPING CART ITEMS ({{ quantity }})</li>
            <li class='dropdown-footer text-center subtotal-li'>
                <div class='row'>
                    <div class='col'>
                        <span class='subtotal-span'>Subtotal:</span>
                    </div>
                    <div class='col'>
                        <span class='subtotal-span float-right'>$ {{ toFixed(cart.subtotal || 0, 2) }} </span>
                    </div>
                </div>
                <button class='btn btn-primary btn-sm btn-block' @click="() => $router.push({name: 'shopping-cart'})">View Cart</button>
                <button v-if="quantity" class='btn btn-warning btn-sm btn-block' @click="emptyCart">Empty Cart</button>
            </li>
            <li class='media' v-for="item in cart.items" :key="item.id">
                <router-link :to="{name: 'course-details',  params: {id: item.course_id}}">
                    <div class='media-left'>
                        <div v-if="item.img"
                                v-bind:style="{
                                    width: '48px', 
                                    height: '48px',
                                    'border-radius': '6px',
                                    'background-image': `url('${assets_path}/a-assets/img/courses${item.img}')`,
                                    'background-size': 'cover',
                                    'background-repeat': 'no-repeat', 
                                    'background-position': '50% 50%', }"
                        ></div>
                        <div v-else class='text-center bg-silver-darker' style='
                            width: 50px;
                            height: 50px;
                            font-size: 2.0rem;
                            border-radius: 5px;'
                        >
                            <i class='fa fa-book bg-silver-darker'></i>
                        </div>
                    </div>
                    <div class='media-body'>
                        <h6 class='media-heading'>{{item.course_name}}</h6>
                        <p>Quantity: <b>{{item.quantity}}</b></p>
                        <div class='text-muted f-s-11'>@ ${{item.cost}} {{item.c_label}}</div>
                    </div>
                </router-link>
                <i class='fas fa-times-circle fa-lg cancel-icon' aria-hidden='true' @click='removeFromCart(item.cart_id, null, item.extra)'></i>
            </li>
        </ul>
    </li>
</template>
<script>
import cartMixin from '../../scripts/cartMixin'

export default {
    props: {
        cart: Object
    }, 
    data() {
        return {
			assets_path: process.env.VUE_APP_ASSETS_PATH
        }
    }, 
    methods: {
        toFixed(n, i) {
            return Number(n).toFixed(i);
        }
    }, 
    mixins: [cartMixin], 
    computed: {
        quantity() {
            // quantity is the total of the quantity of all items
            return this.cart.items.reduce((a,e) => (parseInt(a))+parseInt(e.quantity), 0);    
        }
    }
}
</script>

<template>
    <v-form  @submit="onSubmit">
        <template slot-scope="{edit}">
            <label>Password <span class="text-danger">*</span></label>
            <v-input name="password" class="m-b-20" type="password-confirm" v-model="edit.psw"></v-input>
            <div class="login-buttons">
                <button type="submit" class="btn btn-success btn-block btn-lg" :disabled="submited"><i v-show="submited" class="far fa-spinner fa-spin mr-3"></i>Submit</button>
            </div>
        </template>
    </v-form>
</template>

<script>
import loginMixin from './loginMixin'
import { customPost } from '../../scripts/custom-all'
    export default {
        name: "reset-form",
        mixins: [loginMixin],
        data(){
            return {
                submited : false,
                reset_psw  : new URL(window.location).searchParams.get('reset_psw')
            };
        },
        methods : {
            async onSubmit(data){
                this.submited = true
                try {
                    await customPost(`${ process.env.VUE_APP_AJAX_PATH}/ajax/functions_ajax_login.php`,{
                        action : "changePsw",
                        psw : data.psw,
                        code : this.reset_psw
                    });
                    this.$store.commit('auth/login', "guest");
                } catch (e){
                    this.submited = false;
                }
            }
        },
    };
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-form", {
        on: { submit: _vm.onSubmit },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var edit = ref.edit
              return [
                _c("v-input", {
                  staticClass: "m-b-20",
                  attrs: {
                    type: "email",
                    default: _vm.email,
                    placeholder: "Email Address",
                    required: "",
                    large: "",
                    readonly: _vm.readonly
                  },
                  model: {
                    value: edit.email,
                    callback: function($$v) {
                      _vm.$set(edit, "email", $$v)
                    },
                    expression: "edit.email"
                  }
                }),
                _vm._v(" "),
                _c("v-input", {
                  staticClass: "m-b-20",
                  attrs: {
                    type: "password",
                    placeholder: "Password",
                    required: "",
                    large: ""
                  },
                  model: {
                    value: edit.password,
                    callback: function($$v) {
                      _vm.$set(edit, "password", $$v)
                    },
                    expression: "edit.password"
                  }
                }),
                _vm._v(" "),
                _c(
                  "v-input",
                  {
                    staticClass: "m-b-20",
                    attrs: { type: "checkbox", inline: "" },
                    model: {
                      value: edit.remember_me,
                      callback: function($$v) {
                        _vm.$set(edit, "remember_me", $$v)
                      },
                      expression: "edit.remember_me"
                    }
                  },
                  [_vm._v("Remember Me")]
                ),
                _vm._v(" "),
                _vm.features.secondary
                  ? _c(
                      "v-input",
                      {
                        staticClass: "m-b-20",
                        attrs: { type: "checkbox", inline: "" },
                        model: {
                          value: edit.secondary,
                          callback: function($$v) {
                            _vm.$set(edit, "secondary", $$v)
                          },
                          expression: "edit.secondary"
                        }
                      },
                      [_vm._v("Secondary Company")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.remain,
                        expression: "remain"
                      }
                    ],
                    staticClass: "note note-danger",
                    class: _vm.errorClass
                  },
                  [
                    _c("div", { staticClass: "note-content" }, [
                      _c("h4", [
                        _c("b", [_vm._v("Incorrect username or passsword")])
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "10 invalid attempts within 30 minutes will lock you out for 2 hours."
                        ),
                        _c("br"),
                        _c("b", [
                          _vm._v(_vm._s(_vm.remain) + " attempts remaining.")
                        ])
                      ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "login-buttons" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success btn-block btn-lg",
                      attrs: { type: "submit" }
                    },
                    [_vm._v("Sign In")]
                  )
                ]),
                _vm._v(" "),
                _vm.ajaxLogin
                  ? _c("div", { staticClass: "m-t-10" }, [
                      _vm._v(
                        "\n                Change to another account? Click "
                      ),
                      _c("a", { attrs: { href: "/logout.php" } }, [
                        _vm._v("here")
                      ]),
                      _vm._v(" to login.\n            ")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.features.register
                  ? _c("div", { staticClass: "m-t-10" }, [
                      _vm._v("\n                Not a member yet? Click "),
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:;" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.$emit("update:state", "register")
                            }
                          }
                        },
                        [_vm._v("here")]
                      ),
                      _vm._v(" to register.\n            ")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "m-t-10" }, [
                  _vm._v("\n                Forgot Password? Click "),
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:;" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("update:state", "recover")
                        }
                      }
                    },
                    [_vm._v("here")]
                  ),
                  _vm._v(" to reset.\n            ")
                ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row mt-2" }, [
    _c("div", { staticClass: "col-3" }, [
      _c("label", [_vm._v(_vm._s(_vm.label))])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col" },
      [_vm._t("default", [_vm._v(_vm._s(_vm.value))])],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
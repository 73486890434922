var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user
    ? _c("ul", { staticClass: "nav" }, [
        _c("li", { staticClass: "nav-profile navbar-user" }, [
          _c(
            "a",
            {
              attrs: { href: "javascript:;" },
              on: {
                click: function($event) {
                  return _vm.expand()
                }
              }
            },
            [
              _c("div", {
                staticClass: "cover with-shadow",
                style: { "background-image": "url(" + _vm.user.bg_img + ")" }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "image image-icon " }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: {
                    src: _vm.user.img ? "" + _vm.user.img : "",
                    alt: "Profile Picture"
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "info" }, [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.user.fname) +
                    " " +
                    _vm._s(_vm.user.lname) +
                    "\n\t\t\t\t"
                ),
                _c("small", [_vm._v(_vm._s(_vm.user.position))])
              ])
            ]
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
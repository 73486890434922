
<template >
    <v-form-modal 
        ref = "vfm"
    >
        <template slot-scope="{edit}">
            <template v-for="(value,index) in edit.values">
            <v-input
                :key="index"
                v-model = "edit.values[index]"
                :autofocus = "index == 0"
                v-bind = "configs[index]"
            ></v-input>
            </template>
        </template>
    </v-form-modal>
</template>

<script>
import _ from 'lodash';
import { VFormModal, VInput } from '.';
export default {
    components: {VFormModal, VInput}, 
    data : function(){
        return {
            configs : null
        };
    },
    methods : {
        async open(configs,submit=null){
            if (_.isObject(configs)){
                configs = [configs];
            }
            this.configs = [];
            let values = [];
            _.each(configs,c=>{
                this.configs.push(_.omit(c,"value"));
                values.push(c.value);
            });
            await this.$nextTick();
            values =  (await this.$refs.vfm.open({values})).values;
            
            let out;
            if (_.isObject(configs)){
                out = values[0];
            } else {
                out = values;
            }
            if (submit) submit(out);
            return out;
        }
    },
}
</script>

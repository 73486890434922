var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-form", {
    on: { submit: _vm.onSubmit },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var edit = ref.edit
          return [
            _c("label", [
              _vm._v("Password "),
              _c("span", { staticClass: "text-danger" }, [_vm._v("*")])
            ]),
            _vm._v(" "),
            _c("v-input", {
              staticClass: "m-b-20",
              attrs: { name: "password", type: "password-confirm" },
              model: {
                value: edit.psw,
                callback: function($$v) {
                  _vm.$set(edit, "psw", $$v)
                },
                expression: "edit.psw"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "login-buttons" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success btn-block btn-lg",
                  attrs: { type: "submit", disabled: _vm.submited }
                },
                [
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.submited,
                        expression: "submited"
                      }
                    ],
                    staticClass: "far fa-spinner fa-spin mr-3"
                  }),
                  _vm._v("Submit")
                ]
              )
            ])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export default [
  /* TIME SHEETS */
  {
    path: '/timesheet/timesheet/:id',
    name: 'view-timesheet',
    meta: {
      title: 'View Time Sheet', 
      viewerLevel: 1, 
      instructor: true
    }, 
    component: () => import(/* webpackChunkName: "view-time-sheet" */"../views/TimeSheets/ViewTimeSheet.vue"),
    props: (route) => ({eid: route.params.id})
  },
  {
    path: '/timesheet/timeoff/:id?',
    alias: '/timesheet/timeoff.php/:id?',
    name: 'view-timeoff',
    meta: {
      title: 'View Time Off Request', 
      viewerLevel: 1, 
      instructor: true
    }, 
    component: () => import(/* webpackChunkName: "view-time-off" */"../views/TimeSheets/TimeOffRequest.vue"),
    props: true
  },
  {
    path: '/timesheet/timesheet-settings',
    name: 'timesheet-settings',
    meta: {
      title: 'Time Sheet Settings', 
      viewerLevel: 8
    }, 
    component: () => import(/* webpackChunkName: "time-sheet-settings" */"../views/TimeSheets/TimeSheetSettings.vue"),
  },
  {
    path: '/timesheet/accounting-timesheets',
    name: 'timesheet-accounting',
    meta: {
      title: 'Time Sheet Accounting Settings', 
      viewerLevel: 8
    }, 
    component: () => import(/* webpackChunkName: "time-sheet-accounting" */"../views/TimeSheets/Accounting.vue"),
  },
  {
    path: '/timesheet/supervisor-dashboard',
    name: 'timesheet-dashboard',
    meta: {
      title: "Supervisor Dashboard",
      viewerLevel: 4,
      breadcrumbs: ['Timesheets', 'Supervisor Dashboard']
    }, 
    component: () => import(/* webpackChunkName: "time-sheet-dashboard" */"../views/TimeSheets/ReviewTimeSheets.vue")
  }, 
  {
    path: '/timesheet/my-timesheets',
    name: 'my-timeshets',
    meta: {
      title: "My Time Sheets",
      viewerLevel: 1, 
      instructor: true
    }, 
    component: () => import(/* webpackChunkName: "my-time-sheets" */"../views/TimeSheets/MyTimeSheets.vue")
  }, 
]
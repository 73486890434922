// hello
import Vue from 'vue'
import $ from 'jquery';
import _ from 'lodash';
import store from '../store'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue';
import accounting from './accounting'

import abcs from './abcs';
import cart from './cart'
import compentencies from './competencies'
import classRoutes from './class'
import company from './company'
import instructor from './instructor'
import proctoring from './proctoring'
import receipts from './receipts'
import students from './students'
import scheduling from './scheduling'
import tickets from './tickets'
import timesheets from './timesheets'
// import D from '../pages/Home.vue';
Vue.use(VueRouter)
// const breadcrumbs = {
//   props: { breadcrumbs: Array },
//   template: `<ol class="breadcrumb float-xl-right">
//     <li v-for="(breadcrumb,i) in breadcrumbs" :key="i" :class="{'breadcrumb-item': true, 'active' : i == 0}">
//       <router-link v-if="typeof breadcrumb == 'object' && breadcrumb.link" :to="breadcrumb.link">
//         {{breadcrumb.text}}
//       </router-link>
//       <span v-else>{{breadcrumb}}</span>
//     </li>
//   </ol>`
// }
// const header = {
//   props: {title: String, subtitle: String},
//   template: `<!-- begin page-header -->
//   <h1 class="page-header">{{title}} <small>{{subtitle}}</small></h1>
//   <!-- end page-header -->`
// }

const routes = [
  ...abcs,
  ...accounting,
  ...cart, 
  ...compentencies,
  ...classRoutes, 
  ...company,
  ...instructor,
  ...proctoring,
  ...receipts,
  ...scheduling,
  ...students,
  ...tickets,
  ...timesheets,
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      title: "Dashboard", 
    viewerLevel: 1,
      breadcrumbs: ['Dashboard']
    },
  },
  {
    path: '/:category/search',
    name: 'search',
    meta: {
      title: (route) =>  `${(_.upperFirst(route.params.category))} Search`
    }, 
    component: () => import(/* webpackChunkName: "search" */ '../views/Search/SearchPage.vue'),
    props: true
  },
  {
    path: '/certificates/certificates',
    name: 'Certificates',
    meta: {
      title: 'My Certificates',
      viewerLevel: 1,    
      breadcrumbs: ['My Certificates'], 
    }, 
    component: () => import(/* webpackChunkName: "certiifcate" */ '../views/Certficates/Certificates.vue')
  },
  {
    path: '/certificates/review/:id',
    name: 'review-cert',
    meta: {
      title: 'Review Certificates',
      viewerLevel: 4,    
      breadcrumbs: ['Certificates'], 
    }, 
    component: () => import(/* webpackChunkName: "certiifcate" */ '../views/Certficates/CertReviewer.vue'), 
    props: true
  },
  {
    path: '/equipment',
    name: 'equipment',
    meta: {
      title: 'Equipment Search',
      viewerLevel: 4
    }, 
    component: () => import(/* webpackChunkName: "equipment" */ '../views/Equipment/Equipment.vue')
  },
  {
    path: '/courses/course_catalog',
    name: 'catalog',
    meta: {
      title: "Course Catalogue",
      guest: true,
      viewerLevel: 0,
      breadcrumbs: ['Course Catalogue'],
    },
    component: () => import(/* webpackChunkName: "catalog" */ '../views/Courses/CourseCatalog.vue')
  },
  {
    path: '/courses/view_course/:shortcut?', 
    name: 'course-details',
    meta: {
      title: (_, t) => t, 
      guest: true,
      breadcrumbs: [{name: 'Course Catalog', to: '/courses/course_catalog'}],
    }, 
    component: () => import(/* webpackChunkName: "courses-calendar" */ '../views/Courses/CourseDetail.vue'),
    props: route => ({
        shortcut: route.params.shortcut,
        id: route.query.id
    })
  }, 
  {
    path: '/courses/mycourses(.php)?',
    name: 'mycourses',
    meta: {
      title: "My Courses",
      viewerLevel: 1,
      breadcrumbs: ['My Courses'],    
    },
    component: () => import(/* webpackChunkName: "mycourses" */ '../views/Courses/MyCourses.vue')
  },
  {
    path: '/scheduling/bookings',
    name: 'assign',
    meta: {
      title: "Assign Online Courses", 
      viewerLevel: 3,
    },
    component: () => import(/* webpackChunkName: "assign" */ '../views/Courses/AssignCourses.vue')
  },
  /** classrooms */
  {
    path: '/courses/classroom/:id?',
    name: 'classroom',
    meta: {
      title: 'Classroom', 
      viewerLevel: 1,    
    },
    component: () => import(/* webpackChunkName: "soier-classroom" */ '../views/Classroom/Classroom.vue'),
    props: true
  },
  {
    path: '/courses/result/:id',
    name: 'classroom-result',
    meta: {
      title: 'Your Results',
      viewerLevel: 1,
    },
    component: () => import(/* webpackChunkName: "clasroom-result" */'../views/Classroom/ClassroomResult.vue'),
    props: true
  },
  {
    path: '/courses/section-preview/:id',
    name: 'section-preview',
    meta: {
      title: 'Section Preview',
      viewerLevel: 8,
    },
    component: () => import(/* webpackChunkName: "section-clasroom-result" */'../views/Courses/SectionPreview.vue'),
    props: true
  },
  {
    
    path: '/reports/failed_payment_details/:id?',
    name: 'failed-detail',
    meta: {
      title: "Failed Payments Detail", 
      viewerLevel: 10,
    },
    component: () => import(/* webpackChunkName: "failed-payment-detail" */ '../views/Payments/FailedPaymentDetails.vue'),
    props: true
  },
  /* MEMOS AND GROUPS  */
  {
    path: '/company/memos',
    name: 'memos',
    meta: {
      title: "Memos",
      viewerLevel: 6,
      breadcrumbs: ['Memos'], 
    },
    component: () => import(/* webpackChunkName: "memos" */ '../views/Memos/MemoTable.vue'),
  },
  {
    path: '/users/groups',
    name: 'group-management',
    meta: {
      title: "Groups",
      breadcrumbs: ['Memos'], 
    },
    component: () => import(/* webpackChunkName: "memos" */ '../views/Memos/GroupsTable.vue'),
  },
  {
    path: '/memos/mine',
    name: 'my-memos',
    meta: {
      title: "My Memos",
      viewerLevel: 1
    },
    component: () => import(/* webpackChunkName: "my-memos" */ '../views/Memos/MyMemos.vue'),
  },
  {
    path: '/calendars/:id',
    name: 'custom-calendars',
    meta: {
      title: "Customer Calendars", 
      viewerLevel: 4,
      breadcrumbs: ['Custom Calendars']
    }, 
    component: () => import(/* webpackChunkName: "test" */"../views/Calendars/ShortcutResolver.vue")
  },
  /* DASHBOARD */
  {
    path: '/dashboard/settings',
    name: 'dashboard-settings',
    meta: {
      title: "Dashboard Settings",
      viewerLevel: 4,
      breadcrumbs: ['Dashboard Settings']
    }, 
    component: () => import(/* webpackChunkName: "dashboard-settings" */ '../views/Dashboard/Settings.vue'),
  },
  /* USER PROFILES */
  {
    path: '/users/profile/:id?',
    name: 'user-profile',
    meta: {
      title: 'User Profile',
      viewerLevel: 1
    }, 
    component: () => import(/* webpackChunkName: "uesr-profile" */ '../views/Users/Profile.vue'),
    props: true
  },
  /* REPORTS */
  {
    path: '/reports/transaction-details/:id',
    name: 'transaction-details',
    meta: {
      title: "Transaction Details",
      breadcrumbs: [{name: 'Transaction Search', to: '/transaction/search'}, 'Transaction Details']
    },
    component: () => import(/* webpackChunkName: "transaction-details" */"../views/Accounting/TransactionDetails.vue"),
    props: route => ({id: route.params.id})
  },
  /* CLASSROOM */
  {
    path: '/classes/view/:id',
    name: 'view-class',
    meta: {
      title: 'Class Details',
      breadcrumbs: ['Classes', {name: 'Class Search', to: '/class/search'}, 'View Class']
    },
    component: () => import(/* webpackChunkName: "view-class" */"../views/Classes/ViewClass.vue"),
    props: (route) => ({pid: route.params.id})
  },
  /** Public Profiles  */
  {
    path: '/profile/public/:id',
    name: 'public-profile',
    meta: {
      title: 'Public Profile',
      guest: true
    }, 
    component: () => import(/* webpackChunkName: "public-profile"*/"../views/Users/PublicProfile.vue"),
    props: true
  },
  /** Student Records  */
  {
    path: '/students/record/:id',
    name: 'student-record',
    meta: {
      title: 'Student Record',
      viewerLevel: 1
    }, 
    component: () => import(/* webpackChunkName: "student-record" */"../views/Records/StudentRecord.vue"),
    props: true
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: "Login Page", 
    },
    component: () => import(/* webpackChunkName: "login" */"../views/Auth/Login.vue")
  },
  {
    path: '/*', 
    name: '404', 
    meta: {
      title: 'Not Found'
    }, 
    component: () => import(/* webpackChunkName: "404" */"../views/Meta/404.vue")
  }

]

// let defaults = (route) => ({
//   breadcrumbs: {
//     breadcrumbs: route.breadcrumbs || [{text: 'Dashboard', link: '/'}, route.title || route.name] 
//   },
//   header: {
//     title: route.title || route.name, 
//     subtitle: route.subtitle || null
//   }
// })

// const fixedRoutes = routes.map(route => ({
//   ...route, 
//   name: route.name,
//   path: route.path,
// }));

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const allModalClosed = function(){
  let showModals = $('.modal.show') || []

  if (!showModals.length){
    return
  } 

  return Promise.all(_.map(showModals,m=>new Promise(resolve=>{
    $(m).on("hidden.bs.modal",resolve).modal("hide")
  })))
}
import pageOptions from '../config/PageOptions';
router.beforeEach(async (to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title && !(r.meta.title instanceof Function));
  try {
    await allModalClosed()
  } catch(c) {
    // all modals not closed, or none fond
  } 
  
  
  // // If a route with a title was found, set the document (page) title to that value.
  document.title = (nearestWithTitle && !(nearestWithTitle.meta.title instanceof Function) && `${nearestWithTitle.meta.title} | ${store.state.auth.company_name} | Learning Centre`) || "Learning Centre";
  // // Vue.prototype.$vstore.title = to.meta.title instanceof Function ? to.meta.title(to).split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : to.meta.title
  Vue.prototype.$vstore.subtitle = ''; // clear

  Vue.prototype.$vstore.breadcrumbs = [{name: "Home", to: "/"}, ...(to.meta.breadcrumbs || (typeof (to.matched[0]?.meta?.title) == "string") ?  [to.matched[0]?.meta?.title] : to.matched[0]?.path.toString().split('/').slice(1).map(e => e.split(/[-_]/).map(f => f.charAt(0).toUpperCase() + f.substr(1).toLowerCase()).join(' ')).filter(e => e.charAt(0) != ':')) || []]  

  // // remove sidebar for guest
  // // if (to.meta.guest && store.state.auth.loginState == "guest") {
  // //   pageOptions.pageWithoutSidebar = true
  // // } else {
  // //   pageOptions.pageWithoutSidebar = false;
  // // }
  
  if (!to.meta.guest && store.state.auth.loginState == "guest" && to.name != 'login' && from.name != 'login') {
    Vue.prototype.$vstore.redir = to.path;
    next({name: 'login'})
  } else if (to.path == '/login' && store.state.auth.loginState == "member") {
    // user is already logged in
    next({name : 'dashboard'})
  } else if (to.name != 'dashboard' && to.meta && to.meta.viewerLevel && to.meta.viewerLevel > store.state.auth.viewerLevel) {
    // next({name : 'dashboard'})
  } else {
    if (store.state.auth.loginState == 'guest') {
      pageOptions.pageWithoutSidebar = true;
    }
    next();
  }
});

export default router

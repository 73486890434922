<template>
    <table ref="table" class="table table-hover width-full">
        <thead>
            <tr>
                <th v-if="responsive"></th>
                <slot name="head"></slot>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(row,index) in rows" :key="index">
                <td v-if="responsive"></td>
                <slot name="row" :row="row" :index="index"></slot>
            </tr>
        </tbody>
    </table>
</template>

<script>
import $ from 'jquery'
import _ from 'lodash'
import Swal from 'sweetalert2'
export default {
    name: 'data-table',
    props : {
        rows : {
            type : [Array , Object],
            required : true
        },
        responsive : {
            type : Boolean,
            default : true
        },
        static : {
            type : Boolean,
            default : false 
        }
    },
    watch :{
        rows : {
            handler(){
                if ( ! this.static ) this.initTable();
            },
            deep : true
        }
    },
    methods : {
        initTable(){
            if (this.table){
                this.table.destroy();
                this.table = null;
                this.$nextTick(this.initTable);
                return;
            }
            let buttons = [];
            _.each(this.$slots.buttons,(child)=>{
                if (child.tag == "button"){
                    buttons.unshift({
                        text : child.children[0].text,
                        className : child.data.staticClass,
                        action : child.data.on.click
                    });
                }
            });

            let tableConfig = {
                language: { search: "", searchPlaceholder: "Search", "info":"Showing _START_ to _END_ of _TOTAL_", },
                columnDefs: [{
                    className: 'control',
                    orderable: false,
                    targets: 0
                }],
                order: [1, 'asc'],
                dom: 'Blfrtip',
                lengthMenu: [
                    [20, 40, 80, -1],
                    [20, 40, 80, "All"]
                ],
                buttons,
                colReorder: true,
                fixedHeader: true
            };

            if (this.responsive){
                tableConfig.responsive = {
                    details: {
                        type: 'column'
                    }
                };
            }
            this.table = $(this.$refs.table).DataTable(tableConfig);
            
        }
    },
    mounted(){
        // notice Jason to change version
        if(this.$slots.body) Swal.fire("Old Version","This page is currently using old version of data-table component, please ask Dylan to fix this page","warning");
        this.initTable();
    },
    beforeDestroy(){
        this.table.destroy();
    },
};
</script>
import VForm from './VForm.vue';
import VFormModal from './VFormModal.vue';
import VInput from './VInput.vue';
import StarRating from './StarRating.vue';
import CheckBox from './CheckBox';
import IconSelect from './IconSelect';
import VCredentialCard from './VCredentialCard';
import VItem from './VItem';
import VCalendarPicker from './VCalendarPicker';
import VInfo from './VInfo';
import EditorManager from './EditorManager';
let Ckeditor = () => import('./Ckeditor');
export {
    EditorManager,
    Ckeditor, 
    VInfo,
    VCalendarPicker,
    VItem,
    VForm,
    VFormModal,
    VInput,
    StarRating,
    VCredentialCard,
    IconSelect,
    CheckBox,
}
<template>
    <div class="panel panel-inverse v-menu" data-sortable="false">
        <div class="panel-heading cursor-reg">
            <div class="panel-heading-btn">
                <slot name="title-btns"></slot>
            </div>
            <h4 class="panel-title noselect">{{title}}</h4>
        </div>
        <ul>
            <li v-for="{id:gid,title:gt,items} in groups" :key="gid">
                <div class="title title-group" :class="{active : gid == selected[0],opened : gid == opened}" @click="opened = gid">
                    <span class="noselect">
                        <b class="menu-caret mr-3"></b>
                        {{gt}}
                    </span>
                    <span  class="badge pull-right">{{_.size(items)}}</span>
                    <div class="panel-heading-btn">
                    <slot name="group-btns" :id="gid"></slot>
                    </div>
                </div>
                <ul is="transition-group" v-collapse="gid == opened">
                    <li v-for="{id : iid,title : it,icon} in items" :key="iid" >
                        <div class="title title-item" @click="$emit('click',iid,gid)" :class="{active : iid == selected[1]}">
                            <span class="ml-3 noselect">
                                <i v-if="icon" class="mr-1 icon" :class="icons[icon]"></i>
                                {{it}}
                            </span>
                            <div class="panel-heading-btn">
                                <slot name="item-btns" :id="iid" :pid="gid"></slot>
                            </div>
                        </div>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: "v-menu",
    props : {
        value : [String, Number],
        selected :{
            type :  Array, // [group_id, item_id]
            default : ()=>[]
        },
        title : {
            type : String,
            default : "Course Modules & Sections"
        },
        groups : Array, //[{id : String, title : String, items : [{id : String, title : String, icon : Number|String}]}]
    },
    data(){
        return {
            icons : {
                "1" : "fas fa-video",
                "2" : "fas fa-pen",
                "3" : "fas fa-arrows-alt-h",
                "4" : "fas fa-code",
                "5" : "fas fa-book-open",
                "7" : "fab fa-youtube",
                "8" : "fas fa-image"
            }
        }
    },
    computed : {
        opened : {
            get(){
                return this.value;
            },
            set(val){
                if (val == this.opened) {
                    val = null;
                }
                this.$emit("input",val);
            }
        }
    },
};

</script>

<style>
    .v-menu ul {
        padding-left: 0px;
        list-style: none;
        background-color : #2d353c;
    }

    .v-menu .title {
        background: #2d353c;
        padding: 15px;
        color: #a8acb1;
        border-bottom: 1px solid #383f46;
        cursor : pointer;
    }

    .v-menu .title:hover{
        background : #232a2f;
    }

    .v-menu .title.opened,.v-menu .title.active{
        color : white;
    } 

    .v-menu .title-group.active{
        background: #ff5b57 ;
    }

    .v-menu .title-item.active i.icon{
        color: #ff5b57;
    }

    .v-menu .menu-caret:before{
        content: '\f054';
        display: inline-block;
        text-align: center;
        transition: all .2s linear;
        font-family: Font Awesome\ 5 Free,Font Awesome\ 5 Pro,FontAwesome!important;
        font-weight: 900;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
    }

    .v-menu .menu-caret:before{
        opacity: .5;
        transform: rotate(0deg);
    }

    .v-menu .title.opened .menu-caret:before{
        opacity: 1 !important;
        transform: rotate(90deg) !important;
    }

    .v-menu .badge {
        margin-top: 1px;
        padding: 3px 8px;
        background: #1b1f24;
        font-weight: 600;
        font-size: 10px;
    }

    .v-menu .title-group.active .badge{
        display : none;
    }

    .v-menu i{
        color:#d9e0e7
    }
</style>
<template>
	<ul class="nav" v-if="user">
		<li class="nav-profile navbar-user" >
			<a href="javascript:;" v-on:click="expand()">
				<div class="cover with-shadow" :style="{'background-image': `url(${user.bg_img})` }"></div>
				<div class="image image-icon ">
					<img  class='user-avatar' :src="user.img ? `${user.img}` : ''" alt="Profile Picture"/>
				</div>
				<div class="info" >
					{{ user.fname }} {{ user.lname }}
					<small>{{ user.position }}</small>
				</div>
			</a>
		</li>
	</ul>
</template>

<script>
import PageOptions from '../../config/PageOptions.js'

export default {
	name: 'SidebarNavProfile',
	data() {
		return {
			stat: '',
			pageOptions: PageOptions
		}
	},
	props: {
		user: Object
	},
	methods: {
		expand: function() {
			this.stat = (this.stat == 'expand') ? 'collapse' : 'expand'
		}
  }
}
</script>

<template>
    <span class="label v-tag" :class="`label-${type}`">
        {{label}}
        <button class="btn btn-icon" v-if="closable" @click="$emit('close')"><i class="fal fa-times"></i></button>
    </span>
</template>

<script>
export default {
    props : {
        type : {
            default : "primary"
        },
        label : {
            type : String,
            required : true
        }
    },
    computed : {
        closable(){
            return this.$listeners.close && !this.$listeners.close.fns._isNoop
        }
    },
}
</script>

<style scoped>
    .v-tag {
        display: flex;
        width: max-content;
        height: fit-content;
        justify-content: center;
        border-radius: 2px;
        padding: 5px;
        align-items: center;
        margin: 0px 2px;
        min-width: max-content;
        flex-direction: row;
        font-weight: normal;
    }
    .v-tag .btn {
        width: 1em;
        height: 1em;
        line-height: 1;
        font-size: 100%;
        padding-left: 4px;
        color : inherit;
    }
</style>
import _ from 'lodash';
import Swal from 'sweetalert2';
export default {
    methods: {
        async removeFromCart(id, callback = null, remid = null) {
            let res = await Swal.fire({
                title: 'Are you sure?',
                text: "Item will be removed from your shopping cart!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#888888',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Remove',
                cancelButtonText: 'Cancel',
                reverseButtons: true, 
                focusCancel: true,
            });
            if (res.value) {
                this.$store.dispatch('cart/removeFromCart', id);
            }
        },
        async emptyCart() {
            let res = await Swal.fire({
                title: 'Are you sure?',
                text: "All items will be discarded.",
                type: 'warning',
                showCancelButton: true,
                cancelButtonColor: '#888888',
                confirmButtonColor: '#d33',
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Remove All',
                reverseButtons: true
            });
            if (res.value) {
                this.$store.dispatch('cart/emptyCart');
            }
        }
    }
}

<template>
    <div ref="modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Event Details</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body f-s-14">
                    <div class ="row">
                        <div class="col-3">
                            <Strong>Site: </Strong>
                        </div>
                        <div class="col">
                            {{event.site_name}}
                        </div>
                    </div>
                    <div class ="row mt-3">
                        <div class="col-3">
                            <Strong>Time: </Strong>
                        </div>
                        <div class="col">
                            {{event.start_datetime}} To {{event.end_datetime}}
                        </div>
                    </div>
                    <div class ="row mt-3">
                        <div class="col-3">
                            <Strong>Address: </Strong>
                        </div>
                        <div class="col">
                            <span><a :href="getMapURL(event)" target="_blank">{{event.address}}, {{event.city}}, {{event.postal_code}}</a></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
import courseButtonActionHelpers from './cabh';
import { customPost, timeout } from '../../scripts/custom-all'
import getMapURL from './getMapURL';
export default {
    name: 'event-detail',
    created(){
        courseButtonActionHelpers[this.$options.name] = this;
    },
    data(){
        return {
            window : window,
            event :{}
        };
    },
    beforeDestroy() {
        $(this.$refs.modal).modal("hide");
    }, 
    methods : {
        open(id) {
            customPost(`${ process.env.VUE_APP_AJAX_PATH}/ajax/functions_ajax_courses.php`,{
                action : "getEventDetail",
                id : id
            }).then((output)=>{
                this.event = output.result;
                $(this.$refs.modal).modal('show');
            });
        },
        getMapURL
    },
};
</script>
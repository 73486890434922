import _ from 'lodash';
import $ from 'jquery';
import Swal from 'sweetalert2';
import { customPost } from '../../scripts/custom-all'
import { addToCart } from '../../scripts/cart'
import courseButtonActionHelpers from '../wrapper/cabh';
// let mycourse = false;
let is_admin = true;
let course_types = [null, 'Online Learning', 'Blended Learning', 'Instructor Led Learning', 'Course Bundle', 'Station Booking', 'Scorm Package', 'Link', 'Blended Individual Practical'];
let courseMixin = {
    props : {
        course : {
            type : Object,
            required : true
        },
        tag : {
            type: String,
            default: "div"
        },
        mycourse: {
            type: Boolean,
            default: false
        }
    },
    created(){
        this.passStateOptions = {
            "0" : "not-start",
            "1" : "in-progress",
            "2" : "retry",
            "3" : "pending",
            "4" : "retry",
            "5" : "retry",
            "6" : "fail",
            "7" : "scheduled",
            "8" : "no-show",
            "9" : "pass",
            "10" : "pass",
            "11" : "pass"
        };     
        
        let openClassroom = ()=>{
            // goToClassroom();
            // return;

            if (this.course.passed == '4' || this.course.passed == '5') {
                customPost(`/ajax/functions_ajax_classroom.php`, {
                    action: "checkStudentCanRetryCourse",
                    id: this.course.rid,
                }).then((out) => {
                    if (out.retry_fee) {
                        this.$bus.$emit("pay-course-fee", {rid: this.course.rid, fee : out.retry_fee });
                    }
                    else
                        this.goToClassroom();
                }).catch();
            }
            else
            {
                this.goToClassroom();
                return;
            }
        };

        
        let contentCost = ()=> {
            if (this.course.cost == 0){
                return "FREE"
            }
            let showCost = (cost)=>"CA$"+_.round(cost,2).toFixed(2)
            if (this.hasDiscount){
                return  `<del class='mr-1'>${showCost(this.course.cost / ((100 - this.course.discount)/100))}</del><span>${showCost(this.course.cost)}</span> `
            } else {
                return showCost(this.course.cost)
            }
        }

        this.btnOptions = {
            "unbuy"  : {
                icon : "fa-shopping-cart",
                content : contentCost(),
                type : "btn-info",
                onclick : this.buyAction
            },
            "in-cart"  : {
                icon : "fa-shopping-cart",
                content : "Added to Cart",
                type : "btn-info",
                onclick : this.buyAction
            },
            "not-start" : {
                icon : "fa-play",
                content : "Start",
                type : "btn-warning",
                onclick : openClassroom
            },
            "in-progress" : {
                icon : "fa-play",
                content : "Resume",
                type : "btn-warning",
                onclick : openClassroom
            },
            "retry" : {
                icon : "fa-redo-alt",
                content : "Retry",
                type : "btn-warning",
                onclick : openClassroom
            },
            "pending" : {
                icon : "fa-book-reader",
                content : "Pending",
                type : "btn-warning",
                onclick : openClassroom
            },
            "fail" : {
                icon : "fa-times",
                content : "Failed",
                type : "btn-danger",
                onclick : openClassroom
            },
            "pass" : {
                icon : "fa-check",
                content : "Passed",
                type : "btn-green",
                onclick : openClassroom
            },
            "scheduled" : {
                icon : "fa-calendar-alt",
                content : "Scheduled Detail",
                type : "btn-lime",
                onclick : ()=>{courseButtonActionHelpers["event-detail"].open(this.course.event_id);}
            },
            "no-show" : {
                icon : "fa-times",
                content : "No Show",
                type : "btn-danger",
                onclick : ()=>{}
            },
            "error"  : {
                icon : "fa-times",
                content : "Wrong State " + this.course.passed,
                type : "btn-danger",
                onclick : ()=>{}
            },
        }
        this.onAddToCart = (hash)=>{
            if (this.course._id == hash) this.course.added_to_cart = 1;
        }
        this.$bus.$on("addToCart",this.onAddToCart);

        this.onRemoveFromCart = (hash)=>{
            if (this.course._id == hash) this.course.added_to_cart = 0;
        }
        this.$bus.$on("removeFromCart",this.onRemoveFromCart);

        this.onCourseBought = (hash,r)=>{
            if (this.course._id == hash) {
                this.$set(this.course,"rid",r.rid);
                this.$set(this.course,"passed",r.passed);
            }
        }
        this.$bus.$on("courseBought",this.onCourseBought);
    },
    beforeDestroy(){
        this.$bus.$off("addToCart",this.onAddToCart);
        this.$bus.$off("removeFromCart",this.onRemoveFromCart);
        this.$bus.$off("courseBought",this.onCourseBought);
    },
    computed : {
        state(){
            let state;
            if (!this.mycourse){
                state = this.buyState;
            }
            if ((this.mycourse || !is_admin) && this.course.passed != null){
                state = this.passState;
            }
            return state;
        },
        buyState(){
            if (this.course.added_to_cart == "1"){
                return "in-cart";
            } else {
                return "unbuy";
            }
        },
        passState(){
            return   _.get(this.passStateOptions,this.course.passed,"error");
        },
        hasProgressBar(){
            return this.state == "in-progress" || this.state == "pass";
        },

        hasDiscount(){
            return (this.state == "unbuy" || this.state == "in-cart") && this.course.discount > 0
        },
        btn(){
            return _.get(this.btnOptions,this.state,this.btnOptions.error);
        },

        img_url(){
            if (this.course.img) {
                return `${process.env.VUE_APP_ASSETS_PATH}/a-assets/img/courses${this.course.img}`;
            }
            return `${process.env.VUE_APP_ASSETS_PATH}/a-assets/images/no-image.jpg`;
        },
        img_style(){
            return {
                'background-image' : `url('${this.img_url}')`
            };
        },
        type(){
            return course_types[this.course.type];
        }
    },
    methods : {
        goToClassroom() {
            this.$router.push({name: 'classroom', params: {id: this.course.rid}})
            // location.href = `/courses/classroom.php?id=${encodeURIComponent(this.course.rid)}`;
        },
        async addToRecord(event_id=null){
            let result = await customPost(`${ process.env.VUE_APP_AJAX_PATH}/ajax/functions_ajax_cart.php`,{
                action : "AddToRecords",
                course : this.course.id,
                event : event_id
            });
            if (is_admin){
                await Swal.fire({
                    title: "Course Added!",
                    text: "There are students to assign to the online courses purchased, do you want to assign the students now?",
                    type: "warning",
                    reverseButtons: true, 
                    cancelButtonColor: '#d33',
                    confirmButtonColor: '#3085d6',
                    cancelButtonText: 'I will do it later',
                    confirmButtonText: 'Yes, I will do it now',
                    showCancelButton: true,
                    animation: "slide-from-top",
                }).then(({value: v}) => v && this.$router.push({name: 'assign'}))
            } else {
                _.each(result.bought,(r,hash)=>{
                    this.$bus.$emit("courseBought",hash,r);
                });
                Swal.fire("Course Added!","","success");
            }
        },
        purchase :  _.debounce(async function(event_id = null, showModal = true){
            if (this.course.cost == 0){
                this.addToRecord(event_id);
            } else {
                addToCart(this.course.id,event_id,(output)=>{
                    if (output.error){
                        let error_msg;
                        switch(String(output.error)){
                            case "full":
                                error_msg = "Cannot register into class, the class has reached full capacity.";
                                break;
                            case "TOOMANY":
                                error_msg = "Learners can only register once per course.";
                                break;
                            case "in-bundle":
                                error_msg = "This course already involved in a bundle in your cart.";
                                break;
                            default:
                                error_msg = "Cannot book course, unexpected error.";
                                break;
                        }
                        Swal.fire("Error", error_msg , "error")
                        return;
                    }

                    if (output.warning) {
                        Swal.fire({ title: "Warning", html: output.warning , type: "warning"});
                    }

                    _.each(output.added,(hash)=>{
                        this.$bus.$emit("addToCart",hash)
                    });
                    if (event_id){
						if (showModal) {
							Swal.fire("Please Note","Your seat will only be reserved once you have paid for the course","success");
						}
                    }
                });
            }
        }, 150),
    },
    render(createElement){
        return createElement(
            this.tag,
            [this.$scopedSlots.default({
                btn : this.btn,
                hasProgressBar : this.hasProgressBar,
                hasDiscount : this.hasDiscount,
                img : {
                    url : this.img_url,
                    style : this.img_style
                },
                type : this.type
            })]
        )
    }
}
export default courseMixin;
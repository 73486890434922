export default [

  /* PROSER ADMIN TOOLS */
  {
    path: '/company/company_features',
    name: 'company-features',
    meta: {
      title: "Company Features",
      viewerLevel: 10,
      breadcrumbs: ['Company', 'Company Features'],
    },
    component: () => import(/* webpackChunkName: "company-features" */"../views/Company/CompanyFeatures.vue")
  },
  {
    path: '/users/merge_users',
    name: 'merge-users',
    meta: {
      title: "Merge Users",
      viewerLevel: 10,
      breadcrumbs: [ 'Users', 'Merge Users'],
    }, 
    component: () => import(/* webpackChunkName: "merge-user" */"../views/Users/MergeUsers.vue")
  },
]
import CourseWrapper1 from './CourseWrapper1';
import CourseWrapper2 from './CourseWrapper2';
import CourseWrapper3 from './CourseWrapper3';
import CourseWrapper4 from './CourseWrapper4';
import CourseWrapper5 from './CourseWrapper5';
import CourseWrapper6 from './CourseWrapper6';
import CourseWrapper7 from './CourseWrapper7';
import CourseWrapper8 from './CourseWrapper8';
export {
    CourseWrapper1, 
    CourseWrapper2, 
    CourseWrapper3, 
    CourseWrapper4,
    CourseWrapper5, 
    CourseWrapper6, 
    CourseWrapper7,
    CourseWrapper8,
}
<script>
// blneded course
import _ from 'lodash'
import courseMixin from './courseMixin';
import courseButtonActionHelpers from './cabh'
export default {
    mixins : [courseMixin],
    created(){
        _.assign(this.passStateOptions,{
            "9" : "schedule",
            "10" : "schedule",
            "7": "scheduled"
        });
        this.btnOptions["scheduled"] = {
            icon: "fa-calendar-alt",
            content: "Scheduled Detail",
            type: "btn-lime",
            onclick : ()=>{courseButtonActionHelpers["booking-detail"].open(this.course.rid, 
                // reschedule action 
                () => {
                courseButtonActionHelpers["booking-site-picker"]
                    .open(this.course.id,null,null, this.course.rid, true)
                    .then(res => {
                        if (res.success) {
                            this.setPassed(7);
                        }
                    })
                }
            )
            
            }
        }
        this.btnOptions["schedule"] = {
            icon : "fa-calendar-alt",
            content : "Schedule",
            type : "btn-lime",
            onclick :() => {
                courseButtonActionHelpers["booking-site-picker"]
                    .open(this.course.id,null,null, this.course.rid)
                    .then(res => {
                        if (res.success) {
                            this.setPassed(7);
                        }
                    })
            }
        }
    },
    methods : {
        setPassed(n) {
            this.$parent.$parent.$parent.$data.course_list.find(e => e.rid == this.course.rid).passed = n
        },
        openModal(){
            courseButtonActionHelpers["booking-site-picker"].open(this.course.id,null, ()=>{
                this.purchase();
            });
        },
        buyAction(){
            this.openModal()
        }
    }
}
</script>
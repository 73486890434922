var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "collapse navbar-collapse",
      class: { "d-block": _vm.pageOptions.pageMobileMegaMenu }
    },
    [
      _c(
        "ul",
        { staticClass: "nav navbar-nav" },
        [
          _c(
            "b-nav-item-dropdown",
            { staticClass: "dropdown-lg" },
            [
              _c("template", { slot: "button-content" }, [
                _c("i", { staticClass: "fa fa-th-large fa-fw mr-1 mr-md-0" }),
                _vm._v(" Mega "),
                _c("b", { staticClass: "caret ml-1 ml-md-0" })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-4 col-md-4" }, [
                  _c("div", { staticClass: "f-w-700 text-inverse mb-2" }, [
                    _vm._v("UI Kits")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row m-b-20" }, [
                    _c("div", { staticClass: "col-lg-6" }, [
                      _c("ul", { staticClass: "nav d-block" }, [
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "text-ellipsis text-muted",
                              attrs: { href: "javascript:;" }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa fa-chevron-right fa-fw text-inverse"
                              }),
                              _vm._v(" FontAwesome")
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "text-ellipsis text-muted",
                              attrs: { href: "javascript:;" }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa fa-chevron-right fa-fw text-inverse"
                              }),
                              _vm._v(" Ionicons")
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "text-ellipsis text-muted",
                              attrs: { href: "javascript:;" }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa fa-chevron-right fa-fw text-inverse"
                              }),
                              _vm._v(" Simple Line Icons")
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "text-ellipsis text-muted",
                              attrs: { href: "javascript:;" }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa fa-chevron-right fa-fw text-inverse"
                              }),
                              _vm._v(" Typography")
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "text-ellipsis text-muted",
                              attrs: { href: "javascript:;" }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa fa-chevron-right fa-fw text-inverse"
                              }),
                              _vm._v(" Media Object")
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "text-ellipsis text-muted",
                              attrs: { href: "javascript:;" }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa fa-chevron-right fa-fw text-inverse"
                              }),
                              _vm._v(" Widget Boxes")
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "text-ellipsis text-muted",
                              attrs: { href: "javascript:;" }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa fa-chevron-right fa-fw text-inverse"
                              }),
                              _vm._v(" Tabs & Accordions")
                            ]
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-lg-6" }, [
                      _c("ul", { staticClass: "nav d-block" }, [
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "text-ellipsis text-muted",
                              attrs: { href: "javascript:;" }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa fa-chevron-right fa-fw text-inverse"
                              }),
                              _vm._v(" Unlimited Nav Tabs")
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "text-ellipsis text-muted",
                              attrs: { href: "javascript:;" }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa fa-chevron-right fa-fw text-inverse"
                              }),
                              _vm._v(" Modal & Notification")
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "text-ellipsis text-muted",
                              attrs: { href: "javascript:;" }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa fa-chevron-right fa-fw text-inverse"
                              }),
                              _vm._v(" Buttons")
                            ]
                          )
                        ])
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-4 col-md-4" }, [
                  _c("div", { staticClass: "f-w-700 text-inverse mb-2" }, [
                    _vm._v("Page Options "),
                    _c("span", { staticClass: "label label-pink ml-1" }, [
                      _vm._v("11")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row m-b-20" }, [
                    _c("div", { staticClass: "col-lg-6" }, [
                      _c("ul", { staticClass: "nav d-block" }, [
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "text-ellipsis text-muted",
                              attrs: { href: "javascript:;" }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa fa-chevron-right fa-fw text-inverse"
                              }),
                              _vm._v(" Blank Page")
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "text-ellipsis text-muted",
                              attrs: { href: "javascript:;" }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa fa-chevron-right fa-fw text-inverse"
                              }),
                              _vm._v(" Page with Footer "),
                              _c(
                                "span",
                                {
                                  staticClass: "badge badge-success pt-1 pb-1"
                                },
                                [_vm._v("NEW")]
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "text-ellipsis text-muted",
                              attrs: { href: "javascript:;" }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa fa-chevron-right fa-fw text-inverse"
                              }),
                              _vm._v(" Page without Sidebar")
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "text-ellipsis text-muted",
                              attrs: { href: "javascript:;" }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa fa-chevron-right fa-fw text-inverse"
                              }),
                              _vm._v(" Page with Right Sidebar")
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "text-ellipsis text-muted",
                              attrs: { href: "javascript:;" }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa fa-chevron-right fa-fw text-inverse"
                              }),
                              _vm._v(" Page with Minified Sidebar")
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "text-ellipsis text-muted",
                              attrs: { href: "javascript:;" }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa fa-chevron-right fa-fw text-inverse"
                              }),
                              _vm._v(" Page with Two Sidebar")
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "text-ellipsis text-muted",
                              attrs: { href: "javascript:;" }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa fa-chevron-right fa-fw text-inverse"
                              }),
                              _vm._v(" Page with Line Icons")
                            ]
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-lg-6" }, [
                      _c("ul", { staticClass: "nav d-block" }, [
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "text-ellipsis text-muted",
                              attrs: { href: "javascript:;" }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa fa-chevron-right fa-fw text-inverse"
                              }),
                              _vm._v(" Full Height Content")
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "text-ellipsis text-muted",
                              attrs: { href: "javascript:;" }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa fa-chevron-right fa-fw text-inverse"
                              }),
                              _vm._v(" Page with Mega Menu")
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "text-ellipsis text-muted",
                              attrs: { href: "javascript:;" }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa fa-chevron-right fa-fw text-inverse"
                              }),
                              _vm._v(" Page with Light Sidebar")
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "text-ellipsis text-muted",
                              attrs: { href: "javascript:;" }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa fa-chevron-right fa-fw text-inverse"
                              }),
                              _vm._v(" Page with Large Sidebar")
                            ]
                          )
                        ])
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-4 col-md-4" }, [
                  _c("div", { staticClass: "f-w-700 text-inverse mb-2" }, [
                    _vm._v("Paragraph")
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n\t\t\t\t\t\tLorem ipsum dolor sit amet, consectetur adipiscing elit. Duis libero purus, fermentum at libero convallis, auctor dignissim mauris. Nunc laoreet pellentesque turpis sodales ornare. Nunc vestibulum nunc lorem, at sodales velit malesuada congue. Nam est tortor, tincidunt sit amet eros vitae, aliquam finibus mauris.\n\t\t\t\t\t"
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n\t\t\t\t\t\tFusce ac ligula laoreet ante dapibus mattis. Nam auctor vulputate aliquam. Suspendisse efficitur, felis sed elementum eleifend, ipsum tellus sodales nisi, ut condimentum nisi sem in nibh. Phasellus suscipit vulputate purus at venenatis. Quisque luctus tincidunt tempor.\n\t\t\t\t\t"
                    )
                  ])
                ])
              ])
            ],
            2
          ),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c(
            "b-nav-item-dropdown",
            [
              _c("template", { slot: "button-content" }, [
                _c("i", { staticClass: "fa fa-database fa-fw mr-1 mr-md-0" }),
                _vm._v(" New "),
                _c("b", { staticClass: "caret ml-1 ml-md-0" })
              ]),
              _vm._v(" "),
              _c("b-dropdown-item", { attrs: { href: "javascript:;" } }, [
                _vm._v("Action")
              ]),
              _vm._v(" "),
              _c("b-dropdown-item", { attrs: { href: "javascript:;" } }, [
                _vm._v("Another action")
              ]),
              _vm._v(" "),
              _c("b-dropdown-item", { attrs: { href: "javascript:;" } }, [
                _vm._v("Something else here...")
              ]),
              _vm._v(" "),
              _c("b-dropdown-divider"),
              _vm._v(" "),
              _c("b-dropdown-item", { attrs: { href: "javascript:;" } }, [
                _vm._v("Separated link")
              ]),
              _vm._v(" "),
              _c("b-dropdown-divider"),
              _vm._v(" "),
              _c("b-dropdown-item", { attrs: { href: "javascript:;" } }, [
                _vm._v("One more separated link")
              ])
            ],
            2
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("a", { attrs: { href: "javascript:;" } }, [
        _c("i", { staticClass: "fa fa-gem fa-fw mr-1 mr-md-0" }),
        _vm._v(" Client\n\t\t\t")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
<script>
    import $ from 'jquery';
    import Vue from 'vue';
    import _ from 'lodash'
    export default {
    name: "icon-select",
    functional : true,
    render(nd,context){
        let attrs = context.data.attrs || {};
        attrs.type = 'select2';
        attrs.template = (option)=>{
            return $(`<span><i class="mr-3 ${Vue.prototype.$getIcon(option.id)}"></i>${option.text}</span>`);  
        }
        let children = [nd("option",{attrs:{value : "0"}},"No Icon")];
        _.each(Vue.prototype.$_VUE_ICONS,(icon,id)=>{
            children.push(nd("option",{attrs:{value : id}},icon.name))
        })
        
        context.data.attrs = attrs;
        return nd("v-input",context.data,children);
    }
}
</script>
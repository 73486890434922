<script>
    import _ from "lodash";
   export default {
        name: 'widget-box',
        data(){
            return {
                show : !this.hide,
                tab : null
            };
        },
        watch : {
            hide(val){
                this.show = !val;
            },
            dropdown : {
                handler(val,old){
                    if (!_.size(old) && _.size(val)){
                        this.tab = this.dropdown[0];
                        this.$emit("select",this.tab);
                    }
                },
                immediate : true
            }
        },
        props : {
            title : {
                type: String
            },
            reloadable : {
                type : Boolean,
                default : false
            },
            closeable : {
                type : Boolean,
                default : false
            },
            addable : {
                type : Boolean,
                default : false
            },
            editable : {
                type : Boolean,
                default : false
            },
            expandable : {
                type: Boolean,
                default : false
            },
            assignable : {
                type: Boolean,
                default : false
            },
            unassignable : {
                type: Boolean,
                default : false
            },
            deletable : {
                type: Boolean,
                default : false
            },
            resizable: {
                type: Boolean,
                default : false
            },
            dropdown: {
                type: Array
            },
            loading: {
                type: Boolean,
                default : false
            },
            hide : {
                type: Boolean
            },
            fullColor : {
                type: Boolean
            }
        },
        computed : {
            dropdownSelected : {
                get(){
                    return this.tab;
                },
                set(val){
                    this.tab = val;
                    this.$emit("select",val);
                }
            }
        }
    };
</script>

<template>
    <div class="panel panel-inverse widget-box d-flex flex-column" :class="{'panel-loading' : loading}" data-sortable="false">
		<div class="panel-heading" @click="$emit('click',hide)" :class="$listeners.click ? 'pointer-cursor' : ''">
             <div class="panel-heading-btn">
                <slot name="buttons" :tab="tab"></slot>
                <a href="javascript:;" v-if= "resizable" class="btn btn-xs btn-icon btn-circle btn-default" data-click="panel-expand" v-tooltip="'Expand'"><i class="fa fa-expand"></i></a>
                <a href="javascript:;" v-if="reloadable" @click.stop="$emit('reload')" class="btn btn-xs btn-icon btn-circle btn-success" data-click="panel-reload" v-tooltip="'Reload'"><i class="fa fa-redo"></i></a>
                <a href="javascript:;" v-if="expandable" @click.stop="show=!show;$emit('expand',show)" class="btn btn-xs btn-icon btn-circle btn-warning" 
                    data-click="panel-collapse" v-tooltip="show ? 'Minimize' : 'Maximize'"
                >
                    <i class="fa" :class="show ? 'fa-minus' : 'fa-window-maximize'"></i></a>
                <a href="javascript:;" v-if="addable" @click.stop="$emit('add')" class="btn btn-xs btn-icon btn-circle btn-green" v-tooltip="'Add'"><i class="fa fa-plus"></i></a>
                <a href="javascript:;" v-if="editable" @click.stop="$emit('edit')" class="btn btn-xs btn-icon btn-circle btn-info" v-tooltip="'Edit'"><i class="fa fa-edit"></i></a>
                <a href="javascript:;" v-if="closeable" @click.stop="$emit('close')" class="btn btn-xs btn-icon btn-circle btn-danger" v-tooltip="'Close'"><i class="fa fa-times"></i></a>
                <a href="javascript:;" v-if="assignable" @click.stop="$emit('assign')" class="btn btn-xs btn-icon btn-circle btn-green" v-tooltip="'Assign'"><i class="fa fa-user-plus"></i></a>
                <a href="javascript:;" v-if="unassignable" @click.stop="$emit('unassign')" class="btn btn-xs btn-icon btn-circle btn-warning" v-tooltip="'Unassign'"><i class="fa fa-user-slash"></i></a>
                <a href="javascript:;" v-if="deletable" @click.stop="$emit('delete')" class="btn btn-xs btn-icon btn-circle btn-danger" v-tooltip="'Delete'"><i class="fa fa-trash"></i></a>
            </div>   
            <h4 class="panel-title">
                <slot name="title">{{title}}&nbsp;</slot>
                <div class="pull-right" v-if="dropdown">
                    <select class="btn btn btn-inverse btn-xs" v-model="dropdownSelected">
                        <option :key="val" v-for="val in dropdown" :value="val" >{{val}}</option>
                    </select>
                </div>
            </h4>
		</div>
        <div class="panel-body flex-grow-1 p-0" :class="fullColor ? 'bg-black' : ''" v-collapse="show">
            <div class="p-15 widget-box-body">
                <slot v-show="!loading" :tab="tab"></slot>
                <div v-show="loading" class="overlay bg-white">
                    <span class="spinner"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

    .widget-box-body::after{
        content: ""; 
        display:block;
        clear : both;
    }
</style>
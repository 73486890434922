import Vue from 'vue'

async function switchView(c_view) {
    return await Vue.prototype.$ajax(`${Vue.prototype.$ajax_path}/ajax/functions_ajax_login.php`, "POST",
    {
        action: 'switchView',
        c_view
    })
}

export default {
    namespaced: true,
    state: {
        /** @var viewerLevel that should match $_SESSION['user_level'] */
        viewerLevel: 1, 
        /** @var loginState as returned by checkLoginState() */
        loginState: "guest", 
        /** @var features that this company has enabled */
        features: [], 
        /** @var actualUserLevel of user, even if they are viewing as learner, 
         * for detrmining if the "admin" view button should be shown when logged
         * in as user, among other things
         */
        actualUserLevel: 1,
        /** @var isSecondary company? */
        isSecondary: false,
        menu: [], 
        /** @var dark mode enabled?  */
        dark: false,
        /** @var accout shorthash of account id, for scheduling system filter saves, etc.  */
        account: null, 
        /** @var email string */
        email: null, 
        /** @var userClickedLogout */
        userClickedLogout: true,
        /** @var user details, such as name */
        user: null, 
        error: false,
        errorShake: false,
        logo: null,
        bg_logo: null, 
        CSRF_TOKEN: null,
        company_name: "ProsperLMS", 
        company_list: [], 
        token: null,
        stripeKey: null
    },
    mutations: {
        setErrorShake(state) {
            state.errorShake = true
        }, 
        token(state, token) {
            state.token = token
        }, 
        company_list(state, cl) {
            state.company_list = cl
        }, 
        error(state, error) {
            state.error = error
        }, 
        login(state, status) {
            state.loginState = status;
            state.userClickedLogout = false;
        },
        logout(state) {
            state.loginState = "guest";
            state.viewerLevel = 1;
            state.menu = [];
            state.actualUserLevel = 1;
            state.email = null
            state.userClickedLogout = true;
            state.user = null;
        }, 
        viewerLevel(state, n) {
            state.viewerLevel = n;
        },
        clearError(state) {
            state.errorShake = false;
            state.error = false;
        }, 
        setDark(state) {
            state.dark = true
        },
        setLight(state) {
            state.dark = false
        },
        register(state) {
            state.loginState = 'register'
        },
        email(state, email) {
            state.email = email
        }, 
        core(state, result) {
            state.viewerLevel = result.viewerLevel;
            state.actualUserLevel = result.actualUserLevel;
            state.loginState = result.loginState;
            state.features = result.features;   
            state.isSecondary = result.isSecondary;
            state.menu = result.menu;
            state.dark = result.user?.dark;
            state.account = result.account;
            state.email = result.email;
            state.user = result.user;
            state.logo = result.logo;
            state.bg_logo = result.bg_logo;
            state.CSRF_TOKEN = result.CSRF_TOKEN;
            state.company_name = result.company_name;
            state.stripeKey = result.stripeKey;
        }
    },
    actions: {
        register({commit, state}) {
            if (state.features.register) {
                commit('register');
            }
        }, 
        async checkLoginState({dispatch}) {
            dispatch("getCore");
        }, 
        /**
         * Attemps to login... 
         */
        async login({commit}, payload) {
            commit('clearError')
            const server = await Vue.prototype.$ajax(`/ajax/functions_ajax_login.php`, "POST", payload)
            const {response} = server;
            if (response.result == 'member') {
                commit('login', "member");
                commit('core', response.core)
            } else if (response.result == "fail") {
                commit('setErrorShake');
            } else if (response.result == 'select-company') {
                commit('login', 'select-company');
                commit('company_list', response.company_list)
                commit('token', response.token);
                commit('email', payload.email);
            } else {
                // commit('error', "An error occured while trying to login")
            }
            return server;
        },

        async getCore({commit}) {
            try {
                let response = await Vue.prototype.$ajax(`${process.env.VUE_APP_AJAX_PATH}/ajax/functions_ajax_core.php`, "POST", {
                    action: 'get_init'
                });
                commit("core", response.result)
            } catch (e) {
                // 
            }
        }, 

        async logout({commit}) {
            await Vue.prototype.$ajax(`${process.env.VUE_APP_AJAX_PATH}/logout.php?no_redir`, "POST");
            commit('logout');
        }, 

        async learnerView({dispatch}) {
            await switchView(1);
            dispatch('getCore')
        },

        async adminView({dispatch}) {
            let n = await switchView(0);
            dispatch('getCore')
        },

        async switchView({dispatch, state}) {
            if (state.viewerLevel > 1) {
                dispatch("learnerView");
            } else {
                dispatch("adminView");
            }
        }
    }

}

<template>
    <v-input inline type="select2" :required="required" ref="dropdownThingy" :disabled="readonly" placeholder="Start typing to search..." v-model="k" :config="config" label="Credential Card No."/>
</template>
<script>
import $ from 'jquery'
import VInput from './VInput'
import { customPost } from "../../scripts/custom-all";
export default {
    name: "v-credential-card", 
    components: {VInput},
    props: {
        value: { // the credential card no.
            type: String,
            required: false
        },
        course: { // should be the encrpted course id
            type: String,
            required: true
        },
        readonly: {
            type: Boolean
        },
        required: {
            type: Boolean
        }

    },
    created() {
        this.k = this.value;
        let newVal= this.value;
        // populates dropdown if a value if passed in
        if (newVal) setTimeout(() => {
            var option = new Option(newVal, newVal, true, true);
            var select2Select = $(this.$refs.dropdownThingy.$el).find("select");
            select2Select.append(option).trigger('change');
            select2Select.trigger({
                type: 'select2:select',
                params: {
                    data: {id : newVal, full_name: newVal}
                }
            });
        },0);
    },
    watch: {
        // update k if prop changes
        value(newVal, oldValue) {
            if (newVal === oldValue) return;
            this.k = newVal
            if (newVal) setTimeout(() => {
                var option = new Option(newVal, newVal, true, true);
                var select2Select = $(this.$refs.dropdownThingy.$el).find("select");
                select2Select.append(option).trigger('change');
                select2Select.trigger({
                    type: 'select2:select',
                    params: {
                        data: {id : newVal, full_name: newVal}
                    }
                });
            },0);
        },
        // emit input when updated
        k(newVal) {
            this.$emit('input', newVal);
        }
    },
    data() {
        let vm = this;
        return {
            k: undefined,
            config: {
                ajax: {
                    async transport(info, success, fail) {
                        let result = await customPost(`${vm.$ajax_path}/ajax/functions_ajax_cards.php`, {
                            action: "listCards",
                            course: vm.course,
                            info: info.data.term
                        }).catch(fail);
                        success(result);
                    },
                    delay: 250,
                    processResults(data) {
                        return {
                            results: data.result,
                        };
                    },
                }
            }
        }
    },
};
</script>
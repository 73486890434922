<template>
    <transition
        mode = "out-in"
        enter-active-class="animated fadeIn"
        leave-active-class="animated fadeOut"
    >
        <div class="login login-v2" :key="state" v-if="!loadingInit">
            <!-- begin brand -->
            <div class="login-header mt-3">
                <div class="brand d-flex align-items-center justify-content-center">
                    <div class="url-image img" data-type="login-logo" style="width:100px;height:100px"
                    :style="{'background-image': `url('${bg_img}')`}"></div>
                    <div class="ml-3">
                        <b>Learning</b> Centre
                        <small>Training | Certification | Proctoring</small>
                    </div>
                </div>
            </div>
            <div class="login-content">
                <component 
                    :email.sync="email" 
                    :company-list.sync="company_list" 
                    :ajax-login.sync="ajaxLogin"
                    :lock-company.sync="lockCompany"
                    :enable-register="enable_register"
                    :enable-secondary="enable_secondary"
                    :state.sync="state"
                    :is="state" 
                    @login="update"
                />
            </div>

            <span class="text-white-transparent-2 pull-right mr-3 mb-1 f-s-10">ABCS Safety Training Inc. © 2020 ProsperLMS<span style="font-weight: 700;font-size: 45%;vertical-align: super;">TM</span></span>
        </div>
    </transition>
</template>

<script>
// import 'https://www.google.com/recaptcha/api.js?onload=onGrecaptchaLoadCallback&render=explicit'
import { mapState, mapActions } from 'vuex'
import loadingMixin from '../../components/mixins/loading-mixin';
import {
    LoginForm, 
    RegisterForm, 
    ResetForm, 
    BannedTimer, 
    RecoverForm, 
    CompanySelector, 
    Loading,
    RecoverNotice,
    InactiveMessage
} from './';
    export default {
        mixins: [loadingMixin], 
        name: "login-box",
        components: { 
            'guest' : LoginForm, 
            'multisession' : LoginForm,
            'register' : RegisterForm, 
            'recover' : RecoverForm,
            'recover-notice' : RecoverNotice,
            'reset-psw' : ResetForm, 
            'select-company': CompanySelector,
            'loading' : Loading,
            'non-activated': InactiveMessage
        }, 
         props : {
            ajaxLogin : Boolean,
            lockCompany :Boolean,
            defaultEmail : String
        },
        data(){
            return {
                currentState : this.$store.state.auth.loginState,
                company_list : [],
                token : null,
                email : this.defaultEmail, 
                loadingInit: false, 
                bg_img: false,
                enable_register: false,
                enable_secondary: false,
                components: {
                    'guest' : LoginForm, 
                    'multisession' : LoginForm,
                    'register' : RegisterForm, 
                    'recover' : RecoverForm,
                    'reset-psw' : ResetForm,
                    'select-company': CompanySelector,
                    'loading' : Loading
                }
            }
        },
        computed : {
            ...mapState('auth', ['loginState']), 
            state : {
                get(){
                    return this.loadingStatus == "loading" ? "loading" : this.currentState
                },
                set(val){
                    this.currentState = val
                }
            }
        },
        watch: {
            state(s) {
                if (s == 'member') {
                    this.update();
                }
            }
        },
        created(){
            this.asyncData(()=>this.$ajax("/ajax/functions_ajax_login.php","POST",{
                action : "initLoginBox"
            })).load()
            this.get_img()
        },
        methods : {
            ...mapActions('auth', ['logout']), 
            async get_img() {
                this.bg_img = await this.$ajax(`/ajax/functions_ajax_core.php`, "POST",  {
                    action: "get_bg_logo"
                });
            },
            update() {
                console.log("you are now a member");
                this.$store.dispatch('auth/getCore');
                // this.get_core();
                // this.refreshCart()
                if (this.$route.path == '/login') { 
                    this.$router.push(this.$vstore.redir || '/')
                }
            }
        },
    };
</script>


<style>
    .login {
        background-color: rgba(20,20,20,0.7);
    }
	.login .checkbox label a{
		color : #ff5b57 !important;
    }
    .login-cover-bg{
        z-index : 2000
    }
</style>


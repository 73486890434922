var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "label v-tag", class: "label-" + _vm.type },
    [
      _vm._v("\n    " + _vm._s(_vm.label) + "\n    "),
      _vm.closable
        ? _c(
            "button",
            {
              staticClass: "btn btn-icon",
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_c("i", { staticClass: "fal fa-times" })]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export default [
  {
    path: '/instructors/instructor_profile',
    name: 'instructor-profile',
    meta: {
      title: "Instructor Profile",
      instructor: true,
      breadcrumbs: ['Instructors', 'Instructor Profile']
    }, 
    component: () => import(/* webpackChunkName: "my-instructor-profile" */ '../views/Instructors/Profile.vue'),
  },
  {
    path: '/instructors/instructor_calendars',
    name: 'instructor-calendars',
    meta: {
      title: "Manage My Calendars",
      instructor: true,
      breadcrumbs: ['Instructor Resources', 'Manage My Calendars'],
    }, 
    component: () => import(/* webpackChunkName: "instructor-calendars" */ '../views/Instructors/CalendarList.vue'),
  },
  {
    path: '/users/instructor_management',
    name: 'instructors',
    meta: {
      title: "Instructors",
      viewerLevel: 5,
      breadcrumbs: ['Instructor Management', 'Instructors'],
    },
    // title: (route) => `${route.params.category} Search`,
    component: () => import(/* webpackChunkName: "instructors" */ '../views/Instructors/Instructors.vue'),
  },
  {
    path: '/instructors/instructor_classlists',
    name: 'classlists',
    meta: {
      title: "Instructor Class List",
      viewerLevel: 1,
      breadcrumbs: ['Instuctor Resources', 'Instructor Class Lists'],
      instructor: true, 
    },
    component: () => import(/* webpackChunkName: "classlists" */ '../views/Instructors/ClassLists.vue'),
  },
  {
    path: '/instructor/schedule',
    alias: '/scheduling/instructor_schedule', 
    name: 'instructor-cal',
    meta: {
      title: "My Schedule",
      viewerLevel: 1,
      breadcrumbs: ['Instructor Resources', 'My Schedule'],
      instructor: true,
    },
    component: () => import(/* webpackChunkName: "instructor-schedule" */ '../views/Instructors/InstructorSchedule.vue'),
  },
  {
    path: '/instructors/audit_course_report/:course/:instructor/', 
    name: 'audit-course-report', 
    meta: {
      title: "Audit Course Report", 
      viewerLevel: 4
    }, 
    component: () => import(/* webpackChunkName: "company-audits-instructor" */"../views/Audits/Report.vue"), 
    props: true
  }, 
  {
    path: '/instructor/view/:id',
    name: 'instructor-details',
    meta: {
      title: "Instructor Profile",
      instructor: true, 
      viewerLevel: 5
    }, 
    component: () => import(/* webpackChunkName: "instructor-profile" */ '../views/Instructors/InstructorProfile.vue'),
    props: true
  },
  {
    path: '/instructors/instructor_resources',
    name: 'instructor-resources',
    meta: {
      title: "Course Resources",
      instructor: true
    }, 
    component: () => import(/* webpackChunkName: "instructor-resources" */ '../views/Instructors/CourseResources.vue'),
  },    
  /* Instructor resources */
  {
    path: '/instructors/no_instructor_classlists',
    name: 'no-instructor-classlists',
    meta: {
      title: "Class Lists without Instructor",
      viewerLevel: 4,
      instructor: true,
      breadcrumbs: ['Instructors', 'Class LIsts without Instructor'], 
    }, 
    component: () => import(/* webpackChunkName: "no-ins-class-lists" */ '../views/Instructors/NoClassLists.vue'),
  },
]
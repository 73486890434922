export default [
    
  {
    path: '/classes/student_attendance(.php)?',
    name: 'student-attendance',
    meta: {
      guest: true
    },
    component: () => import(/* webpackChunkName "student-attendance" */'../views/Classes/StudentAttendance.vue')
  }, 
  {
    path: '/classes/unsch_class_list',
    name: 'unscheduled-practicals',
    meta: {
      title: "Unscheduled Practicals",
      instructor: true,
      breadcrumbs: ['Classes', 'Unscheduled Practical Students'], 
    }, 
    component: () => import(/* webpackChunkName: "unsch-practical-students" */ '../views/Instructors/UnscheduledClassList.vue'),
  },
  {
    path: '/classes/class_list/:id',
    name: 'class-list', 
    meta: {
      title: "Class LIst", 
      instructor: true,
      viewerLevel: 4
    }, 
    component: () => import(/* webpackChunkName: "class-list" */"../views/Classes/ClassList.vue"),
    props: (route) => ({clid /*class id */: route.params.id})
  }, 
  {
    path: '/classes/class_list_on_site/:id',
    name: 'class-list-on-site', 
    meta: {
      title: "Class LIst", 
      instructor: true,
      viewerLevel: 4
    }, 
    component: () => import(/* webpackChunkName: "class-list-on-site" */"../views/Classes/ClassListOnSite.vue"),
    props: (route) => ({cid /*class id */: route.params.id})
  }, 
]
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "header navbar-default", attrs: { id: "header" } },
      [
        _c(
          "div",
          { staticClass: "navbar-header" },
          [
            _vm.pageOptions.pageWithTwoSidebar
              ? _c(
                  "button",
                  {
                    staticClass: "navbar-toggle pull-left",
                    attrs: { type: "button" },
                    on: { click: _vm.toggleMobileRightSidebar }
                  },
                  [
                    _c("span", { staticClass: "icon-bar" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "icon-bar" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "icon-bar" })
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "navbar-brand d-flex align-items-center",
                attrs: {
                  to: {
                    name: _vm.loginState == "guest" ? "catalog" : "dashboard"
                  }
                }
              },
              [
                _c("div", {
                  staticClass: "img url-image height-full flex-shrink-0",
                  staticStyle: { width: "86px" },
                  style: { "background-image": "url('" + _vm.logo + "')" },
                  attrs: { id: "company_logo", "data-type": "logo" }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "flex-shrink-0" }, [
                  _c("b", [_vm._v("Learning")]),
                  _vm._v(" Centre")
                ])
              ]
            ),
            _vm._v(" "),
            _vm.pageOptions.pageWithTopMenu &&
            !_vm.pageOptions.pageWithoutSidebar
              ? _c(
                  "button",
                  {
                    staticClass: "navbar-toggle pt-0 pb-0 mr-0 collapsed",
                    attrs: { type: "button" },
                    on: { click: _vm.toggleMobileTopMenu }
                  },
                  [_vm._m(0)]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.pageOptions.pageWithTopMenu &&
            _vm.pageOptions.pageWithoutSidebar
              ? _c(
                  "button",
                  {
                    staticClass: "navbar-toggle",
                    attrs: { type: "button" },
                    on: { click: _vm.toggleMobileTopMenu }
                  },
                  [
                    _c("span", { staticClass: "icon-bar" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "icon-bar" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "icon-bar" })
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.pageOptions.pageWithMegaMenu
              ? _c(
                  "button",
                  {
                    staticClass: "navbar-toggle p-0 m-r-0",
                    attrs: { type: "button" },
                    on: { click: _vm.toggleMobileMegaMenu }
                  },
                  [_vm._m(1)]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.pageOptions.pageWithoutSidebar
              ? _c(
                  "button",
                  {
                    staticClass: "navbar-toggle",
                    attrs: { type: "button" },
                    on: { click: _vm.toggleMobileSidebar }
                  },
                  [
                    _c("span", { staticClass: "icon-bar" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "icon-bar" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "icon-bar" })
                  ]
                )
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _vm.pageOptions.pageWithMegaMenu ? _c("header-mega-menu") : _vm._e(),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "navbar-nav navbar-right" },
          [
            _c("li", [
              _vm.loginState == "member"
                ? _c("div", { attrs: { id: "nav-search" } })
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.loginState && _vm.loginState != "guest"
              ? _c("v-notifications")
              : _vm._e(),
            _vm._v(" "),
            _c("v-cart-dropdown", { attrs: { cart: _vm.cart } }),
            _vm._v(" "),
            _vm._m(2),
            _vm._v(" "),
            _c(
              "b-nav-item-dropdown",
              {
                staticClass: "dropdown navbar-user",
                attrs: { "menu-class": "dropdown-menu-right", "no-caret": "" }
              },
              [
                _vm.loginState != "guest" && _vm.user
                  ? _c("template", { slot: "button-content" }, [
                      _c("img", {
                        staticClass: "user-avatar",
                        attrs: {
                          src: "" + _vm.user.img,
                          alt: "Profile Picture"
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "d-none d-md-inline" }, [
                        _vm._v(_vm._s(_vm.user.fname + " " + _vm.user.lname))
                      ]),
                      _vm._v(" "),
                      _c("b", { staticClass: "caret" })
                    ])
                  : _c("template", { slot: "button-content" }, [
                      _c("span", { staticClass: "d-none d-md-inline" }, [
                        _vm._v(_vm._s("Hello, Guest"))
                      ]),
                      _vm._v(" "),
                      _c("b", { staticClass: "caret" })
                    ]),
                _vm._v(" "),
                _vm.loginState != "guest" && _vm.user
                  ? [
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: { name: "user-profile" } }
                        },
                        [_vm._v("My Profile")]
                      ),
                      _vm._v(" "),
                      _vm.viewerLevel > 3
                        ? [
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item",
                                attrs: { to: "/dashboard/settings" }
                              },
                              [_vm._v("Dashboard Settings")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "dropdown-item",
                                on: {
                                  click: function($event) {
                                    return _vm.switchView()
                                  }
                                }
                              },
                              [_vm._v("Learner View")]
                            )
                          ]
                        : _vm.actualUserLevel > 3
                        ? [
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "javascript:;" },
                                on: {
                                  click: function($event) {
                                    return _vm.switchView()
                                  }
                                }
                              },
                              [_vm._v("Admin View")]
                            )
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c("b-dropdown-divider"),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          attrs: { href: "javascript:;" },
                          on: {
                            click: function($event) {
                              return _vm.$store.dispatch("auth/logout")
                            }
                          }
                        },
                        [_vm._v("Log Out")]
                      )
                    ]
                  : [
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: "/login" }
                        },
                        [_vm._v("Log In")]
                      )
                    ]
              ],
              2
            ),
            _vm._v(" "),
            _vm.pageOptions.pageWithTwoSidebar
              ? _c("li", { staticClass: "divider d-none d-md-block" })
              : _vm._e(),
            _vm._v(" "),
            _vm.pageOptions.pageWithTwoSidebar
              ? _c("li", { staticClass: "d-none d-md-block" }, [
                  _c(
                    "a",
                    {
                      staticClass: "f-s-14",
                      attrs: { href: "javascript:;" },
                      on: { click: _vm.toggleRightSidebarCollapsed }
                    },
                    [_c("i", { staticClass: "fa fa-th" })]
                  )
                ])
              : _vm._e()
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-stack fa-lg text-inverse" }, [
      _c("i", { staticClass: "far fa-square fa-stack-2x" }),
      _vm._v(" "),
      _c("i", { staticClass: "fa fa-cog fa-stack-1x" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-stack fa-lg text-inverse m-t-2" }, [
      _c("i", { staticClass: "far fa-square fa-stack-2x" }),
      _vm._v(" "),
      _c("i", { staticClass: "fa fa-cog fa-stack-1x" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "dropdown" }, [
      _c("a", { attrs: { href: "javascript:;", "data-click": "fullscreen" } }, [
        _c("i", { staticClass: "fas fa-expand" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
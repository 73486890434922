export default [
    
  /* PROCTORING */
  {
    path: '/proctor/proctor_disputes',
    name: 'proctor-disputes',
    meta: {
      title: "Proctor Disputes", 
      viewerLevel: 1, 
      breadcrumbs: ['Proctoring Disputes'], 
    }, 
    component: () => import(/* webpackChunkName: "proctor-disputes" */"../views/Proctoring/ProctorDisputes.vue")
  },
  {
    path: '/proctor/proctor_disputes/:id',
    name: 'proctor-dispute',
    meta: {
      title: "Dispute Details", 
      viewerLevel: 1, 
      breadcrumbs: [ 'Proctor', 'Disptue Details'], 
    }, 
    component: () => import(/* webpackChunkName: "dispute-details" */"../views/Proctoring/DisputeDetails.vue"),
    props: (route) => ({rc_id: route.params.id})
  },
  {
    path: '/proctor/proctor_profile/:id?', 
    alias: ['/proctor/proctor_profile.php/:id?', '/test/:id?'], 
    name: 'proctor-profile', 
    meta: {
      title: "Proctor Profile",
      viewerLevel: 3, 
      breadcrumbs: ['Proctoring Manager', 'Proctoring Profile'], 
    }, 
    component: () => import(/* webpackChunkName: "proctor-profile" */"../views/Proctoring/ProctorProfile.vue"), 
    props: (route) => ({id: route.params.id || route.query.id})
  }, 
  {
    path: '/proctor/email_list', 
    name: 'proctor-email', 
    meta: {
      title: "Proctor Email List",
      breadcrumbs: ['Proctoring Manager', 'Proctoring Profile'], 
    }, 
    component: () => import(/* webpackChunkName: "proctor-email" */"../views/Proctoring/ProctorEmail.vue"), 
  }, 
]
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer", attrs: { id: "footer" } }, [
    _vm._v(
      "\n\t© 2020 Color Admin Responsive Admin Template - Sean Ngu All Rights Reserved\n"
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.tag, {
    ref: "wm",
    tag: "component",
    staticClass: "v-chat",
    attrs: { title: _vm.title, loading: _vm.loading, dropdown: _vm.dropdown },
    on: { closed: _vm.clear },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var tab = ref.tab
          return [
            _c(
              "div",
              { staticClass: "d-flex flex-column height-full" },
              [
                _c(
                  "transition-group",
                  {
                    staticClass:
                      "flex-grow-1 overflow-auto d-flex flex-column v-chat-messages",
                    attrs: {
                      tag: "div",
                      "enter-active-class": "animated fadeInDown"
                    }
                  },
                  _vm._l(_vm.tabMessages(tab), function(message) {
                    return _c(
                      "div",
                      {
                        key: message.id,
                        staticClass: "message",
                        class: message.avatar ? "not-owned" : "owned"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex width-full message-container"
                          },
                          [
                            message.avatar
                              ? _c("div", {
                                  staticClass:
                                    "img rounded-corner width-30 height-30",
                                  style: {
                                    "background-image":
                                      "url('" + message.avatar + "')"
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-column message-content"
                              },
                              [
                                _c("div", { staticClass: "message-box" }, [
                                  _c("div", { staticClass: "f-s-14 f-w-600" }, [
                                    _vm._v(_vm._s(message.content))
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "message-tag f-s-9 text-grey-darker"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(message.name) +
                                          " · " +
                                          _vm._s(
                                            _vm.$timeFormat(message.t_stamp)
                                          )
                                      )
                                    ]
                                  )
                                ])
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                !_vm.disabled
                  ? _c(
                      "div",
                      { staticClass: "message-input m-t-5" },
                      [
                        _c("v-form", {
                          on: {
                            submit: function(edit, reset) {
                              return _vm.addMessage(edit, reset, tab)
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var edit = ref.edit
                                  return [
                                    _c("v-input", {
                                      staticClass: "ml-3 mr-3",
                                      attrs: {
                                        placeholder:
                                          "Press ENTER to leave a message ..."
                                      },
                                      model: {
                                        value: edit.message,
                                        callback: function($$v) {
                                          _vm.$set(
                                            edit,
                                            "message",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "edit.message"
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "star-rating" }, [
    _c(
      "div",
      { staticClass: "back-stars" },
      _vm._l(5, function(i) {
        return _c("i", {
          key: i,
          staticClass: "fa fa-star",
          class: { "cursor-pointer": !_vm.readonly },
          on: {
            click: function($event) {
              return _vm.onClick(i)
            }
          }
        })
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "front-stars",
        style: "width : " + (Number(_vm.value) / 5.0) * 100 + "%"
      },
      _vm._l(5, function(i) {
        return _c("i", {
          key: i,
          staticClass: "fa fa-star",
          class: { "cursor-pointer": !_vm.readonly },
          on: {
            click: function($event) {
              return _vm.onClick(i)
            }
          }
        })
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
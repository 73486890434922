<template>
     <div>
        <h4 class="text-white">Your account is not yet activated.</h4>
        <p>We have sent an activation email to you, click the link in it to activate your account.</p>
        <div class="m-t-20">
            Click <a href="javascript:;" @click="$emit('update:state', 'guest')">here</a> go back to login.
        </div>
        <div class="m-t-20">
            Can't find the email? Click <a href="javascript:;" @click="reConfirm">here</a> to re-send confirmation.
        </div>
    </div>
</template>
<script>
import Swal from 'sweetalert2'
export default {
    methods : {
        async reConfirm() {
            let res = await this.$ajax("/ajax/functions_ajax_login.php", {
                action: "resendConfirm",
            });
            Swal.fire("Success", "Email request sent", "success");
        }
    },
}
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      ref: "form",
      attrs: { novalidate: "", "data-vv-scope": _vm.scope },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      }
    },
    [
      _c(
        "div",
        [_vm._t("default", null, { edit: _vm.edit, disabled: _vm.disabled })],
        2
      ),
      _vm._v(" "),
      _vm.submitBtn && !_vm.disabled
        ? _c(
            "button",
            {
              staticClass: "btn btn-green pull-right",
              attrs: { type: "submit" }
            },
            [_vm._v("Save")]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<script>
// Link
import courseMixin from './courseMixin';
export default {
    name: 'course-wrapper-7',
    mixins : [courseMixin],
    methods : {
        buyAction(){
            window.open(this.course.link)
            // this.$router.push({name: 'course-details', params: {id: this.course.id}})
            // window.open(`/courses/view_course.php?id=${encodeURIComponent(this.course.id)}`)
        }
    }
};
</script>   